import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Grid, Radio
} from "@mui/material";

const predefinedDiseases = [
  "CVD",
  "Hypertension",
  "ObesityWeightLoss",
  "Diabetes",
  "Colorectal Cancer",
  "Prostate Cancer",
  "Cervical Cancer",
  "Oral Cancer",
];

const DieticianGeneralInfo = (selectedUser, approval) => {
  const referenceDetail = JSON.parse(selectedUser?.selectedUser?.referenceDetail);
  
  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleEmail">
                First Name of Clinical Dietician/Nutritionist
              </Label>
                <Input disabled id="" name="name" value={selectedUser?.selectedUser?.firstName} type="text" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleEmail">
                Last Name of Clinical Dietician/Nutritionist
              </Label>
              <Input disabled id="" name="name" value={selectedUser?.selectedUser?.lastName} type="text" />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
                Do you have a registered practice/organization for your business?
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                value="true"
                checked={selectedUser?.selectedUser?.registeredBusiness === true}
                onChange={(e) => {
                  handleRegisteredBusiness(e);
                }}
                disabled
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="registeredOrganization"
                type="radio"
                value="false"
                checked={selectedUser?.selectedUser?.registeredBusiness === false}
                onChange={(e) => {
                  handleRegisteredBusiness(e);
                }}
                disabled
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(selectedUser?.selectedUser?.registeredBusiness) && (
          <>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="organizationName">Name</Label>
                  <Input
                    id={`organizationName`}
                    name="organizationName"
                    placeholder="Enter Name"
                    type="text"
                    value={selectedUser?.selectedUser?.organizationName}
                    onChange={(e) => {
                      handleOrganizationName(e);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`phoneNumber`}>Phone Number</Label>
                  <Input
                    id={`phoneNumber`}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    maxLength={10}
                    onChange={(e) => {
                      handleOrganizationPhoneNumber(e);
                    }}
                    value={selectedUser?.selectedUser?.organizationPhoneNumber}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`organizationBusinessTime`}>Business Time</Label>
                  <Input
                    id={`organizationBusinessTime`}
                    name="organizationBusinessTime"
                    placeholder="Enter Email Id"
                    type="time"
                    onChange={(e) => {
                      handleOrganizationBusinessTime(e);
                    }}
                    value={selectedUser?.selectedUser?.organizationBusinessTime}

                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for={`organizationAddress`}>Address</Label>
                  <Input
                    id={`organizationAddress`}
                    name="phoneNumber"
                    placeholder="Enter Address"
                    type="textarea"
                    onChange={(e) => {
                      handleOrganizationAddress(e);
                    }}
                    value={selectedUser?.selectedUser?.organizationAddress}
      
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <FormGroup>
              <Label for="exampleEmail">
                Please narrate briefly about yourself.
              </Label>
              <Input
                id=""
                name="name"
                value={selectedUser?.selectedUser?.dietitianDetail}
                onChange={(e) => {
                  handleDietitianDetail(e);
                }}
                placeholder="Narrate about yourself"
                type="textarea"
                disabled
      
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="exampleEmail">
                List all your educational qualifications as a Clinical
                Dietician.
              </Label>
              <Input
                id=""
                name="name"
                value={selectedUser?.selectedUser?.educationQualifications}
                onChange={(e) => {
                  handleEducationQualification(e);
                }}
                placeholder="List your educational qualification"
                type="textarea"
                disabled
          
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={4}>
            How long (years) you have now worked in this capacity?
          </Col>
          <Col md={4}>
            <FormGroup>
              <Input
                id=""
                name="name"
                value={selectedUser?.selectedUser?.yearsWorkingAsDietitian}
                onChange={(e) => {
                  handleYearsWorkingAsDietitian(e);
                }}
                placeholder="Enter years"
                type="number"
                disabled
      
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup>
              <span>
                Have you ever worked as a clinical dietician in a hospital or
                clinic setting dealing directly with patients and taking care of
                their dietary needs after illness?
              </span>{" "}
              &nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalDietitian"
                type="radio"
                value="true"
                checked={selectedUser?.selectedUser?.everWorkedAsClinicalDietitian === true}
                onChange={(e) => {
                  handleeverWorkedAsClinicalDietitian(e);
                }}
                disabled
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="everWorkedAsClinicalDietitian"
                type="radio"
                value="false"
                checked={selectedUser?.selectedUser?.everWorkedAsClinicalDietitian === false}
                onChange={(e) => {
                  handleeverWorkedAsClinicalDietitian(e);
                }}
                disabled
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        {(selectedUser?.selectedUser?.everWorkedAsClinicalDietitian) && (
          <>
            <Row></Row>
            <Row>
              <Col md={4}>
                <Label>Name of Hospital / Clinic</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={selectedUser?.selectedUser?.hospitalName}
                    onChange={(e) => {
                      handleHospitalName(e);
                    }}
                    placeholder="Enter name of hospital / clinic"
                    type="text"
                    className="mt-2"
                    disabled
             
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>State</Label>
                <FormGroup>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    value={selectedUser?.selectedUser?.state}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    disabled
                    className="mt-2"
           
                  >
                    <option value="">Select State</option>
                   
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>City</Label>
                <FormGroup>
                  <Input
                    id=""
                    name="name"
                    value={selectedUser?.selectedUser?.city}
                    onChange={(e) => {
                      handleCity(e);
                    }}
                    placeholder="Enter City"
                    type="text"
                    className="mt-2"
                    disabled
               
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>How many years? </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="yearsWorkingAtHospital"
                    value={selectedUser?.selectedUser?.yearsWorkingAtHospital}
                    onChange={(e) => {
                      handleHowManyYears(e);
                    }}
                    disabled
                    className="mt-2"
                 
                  >
                    <option value="">Select years</option>
                    <option value="less than 1 year">Less than 1 year</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="3-4 years">3-4 years</option>
                    <option value="5 years or more">5 years or more</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label>Which Specialization : </Label>
                <FormGroup>
                  <Input
                    type="select"
                    id=""
                    name="specialization"
                    onChange={(e) => {
                      handleSpecialization(e);
                    }}
                    value={selectedUser?.selectedUser?.specialization}
                    className="mt-2"
                    disabled
                   
                  >
                    <option value="">Select Specialization</option>
                    <option value="CVD">CVD</option>
                    <option value="hypertension">Hypertension</option>
                    <option value="obesity/weightLoss">
                      Obesity / Weight Loss
                    </option>
                    <option value="Diabetes">Diabetes</option>
                    <option value="ColorectalCancer">ColorectalCancer</option>
                    <option value="ProstateCancer">ProstateCancer</option>
                    <option value="CervicalCancer">CervicalCancer</option>
                    <option value="Oral Cancer ">Oral Cancer </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>
                For which of the following diseases you would be interested in
                providing diet consulting to our clients? (Please note that most
                of our clients would need Preventive Diet solutions rather than
                cure solutions as our aim is to prevent or delay the onset of
                these diseases through lifestyle changes).
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "CVD"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="CVD"
              value="CVD"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "Hypertension"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Hypertension"
              value="Hypertension"
            />
          </Col>
          <Col md={3}>
            {" "}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "ObesityWeightLoss"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Obesity/Weight Loss"
              value="ObesityWeightLoss"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "Diabetes"
                  )}
                  onChange={(e) => {
                    handleNutritionConsultingForDiseasesForClients(e);
                  }}
                />
              }
              label="Diabetes"
              value="Diabetes"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                      "Colorectal Cancer"
                    )}
                  />
                }
              label="Colorectal Cancer"
              value="Colorectal Cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "Prostate Cancer"
                  )}
                />
              }
              label="Prostate Cancer"
              value="Prostate Cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "Cervical Cancer"
                  )}
                />
              }
              label="Cervical Cancer"
              value="Cervical Cancer"
            />
          </Col>
          <Col md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUser?.selectedUser?.nutritionConsultingForDiseasesForClients?.includes(
                    "Oral Cancer"
                  )}
                />
              }
              label="Oral Cancer"
              value="Oral Cancer"
            />
          </Col>
        </Row>
        {/* <hr style={{ border: "1px solid #000000" }}></hr> */}
        <Row className="mt-3">
          <Col>
            <span>
              Are you comfortable working with children of all ages, guiding
              them towards a healthier and better lifestyle. Please elaborate
              your work with children in the past (if any).
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={selectedUser?.selectedUser?.historyOfWorkingWithChild}
              disabled
              placeholder="Elaborate your work with children in the past"
              type="textarea"
             
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>
              Do you follow any specific methodology or national/international
              dietary guidelines while providing diet consultation? Please
              explain briefly your approach.
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              id=""
              name="name"
              value={selectedUser?.selectedUser?.detailsOfMethodologyOrDietaryGuidelinesFollowed}
              placeholder="Specific methodology or national/international dietary guidelines"
              type="textarea"
              disabled
              className="mt-2"
            />
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Row>
          <Col>
            <span>
              Do you find yourself more comfortable working with specific gender
              (male vs female) or you are equally comfortable working with both
              gender?
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Input
                name="genderPreference"
                value="male"
                checked={selectedUser?.selectedUser?.genderPreference === "male"}
                type="radio"
                disabled
              />
              &nbsp;
              <Label check className="ml-1">
                Male
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="female"
                disabled
                checked={selectedUser?.selectedUser?.genderPreference === "female"}
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Female
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="genderPreference"
                value="both"
                checked={selectedUser?.selectedUser?.genderPreference === "both"}
                disabled
                type="radio"
              />
              &nbsp;
              <Label check className="ml-1">
                Both
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <Grid container spacing={2} mb={2}>
          <Grid item>
            <Label>
              Based on your past experiences, rate your own performance in terms
              of positive outcome for your clients, on a scale of 1 to 5 (5=
              Outstanding, 4= Excellent, 3= Good 2= Fair 1= Not Sure, 0= No
              experience)
            </Label>
          </Grid>
        </Grid>

        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div style={{ display: "flex", gap: "85px" }}>
              <div>0</div>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
            </div>
          </Col>
        </Row>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label>
                1. Children (from Birth to 3 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="0-3"
                  value="0"
                  checked={selectedUser?.selectedUser?.childrenFromBirthTillThreeYearsAge === "0"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="1"
                  checked={selectedUser?.selectedUser?.childrenFromBirthTillThreeYearsAge === "1"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="2"
                  checked={selectedUser?.selectedUser?.childrenFromBirthTillThreeYearsAge === "2"}
                  control={<Radio />}
              
                  disabled
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="3"
                  checked={selectedUser?.selectedUser?.childrenFromBirthTillThreeYearsAge === "3"}
                  control={<Radio />}
                 
                  disabled
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="4"
                  checked={selectedUser?.selectedUser?.childrenFromBirthTillThreeYearsAge === "4"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="0-3"
                  value="5"
                  checked={selectedUser?.selectedUser?.childrenFromBirthTillThreeYearsAge === "5"}
                  control={<Radio />}
                 
                  disabled
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
               
              >
                2. Children (2-12 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="2-12"
                  value="0"
                  checked={selectedUser?.selectedUser?.childrenFromTwoToTwelveYearsAge === "0"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="1"
                  checked={selectedUser?.selectedUser?.childrenFromTwoToTwelveYearsAge === "1"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="2"
                  checked={selectedUser?.selectedUser?.childrenFromTwoToTwelveYearsAge === "2"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="3"
                  checked={selectedUser?.selectedUser?.childrenFromTwoToTwelveYearsAge === "3"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="4"
                  checked={selectedUser?.selectedUser?.childrenFromTwoToTwelveYearsAge === "4"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
                <Input
                  name="2-12"
                  value="5"
                  checked={selectedUser?.selectedUser?.childrenFromTwoToTwelveYearsAge === "5"}
                  control={<Radio />}
                  disabled
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
              >
                3. Children (13-21 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="13-21"
                  value="0"
                  checked={selectedUser?.selectedUser?.childrenFromThirteenToTwentyYearsAge === "0"}
                  control={<Radio />}
               
                  disabled
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="1"
                  checked={selectedUser?.selectedUser?.childrenFromThirteenToTwentyYearsAge === "1"}
                  control={<Radio />}
               
                  disabled
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="2"
                  checked={selectedUser?.selectedUser?.childrenFromThirteenToTwentyYearsAge === "2"}
                  control={<Radio />}
                 
                  disabled
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="3"
                  checked={selectedUser?.selectedUser?.childrenFromThirteenToTwentyYearsAge === "3"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="4"
                  checked={selectedUser?.selectedUser?.childrenFromThirteenToTwentyYearsAge === "4"}
                  control={<Radio />}
                 
                  disabled
                  type="radio"
                />
                <Input
                  name="13-21"
                  value="5"
                  checked={selectedUser?.selectedUser?.childrenFromThirteenToTwentyYearsAge === "5"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label">
                4. Adults-Male (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60"
                  value="0"
                  checked={selectedUser?.selectedUser?.adultMaleFromTwentyOneToSixtyYearsAge === "0"}
                  control={<Radio />}
                 
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="1"
                  checked={selectedUser?.selectedUser?.adultMaleFromTwentyOneToSixtyYearsAge === "1"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="2"
                  checked={selectedUser?.selectedUser?.adultMaleFromTwentyOneToSixtyYearsAge === "2"}
                  control={<Radio />}
                  
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="3"
                  checked={selectedUser?.selectedUser?.adultMaleFromTwentyOneToSixtyYearsAge === "3"}
                  control={<Radio />}
                 
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="4"
                  checked={selectedUser?.selectedUser?.adultMaleFromTwentyOneToSixtyYearsAge === "4"}
                  control={<Radio />}
               
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60"
                  value="5"
                  checked={selectedUser?.selectedUser?.adultMaleFromTwentyOneToSixtyYearsAge === "5"}
                  control={<Radio />}
           
                  disabled
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label"
               
              >
                5. Adult-Female (21-60)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="21-60F"
                  value="0"
                  checked={selectedUser?.selectedUser?.adultFemaleFromTwentyOneToSixtyYearsAge === "0"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="1"
                  checked={selectedUser?.selectedUser?.adultFemaleFromTwentyOneToSixtyYearsAge === "1"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="2"
                  checked={selectedUser?.selectedUser?.adultFemaleFromTwentyOneToSixtyYearsAge === "2"}
                  control={<Radio />}
             
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="3"
                  checked={selectedUser?.selectedUser?.adultFemaleFromTwentyOneToSixtyYearsAge === "3"}
                  control={<Radio />}
              
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="4"
                  checked={selectedUser?.selectedUser?.adultFemaleFromTwentyOneToSixtyYearsAge === "4"}
                  control={<Radio />}
              
                  disabled
                  type="radio"
                />
                <Input
                  name="21-60F"
                  value="5"
                  checked={selectedUser?.selectedUser?.adultFemaleFromTwentyOneToSixtyYearsAge === "5"}
                  control={<Radio />}
               
                  disabled
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <FormControl style={{ width: "-webkit-fill-available" }}>
          <Row>
            <Col lg={5}>
              <Label
                id="demo-radio-buttons-group-label">
                6. Seniors (Age &gt;60 years)
              </Label>
            </Col>
            <Col lg={7}>
              <div style={{ display: "flex", gap: "75px" }}>
                <Input
                  name="<60"
                  value="0"
                  checked={selectedUser?.selectedUser?.seniorsOfAgeGreaterThenSixty === "0"}
                  control={<Radio />}
               
                  disabled
                  type="radio"
                />
                <Input
                  name="<60"
                  value="1"
                  checked={selectedUser?.selectedUser?.seniorsOfAgeGreaterThenSixty === "1"}
                  control={<Radio />}
              
                  disabled
                  type="radio"
                />
                <Input
                  name="<60"
                  value="2"
                  checked={selectedUser?.selectedUser?.seniorsOfAgeGreaterThenSixty === "2"}
                  control={<Radio />}
           
                  disabled
                  type="radio"
                />
                <Input
                  name="<60"
                  value="3"
                  checked={selectedUser?.selectedUser?.seniorsOfAgeGreaterThenSixty === "3"}
                  control={<Radio />}
                
                  disabled
                  type="radio"
                />
                <Input
                  name="<60"
                  value="4"
                  checked={selectedUser?.selectedUser?.seniorsOfAgeGreaterThenSixty === "4"}
                  control={<Radio />}
              
                  disabled
                  type="radio"
                />
                <Input
                  name="<60"
                  value="5"
                  checked={selectedUser?.selectedUser?.seniorsOfAgeGreaterThenSixty === "5"}
                  control={<Radio />}
               
                  disabled
                  type="radio"
                />
              </div>
            </Col>
          </Row>
        </FormControl>
        <Row>
          {/* <Col md={4}>
            {testimonials?.length < 3 && (
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={addTestimonial}
              >
                Add Testimonial
              </Button>
            )}
          </Col> */}
          <Row>
            {}
            {selectedUser?.selectedUser?.testimonials?.map((testimonial, index) => (
              <Col md={12} key={index}>
                <Input
                  id={`testimonial-${index}`}
                  name={`testimonial-${index}`}
                  value={testimonial}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Enter Testimonial"
                  type="textarea"
                  className="mt-2"
                  style={{
                    borderColor: "lightgrey",
                  }}
                />
              </Col>
            ))}
          </Row>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>
        <h4>Reference</h4>
        <hr style={{ width: "10%", marginTop: "3px", height: "2px" }}></hr>
        <Row>
          <Col>
            <span>
              Would you be open to sharing 3 references of your past or current
              clients (1= children, 1= Female Adult and 1 = Male Adult) who can
              give us honest feedback of their experiences in working with you
              for your Nutrition consulting services?
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                value="true"
                checked={selectedUser?.selectedUser?.sharingReferences === true}
        
                disabled
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                Yes
              </Label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Input
                name="serviceProvideWithSameAddress"
                type="radio"
                value="false"
                checked={selectedUser?.selectedUser?.sharingReferences === false}
          
                disabled
                style={{ cursor: "pointer" }}
              />
              &nbsp;
              <Label check className="ml-1">
                No
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        {(referenceDetail) && (
          <>
            <Label>
              Please provide below their contact information and let them know
              that will be receiving a call from Living Scope Health Marketing
              team member. It will be a quick conversation only and we won’t
              take much of their time.
            </Label>
            <h5>Reference # 1</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.name}            
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
              
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference1?.mobile}
                 
                  placeholder="Enter Mobile"
                  type="number"
                  maxLength={10}
                  className="mt-2"
          
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 2</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.name}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference2?.mobile}
                  type="number"
                  maxLength={10}
                  placeholder="Enter Mobile"
                  className="mt-2"
                />
              </Col>
            </Row>
            <hr style={{ width: "80%", height: "3px" }}></hr>
            <h5>Reference # 3</h5>
            <Row style={{ marginTop: "20px" }}>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Name : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.name}
                  placeholder="Enter Name"
                  type="text"
                  className="mt-2"
                />
              </Col>
              <Col md={4}>
                <Label style={{ marginBottom: "0px" }}>Mobile : </Label>
                <Input
                  id=""
                  name="name"
                  value={referenceDetail?.reference3?.mobile}
                  placeholder="Enter Mobile"
                  type="number"
                  maxLength={10}
                  className="mt-2"
                />
              </Col>
            </Row>
          </>
        )}
        <hr style={{ border: "1px solid #000000" }}></hr>

        <Row>
          <Col>
            <span
            >
              Please check below your preferred time to provide diet consulting
              services to our clients. You will be later given a weekly calendar
              to manage your own preferred schedule on our Uncurl: health
              portal. Our clients will book your consultation based on your
              availability only.{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <FormGroup>
              <Label for="weekdays">Weekdays:</Label>
              <Row>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Sunday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Sunday"
                    value="Sunday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Monday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Monday"
                    value="Monday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Tuesday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Tuesday"
                    value="Tuesday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Wednesday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Wednesday"
                    value="Wednesday"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Thursday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Thursday"
                    value="Thursday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Friday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Friday"
                    value="Friday"
                  />
                </Col>
                <Col md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        selectedUser?.selectedUser?.preferredDayAndTime
                          ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.Weekdays?.includes("Saturday")
                          : false
                      }
                        onChange={(e) => {
                          handleWeekDays(e);
                        }}
                        disabled
                      />
                    }
                    label="Saturday"
                    value="Saturday"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="time">Time:</Label>
                <Input
                  type="time"
                  name="time"
                  id="time"
                  value={ selectedUser?.selectedUser?.preferredDayAndTime
                    ? JSON.parse(selectedUser.selectedUser.preferredDayAndTime)?.time
                    : false}
                  onChange={(e) => {
                    handleTime("time", e.target.value);
                  }}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
};

export default DieticianGeneralInfo;
