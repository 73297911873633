import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Table,
} from "reactstrap";
import { event } from "react-ga";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getGeneralInformation,
  getLicenceInfo,
  getUserDetails,
} from "../../constants/apiList";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import Spinner from "../../shared/Spinner";
import SampleTestReportModal from "./SampleTestReportModal";

const GeneralInfo = ({ handleTabChange }) => {
  const [generalInfoData, setGeneralInfoData] = useState([]);
  const [labName, setLabName] = useState();
  const [yearStablish, setYearStablish] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [numberOfLocation, setNumberOfLocation] = useState("");
  const [ownerName, setOwnerName] = useState();
  const [ownerPhone, setOwnerPhone] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [labAddress, setLabAddress] = useState();
  const [labPhoneNumber, setLabPhoneNumber] = useState();
  const [pincode, setPinCode] = useState();
  const [licenses1, setLicenses1] = useState();
  const [licenses2, setLicenses2] = useState();
  const [licenses3, setLicenses3] = useState();
  const [nablAccreditation, setNablAccreditation] = useState();
  const [isoCertificate, setIsoCertificate] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNABLFile, setSelectedNABLFile] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [accessNeeded, setAccessNeeded] = useState(false);
  const [sundayIsHoliday, setSundayIsHoliday] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [licenses, setLicenses] = useState([
    { license: "", documentLink: "", fileName: "" },
    { license: "", documentLink: "", fileName: "" },
    { license: "", documentLink: "", fileName: "" },
  ]);
  const [nablCertificates, setnablCertificates] = useState([]);
  const [isoCertificates, setisoCertificates] = useState([]);
  const [labPartnerSampleTest, setLabPartnerSampleTest] = useState([]);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [editedRows, seteditedRows] = useState([]);
  const [serviceAreaPinCode, setServiceAreaPinCode] = useState([]);
  const [pinCodesString, setPinCodesString] = useState("");
  const [labMainAddress, setLabMainAddress] = useState();
  const [formLoading, setFormLoading] = useState(false);
  const [SampleReportModalOpen, setisSampleReportModalOpen] = useState(false);
  const [isoStandards, setIsoStandards] = useState([{ name: "", file: null }]);
  const [isLoadingView, setIsLoadingView] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSampleTestReport, setselectedSampleTestReport] = useState([]);
  const [adminRows, setAdminRows] = useState([
    {
      adminName: "",
      adminPhoneNumber: "",
      adminEmail: "",
      accessNeeded: false,
    },
  ]);

  const [rows, setRows] = useState([
    {
      id: null,
      labDirectorName: "",
      phoneNumber: "",
      email: "",
      accessNeeded: false,
    },
  ]);
  const [timings, setTimings] = useState({
    Monday: { from: "", to: "" },
    Tuesday: { from: "", to: "" },
    Wednesday: { from: "", to: "" },
    Thursday: { from: "", to: "" },
    Friday: { from: "", to: "" },
    Saturday: { from: "", to: "" },
    Sunday: { from: "", to: "" },
  });
  const [sampleTimings, setSampleTimings] = React.useState({
    Monday: { from: "", to: "" },
    Tuesday: { from: "", to: "" },
    Wednesday: { from: "", to: "" },
    Thursday: { from: "", to: "" },
    Friday: { from: "", to: "" },
    Saturday: { from: "", to: "" },
    Sunday: { from: "", to: "" },
  });
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const handleTimingChange = (day, field, value) => {
    setTimings((prev) => {
      const updatedTimings = {
        ...prev,
        [day]: {
          ...prev[day],
          [field]: value,
        },
      };

      // Check if any field in timings is filled and clear the error
      const isTimingsValid = Object.values(updatedTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (isTimingsValid) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          timings: false,
        }));
      }

      return updatedTimings;
    });
  };

  const handleSampleTimingChange = (day, field, value) => {
    setSampleTimings((prev) => {
      const updatedSampleTimings = {
        ...prev,
        [day]: { ...prev[day], [field]: value },
      };

      // Check if any field in sampleTimings is filled and clear the error
      const isSampleTimingsValid = Object.values(updatedSampleTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (isSampleTimingsValid) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          sampleTimings: false,
        }));
      }

      return updatedSampleTimings;
    });
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: null,
        labDirectorName: "",
        phoneNumber: "",
        email: "",
        accessNeeded: false,
      },
    ]);
  };
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
  // const [isoStandards, setIsoStandards] = useState([""]); // Initial field

  // Handle opening the View Modal
  const handleDocumentView = (file) => {
    setIsLoadingView(true);
    setSelectedFile(file);
    setViewModalOpen(true);
    const authToken = window.sessionStorage.getItem("token");
    const viewApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(file.documentLink);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${viewApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch document: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        setSelectedFile({ ...file, blobUrl });
      })
      .catch((error) => {
        toast.error(`Error fetching the file: ${error}`);
        setViewModalOpen(false); // Close modal if fetch fails
      })
      .finally(() => {
        setIsLoadingView(false); // Stop loader
      });
  };

  const closeViewModal = () => {
    if (selectedFile?.blobUrl) {
      window.URL.revokeObjectURL(selectedFile.blobUrl); // Clean up the blob URL
    }
    setSelectedFile(null);
    setViewModalOpen(false);
  };

  const handleISOStandardChange = (event, index) => {
    // const updatedStandards = [...isoStandards];
    // updatedStandards[index] = event.target.value;
    // setIsoStandards(updatedStandards);
    const updatedStandards = isoStandards.map((standard, idx) =>
      idx === index ? { ...standard, name: event.target.value } : standard
    );
    setIsoStandards(updatedStandards);

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [`isoName-${index}`]: false,
    }));
  };

  const addISOStandardField = () => {
    // setIsoStandards([...isoStandards, ""]);
    setIsoStandards([...isoStandards, { name: "", file: null }]);
  };
  const handleChange = (index, e, checked) => {
    const { name } = e.target;

    const newRows = [...rows];
    if (name.startsWith("accessNeeded")) {
      newRows[index]["accessNeeded"] = checked; // Update accessNeeded directly
    } else {
      newRows[index][name] = e.target.value; // Update other properties
    }
    setRows(newRows);
  };

  const handleDirectorChange = (id, e) => {
    const newRows = [...generalInfoData?.labUsersDto];
    const index = newRows.findIndex((row) => row.id === id);
    if (index !== -1) {
      newRows[index][e.target.name] = e.target.value;
      seteditedRows(newRows);
    }
  };

  const handleDirector2Change = (id, fieldName, value) => {
    const newRows = generalInfoData?.labUsers.map((row) => {
      if (row.id === id) {
        return { ...row, [fieldName]: value };
      }
      return row;
    });
    setGeneralInfoData((prevData) => ({
      ...prevData,
      labUsers: newRows,
    }));
    seteditedRows(newRows);
  };

  const handleLabName = (event) => {
    setLabName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      LabName: false,
    }));
  };
  const handleYearStablish = (event) => {
    setYearStablish(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      yearStablish: false,
    }));
  };
  const handleRegistration = (event) => {
    setRegistrationNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      registrationNumber: false,
    }));
  };
  const handleNumberofLocation = (event) => {
    setNumberOfLocation(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      numberOfLocation: false,
    }));
  };
  const handleOwnerName = (event) => {
    setOwnerName(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerName: false,
    }));
  };
  const handleOwnerPhone = (event) => {
    setOwnerPhone(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerPhone: false,
    }));
  };
  const handleOwnerEmail = (event) => {
    setOwnerEmail(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      ownerEmail: false,
    }));
  };
  const handleLabAddress = (event) => {
    setLabAddress(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labAddress: false,
    }));
  };
  const handlelabPhoneNumber = (event) => {
    setLabPhoneNumber(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      labPhoneNumber: false,
    }));
  };
  const handlePinCode = (event) => {
    setPinCode(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      pincode: false,
    }));
  };

  const handleLicenses = (event) => {
    setLicenses1(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      licenses1: false,
    }));
  };
  const handleNablAccreditationChange = (value) => {
    setNablAccreditation(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      nablAccreditation: value === null,
    }));
  };
  const handleIsoCertification = (value) => {
    setIsoCertificate(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      isoCertificate: value === null,
    }));
  };

  const handleAdminChange = (index, event, accessValue = null) => {
    const { name, value } = event.target;
    const updatedRows = [...adminRows];
    if (name.includes("adminAccessNeeded")) {
      updatedRows[index].accessNeeded = accessValue;
    } else {
      updatedRows[index][name] = value;
    }
    setAdminRows(updatedRows);
  };

  const addAdminRow = () => {
    setAdminRows([
      ...adminRows,
      {
        adminName: "",
        adminPhoneNumber: "",
        adminEmail: "",
        accessNeeded: false,
      },
    ]);
  };

  const initialRows = generalInfoData?.labUsersDto?.map((user) => ({
    labDirectorName: user.labDirectorName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    accessNeeded: user.accessNeeded,
  }));
  const transformTimingsToLabTiming = (timings) => {
    return Object.keys(timings).map((day) => ({
      day: day,
      startTime: timings[day].from,
      endTime: timings[day].to,
    }));
  };
  const transformLabTimingToTimings = (labTimingArray) => {
    return labTimingArray.reduce((acc, item) => {
      acc[item.day] = {
        from: item.startTime,
        to: item.endTime,
      };
      return acc;
    }, {});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};
    if (labName == undefined || labName == "" || labName == null) {
      error.LabName = true;
    }
    if (yearStablish == undefined || yearStablish == "") {
      error.yearStablish = true;
    }
    if (
      registrationNumber === undefined ||
      registrationNumber === "" ||
      registrationNumber === null
    ) {
      error.registrationNumber = true;
    }
    if (
      numberOfLocation === undefined ||
      numberOfLocation === "" ||
      numberOfLocation == null
    ) {
      error.numberOfLocation = true;
    }
    if (licenses[0]?.license === undefined || licenses[0]?.license === "") {
      error.licenses1 = true;
    }
    if (serviceProvideWithSameAddress === undefined) {
      error.sampleCollectionService = true;
    }
    if (nablAccreditation === undefined) {
      error.nablAccreditation = true;
    }
    if (isoCertificate === undefined) {
      error.isoCertificate = true;
    }
    if (labAddress === undefined || labAddress === "" || labAddress == null) {
      error.labAddress = true;
    }
    if (pincode === undefined || pincode === "" || pincode === null) {
      error.pincode = true;
    }

    const isTimingsValid = Object.values(timings).some(
      (day) => day.from.trim() !== "" && day.to.trim() !== ""
    );

    if (!isTimingsValid) {
      error.timings = true;
    }
    if (serviceProvideWithSameAddress === true) {
      const isSampleTimingsValid = Object.values(sampleTimings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );
      if (!isSampleTimingsValid) {
        error.sampleTimings = true;
      }
    }

    if (
      labPhoneNumber === undefined ||
      labPhoneNumber === "" ||
      labPhoneNumber === null
    ) {
      error.labPhoneNumber = true;
    }

    if (
      nablAccreditation &&
      nablCertificates.length === 0 &&
      selectedNABLFile.length === 0
    ) {
      // if (nablAccreditation === true) {
      //   if (NABLreports === false) {
      //     if (labPartnerCertificate[0]?.documentLink === "")
      //     {
      //       error.NABLreports = true;
      //     }
      //   }
      // }
      // if (isoCertificate === true) {
      //    if (ISOreports === false) {
      //       if (labPartnerCertificate[0]?.documentLink === "") {
      //         error.ISOreports = true;
      //       }
      //    }
      // }
      error.NABLreports = true;
    }

    if (
      isoCertificate &&
      isoCertificates.length === 0 &&
      isoStandards.some((standard) => !standard.name || !standard.file)
    ) {
      error.ISOreports = true;
    }
    if (ownerPhone?.length > 10 || ownerPhone?.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Enter valid phone number",
      });
    }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      setFormSubmitted(true);
      const isRowEmpty = (row) => {
        return rows.length > 0 && rows[0].labDirectorName === "";
      };
      let combinedArray;

      if (isRowEmpty(rows)) {
        combinedArray = [...editedRows];
      } else {
        combinedArray = [...editedRows, ...rows];
      }

      const labTimingArray = transformTimingsToLabTiming(timings);
      const sampleTimingArray = transformTimingsToLabTiming(sampleTimings);
      const labMainAddress = {
        labPinCode: pincode, // Assuming this comes from another source
        labAddress: labAddress,
        labPhoneNumber: labPhoneNumber,
        labTiming: labTimingArray,
        coordinatorDetailsDto: adminRows,
        serviceProvideWithSameAddress: serviceProvideWithSameAddress, // Adjust as needed
        labServiceAreaPinCode: {
          serviceAreaPinCode: pincodeRow.map((row) => row.serviceAreaPinCode),
          sampleCollectionTiming: sampleTimingArray,
        },
      };
      const formData = {
        labName: generalInfoData?.labName ? generalInfoData?.labName : labName,
        establishedYear: generalInfoData?.establishedYear
          ? generalInfoData?.establishedYear
          : yearStablish,
        registrationNumber: generalInfoData?.registrationNumber
          ? generalInfoData?.registrationNumber
          : registrationNumber,
        numberOfLocation: numberOfLocation,
        labUsers: combinedArray,
        labNabl: nablAccreditation,
        labIso: isoCertificate,
        labMainAddress: labMainAddress,
        sundayIsHoliday: sundayIsHoliday,
      };
      setSubmitting(true);
      AxiosInstance.put("lab-partner/general-info", formData)
        .then((response) => {
          setLabName("");
          setRegistrationNumber("");
          setYearStablish("");
          // setNumberOfLocation("");
          setOwnerName("");
          setOwnerPhone("");
          setOwnerEmail("");
          setLicenses1("");
          setLicenses2("");
          setLicenses3("");
          // setNablAccreditation("");
          // setIsoCertificate("");
          toast.success("Information submitted successfully");
          setSubmitting(false);
          setTimeout(() => {
            // handleTabChange(null,1)
            // window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });
    }
  };
  const handleLicenseChange = (index, value) => {
    const newLicenses = [...licenses];
    newLicenses[index].license = value;
    setLicenses(newLicenses);
  };
  const handleFileChange = async (event, index) => {
    event.preventDefault();
    const file = event.target.files[0];

    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      setLicenses((prevLicenses) =>
        prevLicenses.map((license, i) =>
          i === index ? { ...license, fileName: file.name } : license
        )
      );
      const documentType = "LICENSE";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("licenseNames", licenses[index].license);
      AxiosInstance.post(
        `/lab-partner/licenses-upload/${documentType}`,
        formData
      )
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setFileName(null);
          GetLicenceInfo();
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const handleISOChange = async (event, index) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!isoStandards[index].name) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [`isoName-${index}`]: true,
      }));
      return;
    }
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const updatedStandards = isoStandards.map((standard, idx) =>
        idx === index ? { ...standard, file } : standard
      );
      setIsoStandards(updatedStandards);
      const documentType = "ISO_CERTIFICATE";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("licenseNames", isoStandards[index].name);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ISOreports: false,
      }));
      AxiosInstance.post(
        `/lab-partner/licenses-upload/${documentType}`,
        formData
      )
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
        })
        .catch((error) => {
          //setError("An
        });
    }
  };
  const handleNABLChange = async (event) => {
    event.preventDefault();
    const NABLCertificate = "NABL_Certificate";
    const file = event.target.files[0];
    const fileArray = Array.from(event.target.files);
    setSelectedNABLFile(fileArray);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      const documentType = "NABL_CERTIFICATE";
      const formData = new FormData();
      formData.append("licenseNames", NABLCertificate);
      formData.append("file", file);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        NABLreports: false,
      }));
      AxiosInstance.post(
        `/lab-partner/licenses-upload/${documentType}`,
        formData
      )
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setFileName(null);
        })
        .catch((error) => {
          console.log("error: ", error);
          //setError("An
        });
    }
  };
  const handleMultipleChange = async (event) => {
    event.preventDefault();
    const fileArray = Array.from(event.target.files);
    // if (files.length < 8) {
    //   toast.error("Please select at least 8 files.");
    //   return;
    // }
    // setFileName(file.name);
    // setSelectedFile(file);
    let fileCheck = fileName?.split(".")?.pop();
    if (
      fileCheck == "gif" ||
      fileCheck == "jfif" ||
      fileCheck == "csv" ||
      fileCheck == "xls"
    ) {
      toast.error("GIF, csv, xls and Jfif file are not allowed");
    } else {
      setIsLoading(true);
      setselectedSampleTestReport([...selectedSampleTestReport, ...fileArray]);
      const formData = new FormData();
      for (const file of fileArray) {
        formData.append("file", file);
      }
      AxiosInstance.post("/lab-partner/sample-test-upload", formData)
        .then((response) => {
          toast.success("Document Uploaded Successfully");
          setUploadFlag((prev) => !prev);
          setIsOpen(false);
          setFileName(null);
        })
        .catch((error) => {
          //setError("An
        })
        .finally(() => {
          setIsLoading(false); // Stop loader
        });
    }
  };
  const GetLicenceInfo = async () => {
    await AxiosInstance.get(`${getLicenceInfo}`)
      .then((response) => {
        // const fetchedLicenses = response?.labPartnerLicense.length > 0 ?  response?.labPartnerLicense : licenses;
        // const sanitizedLicenses = fetchedLicenses.map(license =>
        //   ({ ...license, license: license.license === "undefined" ? '' : license.license })
        // );
        // setLicenses(sanitizedLicenses);

        const fetchedLicenses = response?.labPartnerLicense || [];
        const sanitizedLicenses = fetchedLicenses.map((license) => ({
          ...license,
          license: license.license === "undefined" ? "" : license.license,
        }));

        // Ensure there are exactly three licenses
        const licensesToSet = [...sanitizedLicenses];
        while (licensesToSet.length < 3) {
          licensesToSet.push({ license: "", documentLink: "" });
        }
        if (licensesToSet.length > 3) {
          licensesToSet.length = 3;
        }

        setLicenses(licensesToSet);

        // setLicenses(response?.labPartnerLicense);
        setLabPartnerSampleTest(response?.labPartnerSampleTest || []);
        setnablCertificates(response?.labNablCertificate || []);
        setisoCertificates(response?.labIsoCertificate || []);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const handleViewFile = (filePath) => {
    // Check if the file is viewable (e.g., PDF or image) and set a flag
    const fileExtension = filePath.split(".").pop().toLowerCase();
    if (
      fileExtension === "pdf" ||
      ["jpg", "jpeg", "png"].includes(fileExtension)
    ) {
      // Set the file viewable flag (this might be done based on the file type or other logic)
      setLicenses({
        ...licenses,
        documentLink: filePath,
        isFileViewable: true, // Only allow view if it's a supported file type
      });
    } else {
      alert("File type not supported for preview.");
    }
  };

  const handleDownload = (filePath) => {
    const downloadApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  // Handle file view in iframe
  const handleView = (filePath) => {
    const fileExtension = filePath.split(".").pop().toLowerCase();

    // Check if the file type is viewable (PDF or image)
    if (
      fileExtension === "pdf" ||
      ["jpg", "jpeg", "png"].includes(fileExtension)
    ) {
      // Display in an iframe
      setCurrentFile(filePath); // Assuming setCurrentFile updates the iframe src in your component
    } else {
      toast.error("This file type cannot be viewed directly.");
    }
  };

  const handleLicenseCertificateDelete = (index, id) => {
    const updatedCertificates = [...licenses];
    updatedCertificates.splice(index, 1); // Remove the selected certificate
    setLicenses(updatedCertificates);
    handleISoDelete(id);
  };

  const handleNABLCertificateDelete = (index, id) => {
    const updatedCertificates = [...nablCertificates];
    updatedCertificates.splice(index, 1); // Remove the selected certificate
    setnablCertificates(updatedCertificates);
    handleISoDelete(id);
  };

  const handleISOCertificateDelete = (index, id) => {
    const updatedCertificates = [...isoCertificates];
    updatedCertificates.splice(index, 1); // Remove the selected certificate
    setisoCertificates(updatedCertificates);
    handleISoDelete(id);
  };

  const handleISoDelete = (id) => {
    const deleteApi = "lab-partner/delete-lab-file";
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${deleteApi}/${id}/LICENSE_FILE`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Deleted Successfully!",
            text: "The document was deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
      })
      .catch((error) => {
        toast.error(`Error deleting the file: ${error}`);
      });
  };

  const GetGeneralInformation = async () => {
    setFormLoading(true);
    await AxiosInstance.get(`${getGeneralInformation}`)
      .then((response) => {
        setLabMainAddress(response?.labMainAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setGeneralInfoData(response);
        setLabName(response?.labName);
        setYearStablish(response?.establishedYear);
        setRegistrationNumber(response?.registrationNumber);
        setNumberOfLocation(response?.numberOfLocation);
        setLabAddress(response?.labMainAddress?.labAddress);
        setLabPhoneNumber(response?.labMainAddress?.labPhoneNumber);
        setPinCode(response?.labMainAddress?.labPinCode);
        setServiceProvideWithSameAddress(
          response?.labMainAddress?.serviceProvideWithSameAddress
        );
        setAdminRows(
          response?.labMainAddress.coordinatorDetailsDto
            ? response?.labMainAddress.coordinatorDetailsDto
            : adminRows
        );
        setTimings(
          response.labMainAddress.labTiming
            ? transformLabTimingToTimings(response.labMainAddress.labTiming)
            : timings
        );
        if (response?.labMainAddress?.serviceProvideWithSameAddress) {
          setSampleTimings(
            response.labMainAddress.labServiceAreaPinCode
              ?.sampleCollectionTiming
              ? transformLabTimingToTimings(
                  response.labMainAddress.labServiceAreaPinCode
                    ?.sampleCollectionTiming
                )
              : sampleTimings
          );
          setServiceAreaPinCode(response?.labMainAddress);
        }
        setIsoCertificate(response?.labIso);
        setNablAccreditation(response?.labNabl);
        setSundayIsHoliday(response?.sundayIsHoliday);
        setFormLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setFormLoading(false);
      });
  };
  useEffect(() => {
    GetGeneralInformation();
    GetLicenceInfo();
    setEmail(userDetails?.email);
    setPhoneNumber(userDetails?.phoneNumber);
    setFirstName(userDetails?.firstName);
    setLastName(userDetails?.lastName);
    setPinCodesString(
      serviceProvideWithSameAddress?.labServiceAreaPinCode?.serviceAreaPinCode.join(
        ", "
      )
    );
  }, []);
  useEffect(() => {
    GetLicenceInfo();
  }, []);

  const IOSCertificate = "ISOCertificate";

  const [pincodeRow, setPincodeRow] = useState([{ serviceAreaPinCode: "" }]);
  const addPincode = () => {
    setPincodeRow([...pincodeRow, { serviceAreaPinCode: "" }]);
  };

  // Function to handle pin code change
  const handlePincodeChange = (index, e) => {
    const updatedPincodeRow = [...pincodeRow];
    updatedPincodeRow[index].serviceAreaPinCode = e.target.value;
    setPincodeRow(updatedPincodeRow);
  };
  const [serviceProvideWithSameAddress, setServiceProvideWithSameAddress] =
    useState();

  console.log("ad,min rows", adminRows, isoStandards);
  return (
    <>
      {formLoading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ float: "inline-end", color: "red" }}>
            Note : If Number of Location is 0 or 1 then you have to set Sample
            Collection service as "Yes" and fill all the fields
          </div>
          <Form
            style={{
              border: "1px solid lightgrey",
              padding: "15px",
              marginTop: "25px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              fontSize: "18px",
            }}
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    <b>Name of the Lab</b>
                  </Label>
                  <Input
                    disabled={generalInfoData?.labName ? true : false}
                    id=""
                    name="name"
                    value={
                      generalInfoData?.labName
                        ? generalInfoData?.labName
                        : labName
                    }
                    onChange={(e) => {
                      handleLabName(e);
                    }}
                    placeholder="Enter name of your Lab"
                    type="text"
                    style={{
                      borderColor: fieldErrors.LabName ? "red" : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">
                    <b>Year Established</b>
                  </Label>
                  <Input
                    id=""
                    name="yearEstablished"
                    value={
                      generalInfoData?.establishedYear
                        ? generalInfoData?.establishedYear
                        : yearStablish
                    }
                    onChange={(e) => {
                      handleYearStablish(e);
                    }}
                    placeholder=""
                    type="date"
                    style={{
                      borderColor: fieldErrors.yearStablish
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    <b>Registration Number</b>
                  </Label>
                  <Input
                    id=""
                    name="registrationNumber"
                    value={
                      generalInfoData?.registrationNumber
                        ? generalInfoData?.registrationNumber
                        : registrationNumber
                    }
                    onChange={(e) => {
                      handleRegistration(e);
                    }}
                    placeholder="Enter Registration Number"
                    disabled={
                      generalInfoData?.registrationNumber ? true : false
                    }
                    type="text"
                    style={{
                      borderColor: fieldErrors.registrationNumber
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">
                    <b>No. of State Operations</b>
                  </Label>
                  <Input
                    id=""
                    name="NumofLocation"
                    value={numberOfLocation}
                    onChange={(e) => {
                      handleNumberofLocation(e);
                    }}
                    placeholder="Enter Number of Location"
                    type="number"
                    min={0}
                    style={{
                      borderColor: fieldErrors.numberOfLocation
                        ? "red"
                        : "lightgrey",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label for={`labDirectorName`}>
                    <b>Administrator/Coordinator Name (Primary)</b>
                  </Label>
                  <Input
                    disabled
                    id={`labDirectorName`}
                    name="labDirectorName"
                    placeholder="Enter Name"
                    type="text"
                    value={firstName + " " + lastName}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`phoneNumber`}>
                    <b>Phone Number</b>
                  </Label>
                  <Input
                    disabled
                    id={`phoneNumber`}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    type="number"
                    value={phoneNumber}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`emailIndex`}>
                    <b>Email Id</b>
                  </Label>
                  <Input
                    disabled
                    id={`email`}
                    name="email"
                    placeholder="Enter Email Id"
                    type="email"
                    value={email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            {generalInfoData?.labUsers &&
              generalInfoData?.labUsers?.map((row, index) => (
                <>
                  <Row key={index} className="mt-4">
                    <Col md={3}>
                      <FormGroup>
                        <Label for={row.id}>
                          <b>Director / Founder / Owner Name</b>
                        </Label>
                        <Input
                          id={row.id}
                          name="labDirectorName"
                          placeholder="Enter Name"
                          type="text"
                          value={row.labDirectorName}
                          onChange={(e) => {
                            // handleChange(index, e);
                            // setOwnerName(e.target.value);
                            handleDirectorChange(row.id, e);
                            handleOwnerName(e);
                          }}
                          style={{
                            borderColor: fieldErrors.ownerName
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={row.id}>
                          <b>Phone Number</b>
                        </Label>
                        <Input
                          id={row.id}
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          type="number"
                          value={row.phoneNumber}
                          onChange={(e) => {
                            // handleChange(index, e);
                            handleDirectorChange(row.id, e);
                            setOwnerPhone(e.target.value);
                            handleOwnerPhone(e);
                          }}
                          style={{
                            borderColor: fieldErrors.ownerPhone
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={row.id}>
                          <b>Email Id</b>
                        </Label>
                        <Input
                          id={row.id}
                          name="email"
                          placeholder="Enter Email Id"
                          type="email"
                          value={row.email}
                          onChange={(e) => {
                            // handleChange(index, e);
                            setOwnerEmail(e.target.value);
                            handleOwnerEmail(e);
                            handleDirectorChange(row.id, e);
                          }}
                          style={{
                            borderColor: fieldErrors.ownerEmail
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`accessNeeded${index}`}>
                          <b>Does this person needs access to this account?</b>
                        </Label>
                        <br></br>
                        <Input
                          name={`accessNeeded${row.id}`}
                          type="radio"
                          checked={!row.accessNeeded}
                          value="false"
                          onChange={(e) => {
                            // handleChange(index, e);
                            setAccessNeeded(false);
                            handleDirector2Change(
                              row.id,
                              "accessNeeded",
                              false
                            );
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          No
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          name={`accessNeeded${row.id}`}
                          type="radio"
                          checked={row.accessNeeded}
                          value="true"
                          onChange={(e) => {
                            // handleChange(index, e);
                            setAccessNeeded(true);
                            handleDirector2Change(row.id, "accessNeeded", true);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup></FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
            <hr style={{ border: "1px solid #000000" }}></hr>
            <p style={{ color: "red" }}>
              Want to add more Directors then click on "Add Directors"{" "}
            </p>
            {rows &&
              rows?.map((row, index) => (
                <>
                  <Row key={index} className="mt-4">
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`labDirectorName${index}`}>
                          <b>Director / Founder / Owner Name</b>
                        </Label>
                        <Input
                          id={`labDirectorName${index}`}
                          name="labDirectorName"
                          placeholder="Enter Name"
                          type="text"
                          value={row.labDirectorName}
                          onChange={(e) => {
                            handleChange(index, e);
                            setOwnerName(e.target.value);
                            handleOwnerName(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`phoneNumber${index}`}>
                          <b>Phone Number</b>
                        </Label>
                        <Input
                          id={`phoneNumber${index}`}
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          type="number"
                          value={row.phoneNumber}
                          onChange={(e) => {
                            handleChange(index, e);
                            setOwnerPhone(e.target.value);
                            handleOwnerPhone(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`email${index}`}>
                          <b>Email Id</b>
                        </Label>
                        <Input
                          id={`email${index}`}
                          name="email"
                          placeholder="Enter Email Id"
                          type="email"
                          value={row.email}
                          onChange={(e) => {
                            handleChange(index, e);
                            setOwnerEmail(e.target.value);
                            handleOwnerEmail(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`accessNeeded${index}`}>
                          <b>Does this person needs access to this account?</b>
                        </Label>
                        <br></br>
                        <Input
                          name={`accessNeededDirector${index}`}
                          type="radio"
                          checked={!row.accessNeeded}
                          // value={row.accessNeeded}
                          onChange={(e) => {
                            handleChange(index, e, false);
                            setAccessNeeded(false);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          No
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          name={`accessNeeded${index}`}
                          type="radio"
                          checked={row.accessNeeded}
                          onChange={(e) => {
                            handleChange(index, e, true);
                            setAccessNeeded(true);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        {" "}
                        <Button
                          color="danger"
                          outline
                          style={{ marginTop: "33px", fontSize: "12px" }}
                          onClick={addRow}
                        >
                          Add Directors
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
            <hr style={{ border: "1px solid #000000" }}></hr>
            {/* {labMainAddress &&
            Array.isArray(labMainAddress).map((index, row) => { */}
            <p style={{ color: "red" }}>
              Want to add more Administrator/Coordinator then click on "Add
              Administrator/Coordinator"
            </p>
            {adminRows &&
              adminRows?.map((adminRow, adminIndex) => (
                <>
                  <Row key={adminIndex} className="mt-4">
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`adminName${adminIndex}`}>
                          <b>Administrator/Coordinator Name</b>
                        </Label>
                        <Input
                          id={`adminName${adminIndex}`}
                          name="adminName"
                          placeholder="Enter Name"
                          type="text"
                          value={adminRow.adminName}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`adminPhoneNumber${adminIndex}`}>
                          <b>Phone Number</b>
                        </Label>
                        <Input
                          id={`adminPhoneNumber${adminIndex}`}
                          name="adminPhoneNumber"
                          placeholder="Enter Phone Number"
                          type="number"
                          value={adminRow.adminPhoneNumber}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for={`adminEmail${adminIndex}`}>
                          <b>Email Id</b>
                        </Label>
                        <Input
                          id={`adminEmail${adminIndex}`}
                          name="adminEmail"
                          placeholder="Enter Email Id"
                          type="email"
                          value={adminRow.adminEmail}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for={`adminAccessNeeded${adminIndex}`}>
                          <b>Does this person needs access to this account?</b>
                        </Label>
                        <br />
                        <Input
                          name={`adminAccessNeeded${adminIndex}`}
                          type="radio"
                          checked={!adminRow.accessNeeded}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e, false);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          No
                        </Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Input
                          name={`adminAccessNeeded${adminIndex}`}
                          type="radio"
                          checked={adminRow.accessNeeded}
                          onChange={(e) => {
                            handleAdminChange(adminIndex, e, true);
                          }}
                        />
                        &nbsp;
                        <Label check className="ml-1">
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>
                    {adminIndex === adminRows.length - 1 && (
                      <Col md={2}>
                        <FormGroup>
                          <Button
                            color="danger"
                            outline
                            style={{ marginTop: "33px", fontSize: "12px" }}
                            onClick={addAdminRow}
                          >
                            Add Administrator/Coordinator
                          </Button>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </>
              ))}

            <hr style={{ border: "1px solid #000000" }}></hr>

            <>
              <Row className="mt-4">
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      <b>Main Office / Lab Address</b>
                    </Label>
                    <Input
                      name="labAddress"
                      placeholder="Enter Address"
                      value={labAddress}
                      type="text"
                      onChange={(e) => {
                        // handleAddressChange(addressIndex, e);
                        setLabAddress(e.target.value);
                        handleLabAddress(e);
                      }}
                      style={{
                        borderColor: fieldErrors.labAddress
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      <b>Phone Number</b>
                    </Label>
                    <Input
                      name="labPhoneNumber"
                      placeholder="Enter Phone Number of Lab"
                      value={labPhoneNumber}
                      type="number"
                      onChange={(e) => {
                        // handleAddressChange(addressIndex, e);
                        setLabPhoneNumber(e.target.value);
                        handlelabPhoneNumber(e);
                      }}
                      style={{
                        borderColor: fieldErrors.labPhoneNumber
                          ? "red"
                          : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      <b>Pin Code</b>
                    </Label>
                    <Input
                      name="labPinCode"
                      placeholder="Enter PinCode"
                      value={pincode}
                      onChange={(e) => {
                        // handleAddressChange(addressIndex, e);
                        handlePinCode(e);
                      }}
                      type="number"
                      style={{
                        borderColor: fieldErrors.pincode ? "red" : "lightgrey",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-5">
                <div>
                  <Label>
                    <b>Lab Working Timings</b>
                  </Label>
                  <Table
                    bordered
                    responsive
                    style={{ border: "1px solid #ddd" }}
                  >
                    <thead>
                      <tr
                        style={{
                          background:
                            "linear-gradient(90deg,royalblue, mediumvioletred)",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <th>Day</th>
                        <th>From</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {daysOfWeek.map((day, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                            textAlign: "center",
                          }}
                        >
                          <td>
                            <b style={{ color: "#333" }}>{day}</b>
                          </td>
                          <td>
                            <Input
                              type="time"
                              value={timings[day]?.from || ""}
                              onChange={(e) =>
                                handleTimingChange(day, "from", e.target.value)
                              }
                              style={{
                                textAlign: "center",
                              }}
                            />
                          </td>
                          <td>
                            <Input
                              type="time"
                              value={timings[day]?.to || ""}
                              onChange={(e) =>
                                handleTimingChange(day, "to", e.target.value)
                              }
                              style={{
                                textAlign: "center",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {fieldErrors.timings && (
                    <p className="error">
                      Provide atleast any one working timings.
                    </p>
                  )}
                </div>
                <Row>
                  <Col>
                    <b
                      style={{
                        color: fieldErrors.sampleCollectionService
                          ? "red"
                          : "black",
                      }}
                    >
                      Provide sample collection service from here for different
                      pincode ?
                    </b>
                    &nbsp;&nbsp;&nbsp;
                    <Input
                      name="serviceProvideWithSameAddress"
                      type="radio"
                      checked={serviceProvideWithSameAddress === false}
                      onChange={(e) => {
                        setServiceProvideWithSameAddress(false);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          sampleCollectionService: false,
                        }));
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      No
                    </Label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Input
                      name="serviceProvideWithSameAddress"
                      type="radio"
                      checked={
                        // (numberOfLocation == 0 || numberOfLocation == 1) &&
                        serviceProvideWithSameAddress === true
                      }
                      onChange={(e) => {
                        setServiceProvideWithSameAddress(true);
                        setFieldErrors((prevErrors) => ({
                          ...prevErrors,
                          sampleCollectionService: false,
                        }));
                      }}
                    />
                    &nbsp;
                    <Label check className="ml-1">
                      Yes
                    </Label>
                  </Col>
                </Row>
                {serviceProvideWithSameAddress == true && (
                  <>
                    <Row>
                      <Col md={12} className="mt-3">
                        <FormGroup>
                          {/* <Label for={`LocPincode${addressIndex}`}> */}
                          <b>
                            List all PIN Codes where this location will provide
                            home collection service
                          </b>
                          {/* </Label> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* {Array.isArray(serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode) &&
                    serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode?.map((row, index) => (
                      <>
                        <Row key={index}> */}
                    {/* {console.log("row", row)}
                          <Col md="4"> */}
                    {/* <FormGroup> */}
                    {/* <Input
                                // id={`locPincode${index}`}
                                name={`locPincode${index}`}
                                placeholder="Enter Pincode"
                                value={row}
                                type="number"
                                onChange={(e) => {
                                  handlePincodeChange(index, e);
                                  setServiceAreaPinCode(e.target.value);
                                  // handleLocPinCode(e);
                                }}
                                style={{
                                  borderColor: fieldErrors ? "red" : "lightgrey",
                                }}
                              /> */}
                    {/* </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup> */}
                    {/* <Button color="danger" outline style={{ fontSize: "15px", width: "75%" }} onClick={() => addPincode(addressIndex)}>
                              Add more pincode
                            </Button> */}
                    {/* </FormGroup>
                          </Col>
                        </Row>
                      </> */}
                    {/* // ))} */}
                    {pincodeRow &&
                      pincodeRow.map((row, index) => (
                        <>
                          <Row key={index}>
                            <Col md="4">
                              <FormGroup>
                                <Input
                                  id={`locPincode${index}`}
                                  name="locPincode"
                                  placeholder="Enter Pincode"
                                  value={row.pincode}
                                  type="number"
                                  onChange={(e) => {
                                    handlePincodeChange(index, e);
                                    setServiceAreaPinCode(e.target.value);
                                    // handleLocPinCode(e);
                                  }}
                                  style={{
                                    borderColor: fieldErrors.locPincode
                                      ? "red"
                                      : "lightgrey",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Button
                                  color="danger"
                                  outline
                                  style={{ fontSize: "15px", width: "75%" }}
                                  onClick={addPincode}
                                >
                                  Add more pincode
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      ))}
                    <div>
                      {/* Display pin codes separated by comma */}
                      <b>Pin Codes where service is provided : </b>{" "}
                      {serviceAreaPinCode?.labServiceAreaPinCode?.serviceAreaPinCode.join(
                        ", "
                      )}
                    </div>
                    <Row>
                      <div style={{ marginTop: "20px" }}>
                        <Label>
                          <b>Sample Collection Timings</b>
                        </Label>
                        <Table
                          bordered
                          responsive
                          style={{ border: "1px solid #ddd" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background:
                                  "linear-gradient(90deg,royalblue,mediumvioletred)",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <th>Day</th>
                              <th>From</th>
                              <th>To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {daysOfWeek.map((day, index) => (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                <td>
                                  <b style={{ color: "#333" }}>{day}</b>
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={sampleTimings[day]?.from || ""}
                                    onChange={(e) =>
                                      handleSampleTimingChange(
                                        day,
                                        "from",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.from
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={sampleTimings[day]?.to || ""}
                                    onChange={(e) =>
                                      handleSampleTimingChange(
                                        day,
                                        "to",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.to
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {fieldErrors.sampleTimings && (
                          <p className="error">
                            Provide atleast any one sample collection timings.
                          </p>
                        )}
                      </div>
                    </Row>
                  </>
                )}

                <Col>
                  <b>Sunday is Holiday?</b> &nbsp;&nbsp;&nbsp;&nbsp;
                  <Input
                    name="sundayHoliday"
                    type="radio"
                    checked={sundayIsHoliday === false}
                    onChange={(e) => {
                      setSundayIsHoliday(false);
                    }}
                  />
                  &nbsp;
                  <Label check className="ml-1">
                    No
                  </Label>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Input
                    name="sundayHoliday"
                    type="radio"
                    checked={sundayIsHoliday === true}
                    onChange={(e) => {
                      setSundayIsHoliday(true);
                    }}
                  />
                  &nbsp;
                  <Label check className="ml-1">
                    Yes
                  </Label>
                </Col>
              </Row>
            </>
            {/* })} */}
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col md={12}>
                <FormGroup style={{ marginTop: "25px" }}>
                  <Label for="exampleEmail">
                    <b>What Licenses you carry for your Establishment:</b>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {licenses?.map((license, index) => (
                <Col md={4} key={index}>
                  <FormGroup>
                    <Label>
                      <b>License No. {index + 1}</b>
                    </Label>
                    <Input
                      name={`Licenses${index + 1}`}
                      value={license.license}
                      onChange={(e) => {
                        handleLicenseChange(index, e.target.value);
                        handleLicenses(e);
                      }}
                      placeholder="Enter License Name"
                      type="text"
                      style={{
                        borderColor:
                          index == 0
                            ? fieldErrors.licenses1
                              ? "red"
                              : "lightgrey"
                            : "lightgrey",
                      }}
                    />
                    <label
                      htmlFor={`licenses${index + 1}`}
                      style={{
                        cursor: "pointer",
                        color: "#5353e9",
                        textDecoration: "underline",
                      }}
                    >
                      Attach License {index + 1}
                    </label>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      id={`licenses${index + 1}`}
                      className="file-upload-input"
                      name={`licenses${index + 1}`}
                      style={{ display: "none" }}
                      onChange={(event) => handleFileChange(event, index)}
                    />

                    {license.fileName && (
                      // <Typography
                      //   variant="body2"
                      //   style={{
                      //     marginTop: "10px",
                      //     color: "#333",
                      //   }}
                      // >
                      //   <strong>Attached File:</strong> {license.fileName}
                      // </Typography>
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Selected File
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              // maxWidth: "300px",
                              width: "100%",
                            }}
                          >
                            {/* File Name */}
                            <Typography
                              variant="body2"
                              style={{ wordBreak: "break-all" }}
                            >
                              {license.fileName}
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    )}

                    {license.documentLink && (
                      <div
                        style={{
                          backgroundColor: "#f9f9f9",
                          marginTop: "10px",
                          padding: "15px",
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {/* File Link */}
                        <p
                          style={{
                            fontSize: "small",
                            color: "#333",
                            marginBottom: "10px",
                            wordBreak: "break-all",
                          }}
                        >
                          <strong>License Link:</strong> {license.documentLink}
                        </p>

                        {/* Buttons to view and download the file */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <IconButton
                            onClick={() => handleDocumentView(license)}
                          >
                            <VisibilityIcon color="action" />
                          </IconButton>
                          <DownloadForOfflineIcon
                            onClick={() => handleDownload(license.documentLink)}
                            style={{
                              cursor: "pointer",
                              color: "#4caf50",
                              fontSize: "24px",
                              transition:
                                "transform 0.3s ease, color 0.3s ease",
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.transform = "scale(1.2)")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.transform = "scale(1)")
                            }
                          />

                          <DeleteForeverIcon
                            onClick={() =>
                              handleLicenseCertificateDelete(index, license.id)
                            }
                            style={{
                              cursor: "pointer",
                              color: "#f44336",
                              fontSize: "24px",
                              transition:
                                "transform 0.3s ease, color 0.3s ease",
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.transform = "scale(1.2)")
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.transform = "scale(1)")
                            }
                          />
                        </div>

                        {/* Display PDF or Image */}
                        {license.isFileViewable && (
                          <iframe
                            src={license.documentLink}
                            style={{
                              width: "100%",
                              height: "400px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              marginTop: "10px",
                            }}
                            title="License View"
                          />
                        )}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              ))}
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row style={{ marginTop: "30px" }} className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label
                    for="examplePassword"
                    style={{
                      color: fieldErrors.nablAccreditation ? "red" : "inherit",
                    }}
                  >
                    <b>Do you have NABL Accreditation?</b>
                  </Label>
                </FormGroup>
              </Col>
              <Col md={8}>
                <div className="d-flex align-items-center">
                  <FormGroup check className="form-inline mr-3">
                    <Input
                      name="nablAccreditation"
                      type="radio"
                      checked={nablAccreditation === true}
                      onChange={() => {
                        setNablAccreditation(true);
                        handleNablAccreditationChange(true);
                      }}
                    />
                    <Label check className="mr-3 ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                  <FormGroup
                    check
                    className="form-inline"
                    style={{ marginLeft: "5%" }}
                  >
                    <Input
                      name="nablAccreditation"
                      type="radio"
                      checked={nablAccreditation === false}
                      onChange={() => {
                        setNablAccreditation(false);
                        handleNablAccreditationChange(false);
                      }}
                    />
                    <Label check className="ml-1">
                      No
                    </Label>
                  </FormGroup>
                  {nablAccreditation && (
                    <>
                      <span>
                        <label
                          htmlFor="nablAccreditation"
                          style={{
                            cursor: "pointer",
                            color: "rgb(83, 83, 233)",
                            textDecoration: "underline",
                            marginLeft: "20px",
                            color: fieldErrors.NABLreports ? "red" : "blue",
                          }}
                        >
                          Attach NABL Certificate Copy
                        </label>
                      </span>
                      <div>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .pdf, .png"
                          id="nablAccreditation"
                          className="file-upload-input"
                          name="nablAccreditation"
                          // onChange={(event) => handleFileSubmit(event, row.id)}
                          style={{ display: "none" }}
                          onChange={(event) => handleNABLChange(event)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Col>
              {nablAccreditation && (
                <>
                  {selectedNABLFile.length > 0 && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Selected File
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedNABLFile.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              // maxWidth: "300px",
                              width: "100%",
                            }}
                          >
                            {/* File Name */}
                            <Typography variant="body2">{file.name}</Typography>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                  {nablCertificates.length > 0 && (
                    <>
                      <Typography
                        variant="h6"
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        List of All Attached NABL Certificates
                      </Typography>
                      <div style={{ padding: "10px" }}>
                        {nablCertificates.map((nabl, index) => (
                          <Card
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "15px",
                              padding: "10px",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              border: "1px solid #e0e0e0",
                            }}
                          >
                            {/* ISO Name */}
                            <CardContent style={{ flex: 1, padding: "10px" }}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: "500",
                                  color: "#3f51b5",
                                }}
                              >
                                NABL Name: {nabl.license}
                              </Typography>
                            </CardContent>

                            {/* File Download Link */}
                            <CardContent style={{ flex: 2, padding: "10px" }}>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#4caf50",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDownload(nabl.documentLink)
                                }
                              >
                                {nabl.documentLink || "File Not Available"}
                              </Typography>
                            </CardContent>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() => handleDocumentView(nabl)}
                              >
                                <VisibilityIcon color="action" />
                              </IconButton>
                              <DownloadForOfflineIcon
                                onClick={() =>
                                  handleDownload(nabl.documentLink)
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#4caf50",
                                  fontSize: "24px",
                                  transition:
                                    "transform 0.3s ease, color 0.3s ease",
                                }}
                                onMouseEnter={(e) =>
                                  (e.target.style.transform = "scale(1.2)")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.transform = "scale(1)")
                                }
                              />
                              <DeleteForeverIcon
                                onClick={() =>
                                  handleNABLCertificateDelete(index, nabl.id)
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#f44336",
                                  fontSize: "24px",
                                  transition:
                                    "transform 0.3s ease, color 0.3s ease",
                                }}
                                onMouseEnter={(e) =>
                                  (e.target.style.transform = "scale(1.2)")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.transform = "scale(1)")
                                }
                              />
                            </div>
                          </Card>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col md={4}>
                <FormGroup>
                  <Label
                    for="examplePassword"
                    style={{
                      color: fieldErrors.isoCertificate ? "red" : "inherit",
                    }}
                  >
                    <b>Do you have ISO Certification?</b>
                  </Label>
                </FormGroup>
              </Col>
              <Col md={8}>
                <div className="d-flex align-items-center">
                  <FormGroup check className="form-inline mr-3">
                    <Input
                      name="isoCertificate"
                      type="radio"
                      checked={isoCertificate === true}
                      onChange={() => {
                        setIsoCertificate(true);
                        handleIsoCertification(true);
                      }}
                    />
                    <Label check className="mr-3 ml-1">
                      Yes
                    </Label>
                  </FormGroup>
                  <FormGroup
                    check
                    className="form-inline"
                    style={{ marginLeft: "5%" }}
                  >
                    <Input
                      name="isoCertificate"
                      type="radio"
                      checked={isoCertificate === false}
                      onChange={() => {
                        setIsoCertificate(false);
                        handleIsoCertification(false);
                      }}
                    />
                    <Label check className="ml-1">
                      No
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              {isoCertificate && (
                <div>
                  {isoStandards.map((standard, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: "20px",
                        padding: "10px",
                        border: fieldErrors.ISOreports
                          ? "1px solid red"
                          : "1px solid #e0e0e0",
                        borderRadius: "8px",
                      }}
                    >
                      <Label for={`isoName-${index}`}>ISO Standard Name</Label>
                      <div className="d-flex align-items-center mt-2">
                        <Input
                          id={`isoName-${index}`}
                          type="text"
                          value={standard.name}
                          onChange={(e) => handleISOStandardChange(e, index)}
                          placeholder={`Enter ISO Standard ${index + 1}`}
                          style={{ width: "300px", marginRight: "10px" }}
                        />
                        <Button
                          color="primary"
                          onClick={() =>
                            document
                              .getElementById(`isoCertificate-${index}`)
                              .click()
                          }
                          style={{ marginLeft: "10px" }}
                        >
                          Attach ISO Certificate
                        </Button>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .pdf, .png"
                          id={`isoCertificate-${index}`}
                          style={{ display: "none" }}
                          onChange={(e) => handleISOChange(e, index)}
                        />
                      </div>
                      {fieldErrors[`isoName-${index}`] && (
                        <span
                          style={{
                            color: "red",
                            marginTop: "5px",
                            display: "block",
                          }}
                        >
                          Please enter the ISO certificate name.
                        </span>
                      )}
                      {standard.file && (
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "center",
                            color: "#4caf50",
                          }}
                        >
                          <span>{standard.file.name}</span>
                        </div>
                      )}
                    </div>
                  ))}
                  <Button color="success" onClick={addISOStandardField}>
                    Add Another ISO Standard
                  </Button>
                </div>
              )}
              {isoCertificates && (
                <>
                  {isoCertificates.length > 0 && (
                    <>
                      <Typography
                        variant="h6"
                        style={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        List of All Attached ISO Certificates
                      </Typography>
                      <div style={{ padding: "10px" }}>
                        {isoCertificates.map((iso, index) => (
                          <Card
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "15px",
                              padding: "10px",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              border: "1px solid #e0e0e0",
                            }}
                          >
                            {/* ISO Name */}
                            <CardContent style={{ flex: 1, padding: "10px" }}>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: "500",
                                  color: "#3f51b5",
                                }}
                              >
                                ISO Name: {iso.license}
                              </Typography>
                            </CardContent>

                            {/* File Download Link */}
                            <CardContent style={{ flex: 2, padding: "10px" }}>
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#4caf50",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDownload(iso.documentLink)}
                              >
                                {iso.documentLink || "File Not Available"}
                              </Typography>
                            </CardContent>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() => handleDocumentView(iso)}
                              >
                                <VisibilityIcon color="action" />
                              </IconButton>
                              <DownloadForOfflineIcon
                                onClick={() => handleDownload(iso.documentLink)}
                                style={{
                                  cursor: "pointer",
                                  color: "#4caf50",
                                  fontSize: "24px",
                                  transition:
                                    "transform 0.3s ease, color 0.3s ease",
                                }}
                                onMouseEnter={(e) =>
                                  (e.target.style.transform = "scale(1.2)")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.transform = "scale(1)")
                                }
                              />
                              <DeleteForeverIcon
                                onClick={() =>
                                  handleISOCertificateDelete(index, iso.id)
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#f44336",
                                  fontSize: "24px",
                                  transition:
                                    "transform 0.3s ease, color 0.3s ease",
                                }}
                                onMouseEnter={(e) =>
                                  (e.target.style.transform = "scale(1.2)")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.transform = "scale(1)")
                                }
                              />
                            </div>
                          </Card>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </Row>
            <hr style={{ border: "1px solid #000000" }}></hr>
            <Row className="mt-4">
              <Col>
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <p
                    style={{
                      padding: "1%",
                      fontSize: "16px",
                      color: fieldErrors.reports ? "red" : "#333",
                    }}
                  >
                    Please submit <b>7 - 8 sample copies</b> of your past{" "}
                    <b>Test Reports</b> of your patients. We need these reports
                    to train our AI Model with your reports. You may <b>hide</b>{" "}
                    the patient information (Attach one at a time).
                  </p>

                  <div
                    style={{
                      margin: "15px 0",
                      border: "1px dashed #ccc",
                      borderRadius: "6px",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    {isLoading ? (
                      // Show Circular Progress during upload
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "50px",
                        }}
                      >
                        <CircularProgress size={30} />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .pdf, .png"
                        id="fileUpload"
                        className="file-upload-input"
                        name="file"
                        onChange={(event) => {
                          handleMultipleChange(event);
                        }}
                        multiple
                        style={{ padding: "10px" }}
                      />
                    )}
                  </div>

                  {/* Uploaded Reports Section */}

                  {selectedSampleTestReport.length > 0 && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Selected Files
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedSampleTestReport.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              // maxWidth: "300px",
                              width: "100%",
                            }}
                          >
                            {/* File Name */}
                            <Typography variant="body2">{file.name}</Typography>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}

                  {labPartnerSampleTest.length === 0 &&
                    selectedSampleTestReport.length === 0 && (
                      <div style={{ marginTop: "20px" }}>
                        <b>Reports:</b> No Reports Available{" "}
                      </div>
                    )}
                  {labPartnerSampleTest.length > 0 && (
                    <div>
                      <Button
                        color="success"
                        onClick={() => setisSampleReportModalOpen(true)}
                      >
                        View Reports
                      </Button>
                      <SampleTestReportModal
                        open={SampleReportModalOpen}
                        onClose={() => setisSampleReportModalOpen(false)}
                        data={labPartnerSampleTest}
                      />
                    </div>
                  )}

                  {/* Note Section */}
                  <div
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#e3f2fd",
                      padding: "15px",
                      borderRadius: "6px",
                      border: "1px solid #90caf9",
                    }}
                  >
                    <p style={{ margin: "0", fontSize: "14px", color: "#333" }}>
                      <b>Note:</b> If you have any query regarding this, please
                      reach out to our Marketing Manager at:{" "}
                      <span style={{ color: "blue", fontWeight: "bold" }}>
                        +91 9082950730
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          <center>
            <Button
              onClick={handleSubmit}
              style={{
                marginTop: "4%",
                width: "15%",
                backgroundColor: "rgb(83, 83, 233)",
              }}
            >
              {submitting ? (
                <CircularProgress color="inherit" size={20}></CircularProgress>
              ) : (
                "Save"
              )}
            </Button>
          </center>
          {viewModalOpen && (
            <Modal open={viewModalOpen} onClose={closeViewModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "60%",
                  maxHeight: "90vh",
                  overflowY: "auto",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 3,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    borderBottom: "1px solid #ccc",
                    pb: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    Viewing: {selectedFile?.license}
                  </Typography>
                  <IconButton onClick={closeViewModal} sx={{ color: "#000" }}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {isLoadingView ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : selectedFile?.blobUrl ? (
                  <iframe
                    src={selectedFile.blobUrl}
                    title={selectedFile?.license}
                    style={{ width: "100%", height: "60vh", border: "none" }}
                  />
                ) : (
                  <Typography color="error">
                    Failed to load document.
                  </Typography>
                )}
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button variant="contained" onClick={closeViewModal}>
                    Close
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
export default GeneralInfo;
