import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useCart } from "../../screens/TestCart/CartContext"
import ZumbaTrainer from "./zumbaTrainer";
import YogaTrainer from "./YogaTrainer";
import FitnessTrainerGeneralInfo from "./fitnessTrainerGeneralInfo";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
const FitnessTrainingScreen = () => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const { refresh, triggerRefresh } = useCart();
    const handleTabChange = (e, tabIndex) => {
      // if(tabIndex === 4){
      //   setOrderId('')
      // }
      console.log(tabIndex);
      triggerRefresh();
      setCurrentTabIndex(tabIndex);
    };
    useEffect(() => {
    }, []);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={currentTabIndex} onChange={handleTabChange} sx={{ backgroundColor: "steelblue" }}>
          <Tab
            label="Zumba/CrossFit"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Yoga"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <ZumbaTrainer setCurrentTabIndex={setCurrentTabIndex}></ZumbaTrainer>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <YogaTrainer setCurrentTabIndex={setCurrentTabIndex}></YogaTrainer>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FitnessTrainingScreen;
