import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import GymFreeTrialOrders from "./GymFreeTrialOrders";
import GymMembershipOrders from "./GymMembershipOrders";
import GymLSHMembershipOrders from "./GymLSHMembershipOrders";

const GymOrders = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          sx={{ backgroundColor: "steelblue" }}
        >
          <Tab
            label="Free Trial"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 0 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="Membership"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 1 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
          <Tab
            label="LSH Membership"
            sx={{
              textTransform: "capitalize",
              fontWeight: 600,
              fontSize: "large",
              color: currentTabIndex === 2 ? "white" : "black",
              "&.Mui-selected": {
                color: "white",
              },
            }}
          />
        </Tabs>
        <br />
        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <GymFreeTrialOrders></GymFreeTrialOrders>
          </Box>
        )}
        {currentTabIndex === 1 && (
          <Box sx={{ p: 3 }}>
            <GymMembershipOrders></GymMembershipOrders>
          </Box>
        )}
        {currentTabIndex === 2 && (
          <Box sx={{ p: 3 }}>
            <GymLSHMembershipOrders></GymLSHMembershipOrders>
          </Box>
        )}
      </Box>
    </>
  );
};

export default GymOrders;
