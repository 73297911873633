import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getAddressInfo } from "../../constants/apiList";
import { CircularProgress } from "@mui/material";
import Spinner from "../../shared/Spinner";

const LabAddress = ({ handleTabChange }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addingPincodeFor, setAddingPincodeFor] = useState({});
  const [submitting, setsubmitting] = useState(false);

  const [addressrow, setAddressRow] = useState([
    {
      id: null,
      labAddress: "",
      stateAdminName: "",
      stateAdminEmail: "",
      numberOfCityLocation: "",
      labPhoneNumber: "",
      labPinCode: "",
      coordinatorDetails: [
        {
          adminName: "",
          adminPhoneNumber: "",
          adminEmail: "",
          accessNeeded: false,
        },
      ],
      timings: {
        Monday: { from: "", to: "" },
        Tuesday: { from: "", to: "" },
        Wednesday: { from: "", to: "" },
        Thursday: { from: "", to: "" },
        Friday: { from: "", to: "" },
        Saturday: { from: "", to: "" },
        Sunday: { from: "", to: "" },
      },
      locationLabPinCode: {
        serviceAreaPinCode: [],
        sampleCollectionTiming: {
          Monday: { from: "", to: "" },
          Tuesday: { from: "", to: "" },
          Wednesday: { from: "", to: "" },
          Thursday: { from: "", to: "" },
          Friday: { from: "", to: "" },
          Saturday: { from: "", to: "" },
          Sunday: { from: "", to: "" },
        },
      },
    },
  ]);
  const addAddressRow = () => {
    setAddressRow([
      ...addressrow,
      {
        id: null,
        labAddress: "",
        stateAdminName: "",
        stateAdminEmail: "",
        numberOfCityLocation: "",
        labPhoneNumber: "",
        labPinCode: "",
        coordinatorDetails: [
          {
            adminName: "",
            adminPhoneNumber: "",
            adminEmail: "",
            accessNeeded: false,
          },
        ],
        timings: {
          Monday: { from: "", to: "" },
          Tuesday: { from: "", to: "" },
          Wednesday: { from: "", to: "" },
          Thursday: { from: "", to: "" },
          Friday: { from: "", to: "" },
          Saturday: { from: "", to: "" },
          Sunday: { from: "", to: "" },
        },
        locationLabPinCode: {
          serviceAreaPinCode: [],
          sampleCollectionTiming: {
            Monday: { from: "", to: "" },
            Tuesday: { from: "", to: "" },
            Wednesday: { from: "", to: "" },
            Thursday: { from: "", to: "" },
            Friday: { from: "", to: "" },
            Saturday: { from: "", to: "" },
            Sunday: { from: "", to: "" },
          },
        },
      },
    ]);
  };

  const addPincode = (addressIndex) => {
    const newAddressRows = Array.from(addressrow);
    // Ensure locationLabPinCode is always treated as an array
    newAddressRows[addressIndex].locationLabPinCode =
      newAddressRows[addressIndex].locationLabPinCode || {};
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode =
      newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode || [];

    // Add a new empty string to serviceAreaPinCode array
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode = [
      ...newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode,
      "",
    ];
    setAddressRow(newAddressRows);
    setAddingPincodeFor((prev) => ({
      ...prev,
      [addressIndex]: true,
    }));
  };
  const handleAddressChange = (index, e) => {
    const newRows = [...addressrow];
    newRows[index][e.target.name] = e.target.value;
    setAddressRow(newRows);
  };

  const handleAdminChange = (addressIndex, adminIndex, field, value) => {
    setAddressRow((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[addressIndex].coordinatorDetails[adminIndex][field] = value;
      return updatedRows;
    });
  };

  // Adding a new administrator
  const addAdminRow = (addressIndex) => {
    setAddressRow((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[addressIndex].coordinatorDetails.push({
        adminName: "",
        adminPhoneNumber: "",
        adminEmail: "",
        accessNeeded: false,
      });
      return updatedRows;
    });
  };

  const handlePincodeChange = (addressIndex, index, event) => {
    const newAddressRows = [...addressrow];
    newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode[index] =
      event.target.value;

    setAddressRow(newAddressRows);
  };
  const handleLabAddress = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].labAddress;
      }

      return updatedErrors;
    });
  };
  const handlestateAdminName = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].stateAdminName;
      }

      return updatedErrors;
    });
  };
  const handlestateAdminEmail = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].stateAdminEmail;
      }

      return updatedErrors;
    });
  };
  const handlenumberOfCityLocation = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].numberOfCityLocation;
      }

      return updatedErrors;
    });
  };
  const handlelabPhoneNumber = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].labPhoneNumber;
      }

      return updatedErrors;
    });
  };
  const handlePinCode = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].labPinCode;
      }

      return updatedErrors;
    });
  };
  const uploadFile = async (file, serviceAreaAddressId) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Notify the user if upload is successful
      AxiosInstance.post(
        `lab-partner/service-pinCode-import?serviceAreaAddressId=${serviceAreaAddressId}`,
        formData
      )
        .then((response) => {
          toast.success("File uploaded successfully!");
          setUploadingIndex(null);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("File upload failed. Please try again.");
    }
  };
  const handleFileChange = (index, e, serviceAreaAddressId) => {
    const file = e.target.files[0]; // Get the selected file
    const allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i;
    if (file && allowedExtensions.test(file.name)) {
      // Clear previous errors
      setFileErrors((prev) => ({
        ...prev,
        [index]: null,
      }));

      // Store the file name
      setFileNames((prev) => ({
        ...prev,
        [index]: file.name,
      }));
      setUploadingIndex(index);

      // Send the file to the API
      uploadFile(file, serviceAreaAddressId);
    } else {
      // Show error message if the file is not an Excel file
      setFileErrors((prev) => ({
        ...prev,
        [index]: "Only Excel files (.xlsx, .xls, .csv) are allowed.",
      }));
      setFileNames((prev) => ({
        ...prev,
        [index]: null,
      }));
    }
    console.log("serviceAreaAddressId", serviceAreaAddressId);
  };
  const handleLocPinCode = (event) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      locPincode: false,
    }));
  };
  const handleTimingChange = (addressIndex, day, field, value) => {
    const updatedRows = [...addressrow];
    updatedRows[addressIndex].timings[day][field] = value;
    setAddressRow(updatedRows);

    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }; // Ensure we work with an object
      // Update only the specific addressIndex

      if (updatedErrors[addressIndex]) {
        delete updatedErrors[addressIndex].timings;
        // Clean up the object if it's empty
        if (Object.keys(updatedErrors[addressIndex]).length === 0) {
          delete updatedErrors[addressIndex];
        }
      }

      return updatedErrors;
    });
  };
  const handleSampleCollectionTimingChange = (
    addressIndex,
    day,
    field,
    value
  ) => {
    const updatedRows = [...addressrow];
    updatedRows[addressIndex].locationLabPinCode.sampleCollectionTiming[day][
      field
    ] = value;
    setAddressRow(updatedRows);

    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }; // Ensure we work with an object
      // Update only the specific addressIndex

      if (updatedErrors[addressIndex]) {
        delete updatedErrors[addressIndex].sampleTimings;
        // Clean up the object if it's empty
        if (Object.keys(updatedErrors[addressIndex]).length === 0) {
          delete updatedErrors[addressIndex];
        }
      }

      return updatedErrors;
    });
  };
  const transformTimingsToLabTiming = (timings) => {
    return Object.keys(timings).map((day) => ({
      day: day,
      startTime: timings[day].from,
      endTime: timings[day].to,
    }));
  };
  const transformLabTimingToTimings = (labTimingArray) => {
    return labTimingArray.reduce((acc, item) => {
      acc[item.day] = {
        from: item.startTime,
        to: item.endTime,
      };
      return acc;
    }, {});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};

    addressrow.forEach((address, index) => {
      const addressErrors = {};
      if (address.labAddress == undefined || address.labAddress == "") {
        addressErrors.labAddress = true;
      }
      if (address.stateAdminName == undefined || address.stateAdminName == "") {
        addressErrors.stateAdminName = true;
      }
      if (address.labPhoneNumber == undefined || address.labPhoneNumber == "") {
        addressErrors.labPhoneNumber = true;
      }
      if (
        address.stateAdminEmail == undefined ||
        address.stateAdminEmail == ""
      ) {
        addressErrors.stateAdminEmail = true;
      }
      if (address.labPinCode == undefined || address.labPinCode == "") {
        addressErrors.labPinCode = true;
      }
      if (
        address.numberOfCityLocation == undefined ||
        address.numberOfCityLocation == ""
      ) {
        addressErrors.numberOfCityLocation = true;
      }
      const isTimingsValid = Object.values(address.timings).some(
        (day) => day.from.trim() !== "" && day.to.trim() !== ""
      );

      if (!isTimingsValid) {
        addressErrors.timings = true;
      }
      const isSampleTimingsValid = Object.values(
        address.locationLabPinCode.sampleCollectionTiming
      ).some((day) => day.from.trim() !== "" && day.to.trim() !== "");
      if (!isSampleTimingsValid) {
        addressErrors.sampleTimings = true;
      }
      if (Object.keys(addressErrors).length > 0) {
        error[index] = addressErrors; // Associate errors with the specific index
      }
    });
    // if (locPincode === undefined || locPincode == "") {
    //   error.locPincode = true;
    // }
    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      setFormSubmitted(true);
      const transformedData = addressrow?.map((item) => ({
        id: item?.id ? item?.id : null,
        labPinCode: item.labPinCode,
        labAddress: item.labAddress,
        stateAdminName: item.stateAdminName,
        stateAdminEmail: item.stateAdminEmail,
        numberOfCityLocation: parseInt(item.numberOfCityLocation),
        labPhoneNumber: item.labPhoneNumber,
        labTiming: transformTimingsToLabTiming(item.timings),
        coordinatorDetails: item.coordinatorDetails,
        locationLabPinCode: {
          serviceAreaPinCode: item.locationLabPinCode?.serviceAreaPinCode
            ?.filter((loc) => loc !== null && loc !== undefined && loc !== "")
            .map((loc) => loc),
          sampleCollectionTiming: transformTimingsToLabTiming(
            item.locationLabPinCode.sampleCollectionTiming
          ),
        },
      }));
      console.log("tranformed data ", transformedData);
      setsubmitting(true);
      AxiosInstance.post("lab-partner/lab-location-address", transformedData)
        .then((response) => {
          toast.success("Information submitted successfully");
          setsubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setsubmitting(false);
        });
    }
  };

  const GetAddressInfo = async () => {
    await AxiosInstance.get(`${getAddressInfo}`)
      .then((response) => {
        // const lab = response.map((item, index) => item)
        if (response != null) {
          const createDefaultTimings = () => ({
            Monday: { from: "", to: "" },
            Tuesday: { from: "", to: "" },
            Wednesday: { from: "", to: "" },
            Thursday: { from: "", to: "" },
            Friday: { from: "", to: "" },
            Saturday: { from: "", to: "" },
            Sunday: { from: "", to: "" },
          });
          const createDefaultAdminDetails = () => [
            {
              adminName: "",
              adminPhoneNumber: "",
              adminEmail: "",
              accessNeeded: false,
            },
          ];
          const updatedData = response.map((item) => {
            return {
              id: item.id || null,
              labPinCode: item.labPinCode || "",
              stateAdminName: item.stateAdminName || "",
              stateAdminEmail: item.stateAdminEmail || "",
              numberOfCityLocation: item.numberOfCityLocation || "",
              labAddress: item.labAddress || "",
              labPhoneNumber: item.labPhoneNumber || "",
              coordinatorDetails: item.coordinatorDetails
                ? item.coordinatorDetails
                : createDefaultAdminDetails(),
              timings: item.labTiming
                ? transformLabTimingToTimings(item.labTiming)
                : createDefaultTimings(),
              locationLabPinCode: {
                serviceAreaPinCode:
                  item.locationLabPinCode?.serviceAreaPinCode || [],
                sampleCollectionTiming: item.locationLabPinCode
                  ?.sampleCollectionTiming
                  ? transformLabTimingToTimings(
                      item.locationLabPinCode.sampleCollectionTiming
                    )
                  : createDefaultTimings(),
              },
            };
          });

          setAddressRow(updatedData);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    setLoading(true);
    GetAddressInfo();
  }, []);
  console.log("address ", addressrow);
  return (
    <>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <Button color="danger" outline onClick={addAddressRow}>
              Click here to add more Locations
            </Button>
          </div>
          {addressrow &&
            addressrow?.map((row, addressIndex) => (
              <>
                <Form
                  style={{
                    border: "1px solid lightgrey",
                    padding: "15px",
                    marginTop: "20px",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    fontSize: "18px",
                  }}
                >
                  <>
                    <Row className="mt-4">
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`stateAdminName${addressIndex}`}>
                            <b>State Admin/Coordinator Name</b>
                          </Label>
                          <Input
                            for={`stateAdminName${addressIndex}`}
                            name="stateAdminName"
                            placeholder="Enter State Admin Name"
                            value={row?.stateAdminName}
                            type="text"
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              handlestateAdminName(e, addressIndex);
                            }}
                            style={{
                              borderColor: fieldErrors[addressIndex]
                                ?.stateAdminName
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labPhoneNumber${addressIndex}`}>
                            <b>State Admin/Coordinator Phone Number</b>
                          </Label>
                          <Input
                            for={`labPhoneNumber${addressIndex}`}
                            name="labPhoneNumber"
                            placeholder="Enter Location Phone Number"
                            value={row?.labPhoneNumber}
                            type="number"
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              handlelabPhoneNumber(e, addressIndex);
                            }}
                            style={{
                              borderColor: fieldErrors[addressIndex]
                                ?.labPhoneNumber
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`stateAdminEmail${addressIndex}`}>
                            <b>State Admin/Coordinator Email</b>
                          </Label>
                          <Input
                            for={`stateAdminEmail${addressIndex}`}
                            name="stateAdminEmail"
                            placeholder="Enter State Admin Email"
                            value={row?.stateAdminEmail}
                            type="email"
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              handlestateAdminEmail(e, addressIndex);
                            }}
                            style={{
                              borderColor: fieldErrors[addressIndex]
                                ?.stateAdminEmail
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labPinCode${addressIndex}`}>
                            <b>Pin Code</b>
                          </Label>
                          <Input
                            for={`labPinCode${addressIndex}`}
                            name="labPinCode"
                            placeholder="Enter Location PinCode"
                            value={row?.labPinCode}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              handlePinCode(e, addressIndex);
                            }}
                            type="number"
                            style={{
                              borderColor: fieldErrors[addressIndex]?.labPinCode
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`numberOfCityLocation${addressIndex}`}>
                            <b>No. Of City Locations</b>
                          </Label>
                          <Input
                            for={`numberOfCityLocation${addressIndex}`}
                            name="numberOfCityLocation"
                            placeholder="Enter No. Of City Location"
                            value={row?.numberOfCityLocation}
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              handlenumberOfCityLocation(e, addressIndex);
                            }}
                            type="number"
                            style={{
                              borderColor: fieldErrors[addressIndex]
                                ?.numberOfCityLocation
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labAddress${addressIndex}`}>
                            <b>Location Address</b>
                          </Label>
                          <Input
                            for={`labAddress${addressIndex}`}
                            name="labAddress"
                            placeholder="Enter Location Address"
                            value={row.labAddress}
                            type="text"
                            onChange={(e) => {
                              handleAddressChange(addressIndex, e);
                              handleLabAddress(e, addressIndex);
                            }}
                            style={{
                              borderColor: fieldErrors[addressIndex]?.labAddress
                                ? "red"
                                : "lightgrey",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr style={{ border: "1px solid #000000" }}></hr>
                    <p style={{ color: "red" }}>
                      Want to add more Administrator/Coordinator then click on
                      "Add Administrator/Coordinator"
                    </p>

                    {row.coordinatorDetails?.map((adminRow, adminIndex) => (
                      <Row key={adminIndex} className="mt-4">
                        <Col md={3}>
                          <FormGroup>
                            <Label for={`adminName${adminIndex}`}>
                              <b>Administrator/Coordinator Name</b>
                            </Label>
                            <Input
                              id={`adminName${adminIndex}`}
                              name="adminName"
                              placeholder="Enter Name"
                              type="text"
                              value={adminRow.adminName}
                              onChange={(e) =>
                                handleAdminChange(
                                  addressIndex,
                                  adminIndex,
                                  "adminName",
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for={`adminPhoneNumber${adminIndex}`}>
                              <b>Phone Number</b>
                            </Label>
                            <Input
                              id={`adminPhoneNumber${adminIndex}`}
                              name="adminPhoneNumber"
                              placeholder="Enter Phone Number"
                              type="number"
                              value={adminRow.adminPhoneNumber}
                              onChange={(e) =>
                                handleAdminChange(
                                  addressIndex,
                                  adminIndex,
                                  "adminPhoneNumber",
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for={`adminEmail${adminIndex}`}>
                              <b>Email Id</b>
                            </Label>
                            <Input
                              id={`adminEmail${adminIndex}`}
                              name="adminEmail"
                              placeholder="Enter Email Id"
                              type="email"
                              value={adminRow.adminEmail}
                              onChange={(e) =>
                                handleAdminChange(
                                  addressIndex,
                                  adminIndex,
                                  "adminEmail",
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label for={`adminAccessNeeded${adminIndex}`}>
                              <b>
                                Does this person needs access to this account?
                              </b>
                            </Label>
                            <br />
                            <Input
                              name={`adminAccessNeeded${adminIndex}`}
                              type="radio"
                              checked={!adminRow.accessNeeded}
                              onChange={() =>
                                handleAdminChange(
                                  addressIndex,
                                  adminIndex,
                                  "accessNeeded",
                                  false
                                )
                              }
                            />
                            &nbsp;
                            <Label check className="ml-1">
                              No
                            </Label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              name={`adminAccessNeeded${adminIndex}`}
                              type="radio"
                              checked={adminRow.accessNeeded}
                              onChange={() =>
                                handleAdminChange(
                                  addressIndex,
                                  adminIndex,
                                  "accessNeeded",
                                  true
                                )
                              }
                            />
                            &nbsp;
                            <Label check className="ml-1">
                              Yes
                            </Label>
                          </FormGroup>
                        </Col>
                        {adminIndex === row.coordinatorDetails.length - 1 && (
                          <Col md={2}>
                            <FormGroup>
                              <Button
                                color="danger"
                                outline
                                style={{ marginTop: "33px", fontSize: "12px" }}
                                onClick={() => addAdminRow(addressIndex)}
                              >
                                Add Administrator/Coordinator
                              </Button>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    ))}
                    <hr style={{ border: "1px solid #000000" }}></hr>

                    {row.timings && (
                      <Row className="mt-5">
                        <Col md={12}>
                          <Label>
                            <b>Working Timings</b>
                          </Label>
                        </Col>

                        <Table
                          bordered
                          responsive
                          style={{ border: "1px solid #ddd" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background:
                                  "linear-gradient(90deg,royalblue,mediumvioletred)",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <th>Day</th>
                              <th>From</th>
                              <th>To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(row.timings).map((day) => (
                              <tr
                                key={day}
                                style={{
                                  backgroundColor:
                                    Object.keys(row.timings).indexOf(day) %
                                      2 ===
                                    0
                                      ? "#f9f9f9"
                                      : "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                <td>
                                  <b style={{ color: "#333" }}>{day}</b>
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={row.timings[day].from}
                                    onChange={(e) =>
                                      handleTimingChange(
                                        addressIndex,
                                        day,
                                        "from",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.from
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={row.timings[day].to}
                                    onChange={(e) =>
                                      handleTimingChange(
                                        addressIndex,
                                        day,
                                        "to",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.to
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {fieldErrors[addressIndex]?.timings && (
                          <p className="error">
                            Provide at least one working timing.
                          </p>
                        )}
                      </Row>
                    )}

                    <hr style={{ border: "1px solid #000000" }}></hr>
                    <Row>
                      <Col md={7} className="mt-3">
                        <FormGroup>
                          <Label for={`LocPincode${addressIndex}`}>
                            <b>
                              List all PIN Codes where this location will
                              provide home collection service
                            </b>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {
                      row?.locationLabPinCode &&
                        !addingPincodeFor[addressIndex] &&
                        row.locationLabPinCode.serviceAreaPinCode.length >
                          0 && (
                          // pincodeRow.length === 0 && (
                          <>
                            <div>
                              <button
                                onClick={() => handleTabChange(null, 2, row)}
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  textDecoration: "underline",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                View All Pincodes
                              </button>
                            </div>
                          </>
                        )
                      // )
                    }
                    {addingPincodeFor[addressIndex] &&
                      row.locationLabPinCode?.serviceAreaPinCode?.map(
                        (prow, index) => (
                          <>
                            <Row key={index} className="mt-2">
                              <Col md="4">
                                <FormGroup>
                                  <Input
                                    // id={`locPincode${index}`}
                                    id={`locPincode${addressIndex}-${index}`}
                                    name="locPincode"
                                    placeholder="Enter Pincode"
                                    value={prow || ""}
                                    type="number"
                                    onChange={(e) => {
                                      handlePincodeChange(
                                        addressIndex,
                                        index,
                                        e
                                      );
                                      // handleLocPinCode(e);
                                    }}
                                    style={{
                                      borderColor: fieldErrors.locPincode
                                        ? "red"
                                        : "lightgrey",
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )
                      )}
                    <Col md={12} className="mt-2">
                      <FormGroup
                        style={{
                          gap: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          color="danger"
                          outline
                          style={{ fontSize: "15px" }}
                          onClick={() => addPincode(addressIndex)}
                        >
                          Add More Pincode
                        </Button>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          OR
                        </span>

                        <Button
                          color="primary"
                          outline
                          style={{ fontSize: "15px" }}
                          onClick={() =>
                            document
                              .getElementById(`attachFile${addressIndex}`)
                              .click()
                          }
                        >
                          {uploadingIndex === addressIndex ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Attach Pincode File*"
                          )}
                        </Button>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id={`attachFile${addressIndex}`}
                          onChange={(e) =>
                            handleFileChange(addressIndex, e, row.id)
                          }
                        />

                        {fileNames[addressIndex] && (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              marginTop: "2px",
                            }}
                          >
                            {fileNames[addressIndex]}
                          </div>
                        )}
                      </FormGroup>

                      <div className="mt-2">
                        <b>*Note:</b> Please Attach Excel File Only
                      </div>

                      {fileErrors[addressIndex] && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginTop: "5px",
                          }}
                        >
                          {fileErrors[addressIndex]}
                        </div>
                      )}
                    </Col>
                    {row.locationLabPinCode && (
                      <Row className="mt-5">
                        <Col md={12}>
                          <Label>
                            <b>Sample Collection Timings.</b>
                          </Label>
                        </Col>

                        <Table
                          bordered
                          responsive
                          style={{ border: "1px solid #ddd" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background:
                                  "linear-gradient(90deg,royalblue,mediumvioletred)",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <th>Day</th>
                              <th>From</th>
                              <th>To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(
                              row.locationLabPinCode.sampleCollectionTiming
                            ).map((day) => (
                              <tr
                                key={day}
                                style={{
                                  backgroundColor:
                                    Object.keys(
                                      row.locationLabPinCode
                                        .sampleCollectionTiming
                                    ).indexOf(day) %
                                      2 ===
                                    0
                                      ? "#f9f9f9"
                                      : "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                <td>
                                  <b style={{ color: "#333" }}>{day}</b>
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={
                                      row.locationLabPinCode
                                        .sampleCollectionTiming[day].from
                                    }
                                    onChange={(e) =>
                                      handleSampleCollectionTimingChange(
                                        addressIndex,
                                        day,
                                        "from",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.from
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={
                                      row.locationLabPinCode
                                        .sampleCollectionTiming[day].to
                                    }
                                    onChange={(e) =>
                                      handleSampleCollectionTimingChange(
                                        addressIndex,
                                        day,
                                        "to",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      borderColor: fieldErrors[day]?.to
                                        ? "red"
                                        : "#ddd",
                                      textAlign: "center",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {fieldErrors[addressIndex]?.sampleTimings && (
                          <p className="error">
                            Provide at least one Sample Collection timing.
                          </p>
                        )}
                      </Row>
                    )}
                    <Row>
                      <Col md={5}></Col>
                      <Col md={3}>
                        <FormGroup></FormGroup>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </>
                </Form>
              </>
            ))}
          <center>
            <Button
              onClick={handleSubmit}
              style={{
                marginTop: "4%",
                width: "15%",
                backgroundColor: "rgb(83, 83, 233)",
              }}
            >
              {submitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </center>
        </>
      )}
    </>
  );
};
export default LabAddress;
