import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Input, Row, Toast } from "reactstrap";
import "./zumbaTrainer.css";
import trainer from "./images/trainer.jpeg"
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
const ZumbaTrainer = ({ setCurrentTabIndex }) => {
 
  const handleZumbaSession = () => {
     Swal.fire({
       text: "Free Zumba session is booked!",
       icon: "success",
     });
  }
   const handleCrossfitSession = () => {
     Swal.fire({
       text: "Free CrossFit session is booked!",
       icon: "success",
     });
   };

  return (
    <>
      <div className="card">
        <h3 style={{ fontFamily: "auto", fontSize: "xx-large", color: "firebrick", textAlign:"center", marginBottom:"20px" }}>Pallavii Karekar</h3>

        <Row>
          <Col md={2} style={{ display: "flex", justifyContent: "flex-start", paddingTop: "20px" }}>
            <div>
              <img
                src={trainer}
                alt="Trainer"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  border: "1px solid #1976d2",
                  padding: "10px",
                }}
              />
            </div>
          </Col>

          <Col md={10} style={{ paddingLeft: "20px", marginTop:"20px" }}>
            <p>
              An energetic and enthusiastic Zumba instructor dedicated to total fitness and overall well-being. With experience working with individuals of all fitness levels, helps to improve
              strength, weight management, and maximize health.
            </p>
            <Row>
              <Col md={4}>
        <h4 className="mt-4" style={{ color: "#1976d2" }}>
          Expertise
        </h4>
        <ul>
          <li>Wellness Coaching</li>
          <li>Group Classes</li>
          <li>Individual Instruction</li>
          <li>Weight Management</li>
          <li>Strength Training</li>
          <li>Cardio Fitness</li>
          <li>Equipment Expertise</li>
          <li>Exercise Plan Development</li>
        </ul>
              </Col>
              <Col md={6}>
                {" "}
        <h4 style={{ color: "#1976d2" }} className="mt-4">
                  Current Engagement
                </h4>
                <ul>
                  <li>Batches of Zumba</li>
                  <li>Crossfit</li>
                </ul>
                <p>At Energyia Gym, Kasarvadavli, Thane.</p>
                <h4 style={{ color: "#1976d2", marginTop: "20px" }}>Certifications & Recognition</h4>
                <ul>
                  <li>Completed the IFSA certification course in personal training, Vikhroli.</li>
                  <li>SS Fitness Training Academy, Thane (Zumba, Cross-Fit, Power Yoga)</li>
                  <li>Recognized with the Value Contribution Reward from SS Fitness Training Academy, Thane.</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={12}></Col>
            </Row>
          </Col>
        </Row><br></br>
        <hr></hr>
        <h4 style={{ color: "#1976d2", marginTop:"2%" }}>Zumba Benefits</h4>
        <ul>
          <li>Calorie Burner: Zumba can burn up to 400-800 calories per session.</li>
          <li>Improved Cardiovascular Health: Zumba's aerobic exercise improves heart health and increases endurance.</li>
          <li>Weight Loss: Zumba's high-energy movements help with weight loss and maintenance.</li>
          <li>Increased Metabolism: Zumba's interval training boosts metabolism, helping the body burn calories more efficiently.</li>
          <li>Improved Coordination and Balance: Zumba's dance-based movements improve coordination, balance, and overall physical fitness.</li>
          <li>Stress Relief: Zumba's fun and energetic atmosphere helps reduce stress and improve mood.</li>
          <li>Improved Muscle Tone: Zumba's movements engage multiple muscle groups, improving overall muscle tone.</li>
        </ul>

        <h4 style={{ color: "#1976d2" }}>CrossFit Benefits</h4>
        <ul>
          <li>High-Intensity Calorie Burn: CrossFit's intense workouts burn up to 700-1000 calories per session.</li>
          <li>Improved Functional Strength: CrossFit's varied exercises improve functional strength, making everyday activities easier.</li>
          <li>Increased Endurance: CrossFit's high-intensity interval training (HIIT) improves cardiovascular endurance and anaerobic capacity.</li>
          <li>Weight Loss and Management: CrossFit's combination of strength training and cardio exercises helps with weight loss and maintenance.</li>
          <li>Improved Flexibility and Mobility: CrossFit's varied movements improve flexibility, mobility, and range of motion.</li>
          <li>Mental Toughness and Accountability: CrossFit's community-driven approach fosters mental toughness, accountability, and motivation.</li>
          <li>Improved Bone Density: CrossFit's strength training exercises help improve bone density, reducing the risk of osteoporosis.</li>
        </ul>

        <h4 style={{ color: "#1976d2" }}>Combining Zumba and CrossFit</h4>
        <ul>
          <li>Well-Rounded Fitness: Combining Zumba and CrossFit provides a well-rounded fitness routine, covering cardiovascular exercise, strength training, and flexibility.</li>
          <li>Increased Caloric Burn: Alternating between Zumba and CrossFit sessions can increase overall caloric burn and enhance weight loss efforts.</li>
          <li>
            Improved Mental and Physical Fitness: Combining the fun, energetic atmosphere of Zumba with the challenging, results-driven approach of CrossFit can improve mental and physical fitness,
            overall.
          </li>
        </ul>
        <p style={{ color: "crimson", fontSize: "large" }}>
          We focus towards your fitness goals and adjust the intensity and frequency of your workouts based on your individual needs and fitness level.
        </p>

        <br></br>

        <Row>
          <Col md={4} style={{ border: "1px solid #1976d2", padding: "10px" }}>
          <h4 style={{ color: "#1976d2" }}>CrossFit Schedule (Offline)</h4>
          <Col md={12} className="mt-3">
            <b>Morning Sessions</b>
            <ul>
              <li>Monday to Friday: 7:40 AM</li>
              <li>Tuesday and Thursday: 8:40 AM</li>
              <li>Saturday: 8:30 AM and 10:30 AM</li>
            </ul>
          </Col>
          <Col md={12}>
            <b>Evening Sessions</b>
            <ul>
              <li>Tuesday and Thursday: 8:30 PM</li>
              <li>Monday and Wednesday: 7:30 PM</li>
            </ul>
          </Col>
        </Col>

        <Col md={4} style={{ border: "1px solid #1976d2", padding: "10px" }}>
          <h4 style={{ color: "#1976d2" }}>CrossFit/HIIT Workout Schedule (Online)</h4>
          <Col md={12} className="mt-3">
            <b>Morning Sessions</b>
            <ul>
              <li>Monday to Friday: 11:00 AM and 12:00 PM (noon)</li>
            </ul>
          </Col>
          <Col md={12}>
            <b>Evening Sessions</b>
            <ul>
              <li>Monday to Friday: 6:00 PM</li>
            </ul>
            </Col>
          </Col>
          <Col md={4} style={{ border: "1px solid #1976d2", padding: "10px" }}>
            <h4 style={{ color: "#1976d2" }}>Zumba Schedule (Offline)</h4>
            <Col md={12} className="mt-3">
              <b>Morning Sessions</b>
              <ul>
                <li>Monday, Wednesday, and Friday: 8:30 AM</li>
                <li>Saturday: 9:30 AM</li>
              </ul>
            </Col>
            <Col md={12}>
              <b>Evening Sessions</b>
              <ul>
                <li>Tuesday, Thursday, and Friday: 7:30 PM</li>
                <li>Monday, Wednesday, and Friday: 8:30 PM</li>
              </ul>
            </Col>
          </Col>
        </Row>

        <h5 className="mt-3" style={{ color: "crimson" }}>
          Join us for a high-energy workout from the comfort of your own space!
        </h5>
        <div>
          <Button variant="contained" color="success" type="submit" style={{ float: "inline-end", width: "20%" }} onClick={handleZumbaSession}>
            Book for Zumba free session
          </Button>
          <Button variant="contained" color="success" type="submit" style={{ float: "inline-end", width: "20%", marginRight: "20px" }} onClick={handleCrossfitSession}>
            Book for CrossFit free session
          </Button>
        </div>
      </div>
    </>
  );

};

export default ZumbaTrainer;
