import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Col, Input, Row } from "reactstrap";
import { Checkbox, FormGroup, Grid, TextField, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@mui/material";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useState } from "react";
const GymApproval = ({ onBack, user, selectedUser }) => {
  console.log("🚀 ~ GymApproval ~ selectedUser:", selectedUser)
  const [environmentStep, setEnvironmentStep] = useState(0);
  const handleEnvironmentStep1 = () => {
    setEnvironmentStep((prev) => prev + 1);
  };
 const handleEnvironmentStepKindOfEquipment = () => {
     setEnvironmentStep((prev) => prev + 1);
 };

  const handleEnvironmentStep2 = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleEnvironmentStep3 = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleEnvironmentStep4 = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleEnvironmentStep5 = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleEnvironmentStep6 = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleEnvironmentStep7 = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleAttachProgramFile = () => {
      setEnvironmentStep((prev) => prev + 1);
  };
  const handleSaveClassDescription = () => {
    setEnvironmentStep((prev) => prev + 1);
  };
  const handleTrainerSave = (id, name) => {
    setEnvironmentStep((prev) => prev + 1); // Update the environment step after saving
  };
  const handleTrainersScreen = () => {
    setEnvironmentStep((prev) => prev + 1);
  };

  const handleEnvironmentStepBack = () => {
    setEnvironmentStep((prev) => prev - 1);
  };

  const FinalPageBack = () => {
    if (classesProgramList == null || classesProgramList.length == 0) {
      setEnvironmentStep((prev) => prev - 3);
    }
    else {
      setEnvironmentStep((prev) => prev - 1);
    }
  }

  const GymFromSubmit = (e) => {
  };


  const handleDownload = (filePath) => {
      // if (!filePath) return;
    
      console.log("Downloading file from:", filePath);
    
      const downloadApi = "document/download-partner-document/GYM";
      const encodedUrl = encodeURIComponent(filePath);
      const authToken = window.sessionStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        redirect: "follow",
      };
    
      fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`, requestOptions)
        .then((response) => {
          
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }
          const contentDispositionHeader = response.headers.get("Content-Disposition");
          if (contentDispositionHeader) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
            if (matches != null && matches[1]) {
              const filename = matches[1].replace(/['"]/g, "");
              response.blob().then((blobData) => {
                const blobUrl = window.URL.createObjectURL(blobData);
                const downloadLink = document.createElement("a");
                downloadLink.href = blobUrl;
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(blobUrl);
              });
            } 
          } 
        })
        .catch((error) => {
          toast.error(`Error fetching or downloading the file: ${error}`);
        });
    };
  
    const handleDelete = (filePath, gymPhoto) => {
      console.log("filePath", gymPhoto)
      const id = gymPhoto.id
    
      const downloadApi = "document/delete-partners-document";
      const encodedUrl = encodeURIComponent(filePath);
      const authToken = window.sessionStorage.getItem("token");
      const requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        redirect: "follow",
      };
    
      fetch(`${process.env.REACT_APP_SERVER_URL}${downloadApi}/${id}/GYM`, requestOptions)
        .then((response) => {
          if(response.ok){
            Swal.fire({
              title: 'Deleted Successfully!',
              text: 'The document was deleted successfully.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
          }
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }
    
          const contentDispositionHeader = response.headers.get("Content-Disposition");
          if (contentDispositionHeader) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);
            if (matches != null && matches[1]) {
              const filename = matches[1].replace(/['"]/g, "");
              response.blob().then((blobData) => {
                const blobUrl = window.URL.createObjectURL(blobData);
                const downloadLink = document.createElement("a");
                downloadLink.href = blobUrl;
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(blobUrl);
              });
            } else {
              toast.error("Filename not found in Content-Disposition header");
            }
          } 
        })
        .catch((error) => {
          toast.error(`Error fetching or downloading the file: ${error}`);
        });
    }

  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>

      {environmentStep == 0 && (
        <Grid container spacing={2} mb={5}>
          <Grid item>
            <div className="register_title">
              <h2>GYM PARTNER QUESTIONNAIRE</h2>
            </div>
          </Grid>
        </Grid>
      )}

      <form onSubmit={GymFromSubmit} className="gym_from">
        {environmentStep == 0 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>1. General Questions </h4>
                </div>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.1 Enter Gym name
                  </FormLabel>
                  <Input
                    type="text"
                    style={{ textIndent: "10px" }}
                    className="form-control"
                    value={selectedUser?.gymName}
                    disabled
                  ></Input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">1.2 Gym Email address</FormLabel>
                  <Input type="text" className="form-control" value={selectedUser?.email} disabled></Input>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>1.3 Gym Phone Number</FormLabel>
                  <input type="text" className="form-control" value={selectedUser?.phoneNumber} disabled></input>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.4 When was this gym established ?
                  </FormLabel>
                  <Input
                    type="date"
                    style={{ textIndent: "10px" }}
                    className="form-control"
                    value={selectedUser?.gymestablised}
                    disabled
                  ></Input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.5 What is the size of the gym in terms of sq. ft. area?
                  </FormLabel>
                  <Input
                    type="number"
                    className="form-control"
                    value={selectedUser?.sizeOfGym}
                    disabled
                  ></Input>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                  >
                    1.6 What is the location?{" "}
                  </FormLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedUser?.locationOfGym}
                    disabled
                  ></input>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.7 Is the gym at the ground level?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.isGroundLevel} name="radio-buttons-group" disabled>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.8 Please describe. Give a picture of the gym.
                  </FormLabel>
                  <div style={{ alignItems: "center", gap: "10px" }}>
                    <textarea
                      name="licenses"
                      className="form-control"
                      value={selectedUser?.describeGym}
                      type="text"
                      disabled
                      // style={{
                      //   borderColor: index == 0 ? (fieldErrors ? "red" : "lightgrey") : "lightgrey",
                      // }}
                    />
                     {selectedUser?.gymPhotos?.length > 0 && (
                      <div style={{ display: "ruby" }}>
                        <span>
                          File Name: <p>{selectedUser?.gymPhotos[selectedUser?.gymPhotos?.length - 1].filePath}</p>
                        </span>
                          <DownloadForOfflineIcon
                            onClick={() => handleDownload(selectedUser?.gymPhotos[selectedUser?.gymPhotos?.length - 1].filePath)}
                          />
                          <DeleteForeverIcon
                            onClick={() => handleDelete(selectedUser?.gymPhotos[selectedUser?.gymPhotos.length - 1].filePath, selectedUser?.gymPhotos[selectedUser?.gymPhotos.length - 1])}
                          />
                      </div>
                     )}
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.9 Please attach the licence of gym.
                  </FormLabel>
                  <div style={{ alignItems: "center", gap: "10px" }}>
                    <label htmlFor="programList" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline" }}>
                      Attach Licence
                    </label>
                    <input
                      type="file"
                      accept=".pdf, .doc"
                      id="programList"
                      className="file-upload-input"
                      name="programList"
                      style={{ display: "none" }}
                      value={selectedUser?.programList}
                      disabled
                    />
                   {selectedUser?.gymInvoiceFile?.length > 0 && (
                    <div style={{ display: "ruby" }}>
                      <span>
                        Licence Name: <p>{selectedUser?.gymInvoiceFile[selectedUser?.gymInvoiceFile?.length - 1].filePath}</p>
                      </span>
                    <div>
                    <DownloadForOfflineIcon
                      onClick={() => handleDownload(selectedUser?.gymInvoiceFile[selectedUser?.gymInvoiceFile?.length - 1].filePath)}
                    />
                    <DeleteForeverIcon
                      onClick={() => handleDelete(selectedUser?.gymInvoiceFile[selectedUser?.gymInvoiceFile?.length - 1].filePath, gymInvoiceFile[selectedUser?.gymInvoiceFile?.length - 1])}
                    />
                   </div>
                  </div>
                )}
                </div>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                  >
                    1.10 What is the general time of the gym ?
                  </FormLabel>
                  <div>
                    <FormLabel style={{ marginBottom: "0px" }}>From</FormLabel>
                    <input type="time" className="form-control" value={selectedUser?.gymTime} disabled/>
                  </div>

                  {/* To Time Field */}
                  <div className="mt-3">
                    <FormLabel style={{ marginBottom: "0px" }}>To</FormLabel>
                    <input type="time" className="form-control" value={selectedUser?.gymTimeTo} disabled/>
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>
                    1.11 How many staff members are present at any time in the gym ?
                  </FormLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={selectedUser?.staffMemberPresent}
                    disabled
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    1.12 Are female staff members always present to assist female members in case of any need?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.femaleStaffMemberPresent} name="radio-buttons-group" disabled>
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>
                    1.13 How does the outfit ensure safety and security of all its members especially female members? Please elaborate on safety and security measures.
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={selectedUser?.femaleOutfitSafety}
                    disabled
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel>
                    1.14 What is the typical age group of all the present members ?
                  </FormLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedUser?.memberAgeGroup}
                    disabled
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>
                    1.15 What is the present composition in terms of gender of all present members? (% female vs male members).
                  </FormLabel>
                  <div>
                    <select
                      value={selectedUser?.compositionInGender}
                      style={{ borderRadius: "5px" }}
                      disabled
                    >
                      <option value="">Female/Male</option>
                      <option value="10/90">10/90</option>
                      <option value="20/80">20/80</option>
                      <option value="30/70">30/70</option>
                      <option value="40/60">40/60</option>
                      <option value="50/50">50/50</option>
                      <option value="60/40">60/40</option>
                      <option value="70/30">70/30</option>
                    </select>
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel>
                    1.15 Some gyms attract hardcore bodybuilders, some gyms attract members that may not be heavily invested in real change, and some gyms attract “true” fitness communities. What kind
                    of members mostly your gym attracts or has?
                  </FormLabel>
                  <div>
                    <select
                      value={selectedUser?.memberGymAttract}
                      style={{ borderRadius: "5px" }}
                      disabled
                    >
                      <option value="">Select</option>
                      <option value="Hardcore bodybuilders">Hardcore bodybuilders</option>
                      <option value="Not heavily invested in read change">Not heavily invested in read change</option>
                      <option value="True fitness communities">True fitness communities</option>
                      <option value="Mixture of all three ">Mixture of all three </option>
                    </select>
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel>
                    1.16 List all the pin codes from where your members come from.
                  </FormLabel>
                  {selectedUser?.gymAreaPincode?.map((row, index) => (
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} key={index}>
                      <input id={`areaPincode${index}`} type="text" className="form-control" value={row} style={{ marginRight: "10px" }} disabled/>
                      
                    </div>
                  ))}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={onBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep1}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 1 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. About Equipment </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel>
                    2.1 What kind of equipment are available in the gym? List typical equipment available for fitness and strength training. How old are the equipment?
                  </FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <hr></hr>
            <Grid item xs={12} md={12}
            style={{ height: '400px', overflowY: 'auto'}}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Equipment Name</TableCell>
                      <TableCell>Equipment Type</TableCell>
                      <TableCell>How Old Equipment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedUser?.kindOfEquipment?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input type="text" value={row.equipment} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.type} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.equipmentOld} className="form-control" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container spacing={0} mb={0} mt={5} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStepKindOfEquipment}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 2 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. About Equipment </h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    2.2 Are there easy to understand instruction/pictures available or posted on each machine explaining its usage and operation?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={selectedUser?.machineInstruction}
                    style={{ width: "85%" }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    2.3 Is there is a sign-up sheet or a time limit on cardio equipment?
                  </FormLabel>
                  <FormGroup>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.timeLimitOnCardio} name="radio-buttons-group">
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="No" />
                    </RadioGroup>
                    {selectedUser?.timeLimitOnCardio && (
                      <>
                        <FormLabel>
                          Please explain.
                        </FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedUser?.explainCardioTimeLimit}
                        ></input>
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <label>
                  2.4 Please describe all other facilities available to members such as changing rooms, personal lockers (if any), Sauna, etc.
                </label>
                <textarea
                  type="text"
                  value={selectedUser?.otherFacility}
                  className="form-control"
                ></textarea>
              </Grid>
            </Grid>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep2}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 3 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>3. Membership, Training and Testing </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    3.1 Is there any introductory clinic to help members get started?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.introductoryClinic} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    3.2 Is separate training provided on complex equipment for proper usage?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.separateTraining} name="radio-buttons-group" >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    3.3 Is an initial fitness orientation, screening or body assessment provided to each member right after joining?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.initialFitnessOrientation} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    3.4 Are there areas dedicated to stretching or turf areas for training?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.areaDedicated} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    3.5 Does the gym offer body fat testing, cardiovascular testing, and strength and flexibility testing
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.gymOfferTesting} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    3.6 Is there any free trial membership available to check out the gym ?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.freeTrailMembership} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {selectedUser?.freeTrailMembership == true && (
                    <>
                      <FormLabel>
                        How many days or hours? What kind of membership plans are available? What is included, please briefly mention.{" "}
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={selectedUser?.freeTrailMembershipExplain}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    3.7 Can I get a Tour of the gym before signing up for the membership?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.getTour} name="radio-buttons-group" >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {(selectedUser?.getTour == true || selectedUser?.getTour == "true") && (
                    <>
                      <FormLabel>
                        When can I come for a quick tour?
                      </FormLabel>
                      <div>
                        <FormLabel style={{ marginBottom: "0px" }}>From</FormLabel>
                        <input type="time" className="form-control" value={selectedUser?.getTourTime} />
                      </div>

                      {/* To Time Field */}
                      <div className="mt-3">
                        <FormLabel style={{ marginBottom: "0px" }}>To</FormLabel>
                        <input type="time" className="form-control" value={selectedUser?.getTourTimeTo}/>
                      </div>

                      {/* Textbox Below */}
                      <div className="mt-3">
                        <FormLabel style={{ marginBottom: "0px" }}>Additional Notes</FormLabel>
                        <textarea className="form-control" value={selectedUser?.tourTimeNote} />
                      </div>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    3.8 Can I cancel and get refund of membership fees, if for some reason, after few days of training, I do not want to continue with my membership anymore?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.refundMembership} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  <textarea
                    type="text"
                    className="form-control"
                    value={selectedUser?.refundMembershipDetail}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    3.9 What kind of membership plans the Gym has? (Select all that applies)
                  </FormLabel>
                  <div>
                    <Checkbox value="1 month" checked={selectedUser?.selectedPlans?.includes("1 month")} />1 month
                  </div>
                  <div>
                    <Checkbox value="2 months" checked={selectedUser?.selectedPlans?.includes("2 months")}/>2 months
                  </div>
                  <div>
                    <Checkbox value="3 months" checked={selectedUser?.selectedPlans?.includes("3 months")} />3 months
                  </div>
                  <div>
                    <Checkbox value="6 months" checked={selectedUser?.selectedPlans?.includes("6 months")}/>6 months
                  </div>
                  <div>
                    <Checkbox value="12 months" checked={selectedUser?.selectedPlans?.includes("12 months")} />
                    12 months
                  </div>

                  {/* "Other" Plan */}
                  <div>
                    <Checkbox
                      value="Other"
                      checked={selectedUser?.selectedPlans?.includes("Other")}
                    />
                    Other
                    {selectedUser?.selectedPlans?.includes("Other") && <TextField placeholder="other plan" value={selectedUser?.otherPlan} fullWidth />}
                  </div>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep3}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 4 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>4. About Trainers </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    4.1 Are the Trainers Qualified?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.trainersQualified} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    4.2 Are there Male trainers?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.areMaleTrainers} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {selectedUser?.areMaleTrainers == true && (
              <>
                <Grid container spacing={2} mb={5}>
                  <Grid item md={6}>
                    <FormLabel>
                      4.2.1 What are the qualification and experience level of all Male trainers in the gym?
                    </FormLabel>
                    <textarea
                      type="text"
                      className="form-control"
                      value={selectedUser?.maleTrainerExpirence}
                    ></textarea>
                  </Grid>
                  <Grid item md={6}>
                    <FormLabel>4.2.2 What days and time Male trainers are available?</FormLabel>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label>Weekdays (Monday to Friday):</label>
                      <div>
                        From : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.maleWeekdaysFrom} />
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.maleWeekdaysTo} />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label >Saturday:</label>
                      <div>
                        From :&nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.maleWeekendsFrom} /> &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.maleWeekendsTo} />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label>Sunday:</label>
                      <div>
                        From :&nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.maleWeekendsSundayFrom} /> &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.maleWeekendsSundayTo} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    4.3 Are Female trainers available?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.areFemaleTrainer} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {selectedUser?.areFemaleTrainer == true && (
              <>
                <Grid container spacing={2} mb={5}>
                  <Grid item md={6}>
                    <FormLabel>
                      4.3.1 What are the qualification and experience level of all Female trainers in the gym?
                    </FormLabel>
                    <textarea
                      type="text"
                      className="form-control"
                      value={selectedUser?.femaleTrainerExperience}
                    ></textarea>
                  </Grid>
                  <Grid item md={6}>
                    <FormLabel>4.3.2 What days and time Female trainers are available?</FormLabel>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label>Weekdays (Monday to Friday):</label>
                      <div>
                        From : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.femaleWeekdaysFrom} />
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.femaleWeekdaysTo} />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label>Saturday:</label>
                      <div>
                        From :&nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.femaleWeekendsFrom} /> &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.femaleWeekendsTo} />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label>Sunday</label>
                      <div>
                        From :&nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.femaleWeekendsSundayFrom}/> &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input type="time" style={{ width: "30%" }} value={selectedUser?.femaleWeekendsSundayTo}/>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    4.4 Is there any Manager/Lead Trainer who manages the entire operations of the gym?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.anyManager} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl>
                  {selectedUser?.anyManager == true && (
                    <>
                      <FormLabel>
                        4.4.1 Name of the Manager/Lead/Trainer of the gym
                      </FormLabel>
                      <input
                        type="text"
                        className="form-control"
                        value={selectedUser?.managerName}
                      ></input>
                      <FormLabel>
                        4.4.2 Enter Qualification
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={selectedUser?.managerQualification}
                      ></textarea>
                      <FormLabel>
                        4.4.3 Enter Expirence
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={selectedUser?.managerExpirence}
                      ></textarea>
                      <FormLabel>
                        4.4.4 Won any award or other recognitions
                      </FormLabel>
                      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.managerAward} name="radio-buttons-group">
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                    </>
                  )}
                  {selectedUser?.managerAward == true && (
                    <>
                      <FormLabel>
                        4.4.4.1 Mention award/recognitions
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={selectedUser?.explainManagerAward}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep4}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 5 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>5. Cleanliness Standards </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    5.1 Does the gym maintain good sanitation and cleanliness standard?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.gymMaintainCleanliness} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {selectedUser?.gymMaintainCleanliness == true && (
                    <>
                      <FormLabel>
                        How is the cleaning schedule like?
                      </FormLabel>
                      <input
                        type="text"
                        style={{ width: "50%" }}
                        className="form-control"
                        value={selectedUser?.explainCleanliness}
                      ></input>
                    </>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    5.2 Are sanitation and cleaning stations placed around the GYM and well-stocked.
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.cleaningStationPlaced} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    5.3 How do staff members enforce standard of hygiene?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={selectedUser?.hygieneStandard}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    5.4 Are locker rooms, showers, toilets, sinks, kept clean and properly maintained?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.properlyMaintained} name="radio-buttons-group" >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                  {selectedUser?.properlyMaintained == true && (
                    <>
                      <FormLabel>
                        How often they are cleaned throughout the day?
                      </FormLabel>
                      <textarea
                        type="text"
                        style={{ width: "50%" }}
                        className="form-control"
                        value={selectedUser?.explainProperlyMaintained}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep5}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 6 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>6. AMENITIES </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label">
                    6.1 Does the Gym offer any other amenities?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.gymOfferAmenities} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {selectedUser?.gymOfferAmenities == true && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label"> 6.2 Does it offer ?</FormLabel>
                      <Row>
                        <Col lg={10}></Col>
                        <Col lg={2}>
                          <div
                            style={{
                              display: "flex",
                              gap: "65px",
                              marginTop: "-35px",
                            }}
                          >
                            <div style={{ fontWeight: "bold" }}>Yes</div>
                            <div style={{ fontWeight: "bold" }}>No</div>
                          </div>
                        </Col>
                      </Row>
                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          {/* Question 1 */}
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                1. A kid's area
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="multitask"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.kidsArea}
                              >
                                <FormControlLabel value={"true"} control={<Radio />} />
                                <FormControlLabel value={"false"} control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                2. Ladies-only workout space
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="exhausted"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.onlyLadiesWorkoutSpace}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                3. A sauna
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="schedule"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.sauna}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                4. A functional training area
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="focus"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.functionalTrainingArea}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                5. Personal trainers
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="timeOff"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.personalTrainer}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                6. WiFi
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="imbalance"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.wifi}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                7. A pro shop
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="guilty"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={selectedUser?.proShop}
                              >
                                <FormControlLabel value="true" control={<Radio />} />
                                <FormControlLabel value="false" control={<Radio />} />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                8. Other amenities
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <input
                                type="text"
                                style={{ width: "8rem", borderBottom: "1px solid #000" }}
                                className="form-control"
                                value={selectedUser?.otherAmenities}
                              ></input>
                            </Col>
                          </Row>
                        </FormControl>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep6}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 7 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>7. CLASSES AND PROGRAMS </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    7.1 What various classes and training programs offered at the gym?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={selectedUser?.variousTrainingProgram}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    7.2 Are group fitness classes offered at the gym?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.groupFitnessClasses} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    7.3 Does the gym charges extra for Group, studio classes, or training programs?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.extraCharge} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleEnvironmentStep7}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 8 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>7. CLASSES AND PROGRAMS </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    7.4 Can I get a list of various classes and programs and their duration, time, charges, trainer name, etc.?
                  </FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <hr></hr>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Classes Name</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Charges</TableCell>
                      <TableCell>Trainer Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedUser?.classesAndProgram?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input type="text" value={row.className} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.duration} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.classTime} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.charges} className="form-control" />
                        </TableCell>
                        <TableCell>
                          <input type="text" value={row.trainerName} className="form-control" />
                        </TableCell>
                      
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container spacing={0} mb={0} mt={5} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleAttachProgramFile}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 9 && (
          <>
            <h5>Enter Details about Classes (Upload class description or fill the details in the text box)</h5>
            <table class="table  table-bordered table-striped" style={{ marginTop: "10px" }}>
              <thead class="thead-dark">
                <tr>
                  <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
                    Classes Name
                  </th>
                  <th scope="col" style={{ backgroundColor: "#5353E9", color: "white", textAlign: "center" }}>
                    Class Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedUser?.classesAndProgram?.length > 0 ? (
                  selectedUser?.classesAndProgram?.map((test, index) => (
                    <>
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{test.className}</td>
                        <td>
                          <textarea type="text" className="form-control" value={test.description} ></textarea>
                          <br></br>
                          <label htmlFor="licenses" style={{ cursor: "pointer", color: "#5353e9", textDecoration: "underline", fontWeight: "bold" }}>
                            Attach Class description
                          </label>
                          {test.descriptionFile && (
                            <div style={{ marginTop: "10px" }}>
                              <span>
                                Description File: <b>{test.descriptionFile}</b>
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Grid container spacing={0} mb={0} mt={5} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={handleSaveClassDescription}>
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 10 && (
          <>
            <Grid container spacing={2} mb={12}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    <h5>Enter Details about Trainer</h5>
                  </FormLabel>

                  {selectedUser?.classesAndProgram?.length > 0 ? (
                    // Group by trainerName (unique trainers)
                    [...new Map(selectedUser?.classesAndProgram.map((item) => [item.trainerName, item])).values()].map((groupedItem, index) => {
                      return (
                        <>
                          <div key={index}>
                            {/* Render table for each trainer */}
                            <table
                              className="table table-bordered"
                              style={{
                                marginTop: "2%",
                              }}
                            >
                              <thead>
                                <tr className="table-active">
                                  <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                    Trainer Name
                                  </th>
                                  <th scope="col" colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }}>
                                    Qualifications and Experience Detail
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={groupedItem.id}>
                                  {/* Trainer Name */}
                                  <td style={{ textAlign: "center" }}>
                                    <input type="text" className="form-control" value={groupedItem.trainerName} disabled />
                                  </td>
                                  <td colSpan={2}>
                                    {groupedItem.gymTrainerDetail && groupedItem.gymTrainerDetail.length > 0 ? (
                                      // If gymTrainerDetail is not null and has data, filter the trainer detail based on trainerName
                                      groupedItem.gymTrainerDetail
                                        .filter((trainerDetail) => trainerDetail.trainerName === groupedItem.trainerName)
                                        .map((trainerDetail) => (
                                          <div key={trainerDetail.id}>
                                            {/* Trainer Qualification */}
                                            <textarea
                                              className="form-control"
                                              value={trainerDetail?.qualificationAndExperience}
                                              disabled={true}
                                              placeholder="Trainer Qualification"
                                              style={{ width: "100%" }}
                                            />

                                            {/* Gender, Google Rating, and Testimonial */}
                                            <table className="table">
                                              <thead>
                                                <tr className="table-active">
                                                  <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    Gender Trained
                                                  </th>
                                                  <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    Google Rating
                                                  </th>
                                                  <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    Client Testimonials from Google/Other
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  {/* Gender Trained */}
                                                  <td>
                                                    <select
                                                      value={trainerDetail?.genderTrained}
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      disabled={true}
                                                    >
                                                      <option value="">Select</option>
                                                      <option value="male">Male</option>
                                                      <option value="female">Female</option>
                                                      <option value="both">Both</option>
                                                    </select>
                                                  </td>

                                                  {/* Google Rating */}
                                                  <td>
                                                    <textarea
                                                      className="form-control"
                                                      value={trainerDetail?.googleRating}
                                                      disabled={true}
                                                      placeholder="Enter Google Rating"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>

                                                  {/* Client Testimonial */}
                                                  <td>
                                                    <textarea
                                                      className="form-control"
                                                      value={trainerDetail?.clientTestimonial}
                                                      disabled={true}
                                                      placeholder="Enter Client Testimonial"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            {/* Awards and Age Group */}
                                            <table className="table">
                                              <thead>
                                                <tr className="table-active">
                                                  <th scope="col" colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    Any Awards
                                                  </th>
                                                  <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    Age Group Trained
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td colSpan={2}>
                                                    <textarea
                                                      className="form-control"
                                                      value={trainerDetail?.anyAwards}
                                                      disabled={true}
                                                      placeholder="Enter any awards"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>

                                                  <td>
                                                    <textarea
                                                      className="form-control"
                                                      value={trainerDetail?.ageGroupTrained}
                                                      disabled={true}
                                                      placeholder="Enter age group trained"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        ))
                                    ) : (
                                      <div>
                                        <textarea
                                          className="form-control"
                                          value={selectedUser?.classesAndProgram?.gymTrainerDetail?.trainerQualification}
                                          placeholder="Enter qualifications and experience"
                                          style={{ width: "100%" }}
                                        />

                                        {/* Gender, Google Rating, and Testimonial */}
                                        <table className="table">
                                          <thead>
                                            <tr className="table-active">
                                              <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                Gender trained
                                              </th>
                                              <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                Google Rating
                                              </th>
                                              <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                Client testimonials from Google/ other
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <select value={selectedUser?.classesAndProgram?.gymTrainerDetail?.genderTrained}  className="form-control" style={{ width: "100%" }}>
                                                  <option value="">Select</option>
                                                  <option value="male">Male</option>
                                                  <option value="female">Female</option>
                                                  <option value="both">Both</option>
                                                </select>
                                              </td>
                                              <td>
                                                <textarea
                                                  className="form-control"
                                                  value={selectedUser?.classesAndProgram?.gymTrainerDetail?.trainerGoogleRating}
                                                  placeholder="Enter Google Rating"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                              <td>
                                                <textarea
                                                  className="form-control"
                                                  value={selectedUser?.classesAndProgram?.gymTrainerDetail?.trainerTestimonial}
                                                  placeholder="Enter Client Testimonial"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        {/* Awards and Age Group */}
                                        <table className="table">
                                          <thead>
                                            <tr className="table-active">
                                              <th scope="col" colSpan={2} style={{ textAlign: "center", fontWeight: "bold" }}>
                                                Any awards
                                              </th>
                                              <th scope="col" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                Age group trained
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td colSpan={2}>
                                                <textarea
                                                  className="form-control"
                                                  value={selectedUser?.classesAndProgram?.gymTrainerDetail?.trainerAward}
                                                  placeholder="Enter any awards"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                              <td>
                                                <textarea
                                                  className="form-control"
                                                  value={selectedUser?.classesAndProgram?.gymTrainerDetail?.ageGroupTrained}
                                                  placeholder="Enter age group trained"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p>No trainers available</p>
                  )}
                  <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
                    <div className="submit-button">
                      <button className="base" onClick={handleEnvironmentStepBack}>
                        Back
                      </button>
                    </div>
                    <div className="submit-button">
                      <button
                        type="button"
                        className="base"
                        onClick={() => handleTrainersScreen()} // Pass the correct ID and trainer name
                      >
                        Next
                      </button>
                    </div>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={12}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  ></FormLabel>
                </FormControl>
              </Grid>
            </Grid>

            <br></br>
          </>
        )}
        {environmentStep == 11 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>8. COST AND FEES </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    8.1 Is the gym running any promotional plan currently?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.promotionalCurrentPlan} name="radio-buttons-group" >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                  >
                    8.2 Is there a payment plan available to pay the membership fees?
                  </FormLabel>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={selectedUser?.paymentPlan} name="radio-buttons-group">
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <h6>* START TODAY ON YOUR “FITNESS AND LIFESTYLE DISEASE PREVENTION” PLAN WITH YOUR 7 DAY FREE PASS FROM LIVING SCOPE HEALTH. </h6>
            <br></br>
            <Grid container spacing={0} mb={0} mt={3} justifyContent="space-between" display={"flex"} gap={5}>
              <div className="submit-button">
                <button className="base" onClick={FinalPageBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={GymFromSubmit}>
                  Submit
                </button>
              </div>
            </Grid>
          </>
        )}
      </form>
    </div>
  );
};

export default GymApproval;
