import "../LifeStyleRiskAssessment.css";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { useNavigate } from "react-router-dom";
import NutritionistCard from "./NutritionistCard";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Button, Grid, Typography } from "@mui/material";
import AppointmentScheduler from "./AppointmentScheduler";
import BookGymModel from "../../GymScreen/BookGymModel";
import { bookPreventiveCareTest } from "../../../constants/apiList";

const PreventiveCareServices = ({
  PreventiveCareRecommendationNext,
  FullRecommendationNext,
}) => {
  const [preventiveCare, setPreventiveCare] = useState([]);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const [appointmentmodal, setappointmentmodal] = useState(false);
  const toggle = () => setModal(!modal);
  const appointmentmodaltoggle = () => setappointmentmodal(!appointmentmodal);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [nutritionistData, setnutritionistData] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [scheduleId, setscheduleId] = useState(null);
  const [NutritionistLrid, setNutritionistLrid] = useState('');
  const [isGymModalOpen, setGymIsModalOpen] = useState(false);
  const navigate = useNavigate();


  const handleNutritionistSelect = (doctor, type) => {
    setSelectedDoctor(doctor); // Set the selected doctor data
    setType(type);
    setappointmentmodal(true);
    setModal(!modal);
  };
  const onAppointmentSelect = (sessionId) => {
    setappointmentmodal(!appointmentmodal);
    setOpen(true);
    setscheduleId(sessionId.sessionId);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const surcharge = 0;
  const taxRate = 0.18;
  const tax =
    selectedDoctor != null &&
    parseInt(
      type === "Audio"
        ? selectedDoctor.audioCharge * taxRate
        : selectedDoctor.videoCharge * taxRate
    );
  const total =
    selectedDoctor != null &&
    parseInt(
      type === "Audio"
        ? selectedDoctor.audioCharge + surcharge + tax
        : selectedDoctor.videoCharge + surcharge + tax
    );

  useEffect(() => {
    AxiosInstance.get("riskAssessment/getLifestylePreventiveCareServices")
      .then((response) => {
        if (response != null) {
          setPreventiveCare(response);
        } else {
          setPreventiveCare([]);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  const getPaymentSuccessBaseUrl = () => {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
    return encodeURI(newPath); // Encode the URL
  };

  const handlePayment = () => {
    const formData = {
      amount: parseInt(total),
      dietitianId: selectedDoctor.userId,
      scheduleId: scheduleId,
      typeOfCall: type,
      lrId: NutritionistLrid,
    };

    AxiosInstance.post(
      `/payment/dietitian-create-order?successURL=${encodeURIComponent(
        getPaymentSuccessBaseUrl()
      )}`,
      formData
    )
      .then((response) => {
        window.location.href = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setOpen(!open);
  };

  const handlePreventiveTest = (lrid) => {
    const LRID = lrid;
    // AxiosInstance.post(
    //   `/test-labs/create-preventive-cart/Lipid%20Profile/${LRID}`
    // )   
    const testName = "Lipid Profile"
     AxiosInstance.post(`${bookPreventiveCareTest}/${testName}/${LRID}`)
      .then((response) => {
        setPreventiveCare(response);
        navigate("/lab-services?page=testcart");
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    AxiosInstance.get("dietitian/getAllDietitianDetail")
      .then((response) => {
        if (response) {
          setnutritionistData(response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const bookNutritionistDetail = (lrid) => {
    setNutritionistLrid(lrid);
    setModal(!modal);
  };

  const bookGym = () => {
    setGymIsModalOpen(true);
  }
   const closeModal = () => {
     setGymIsModalOpen(false); // Set to false to hide the modal
   };

  // const nutritionistData = [
  //   {
  //     userId: 1179,
  //     firstName: "Deepak",
  //     lastName: "Lohar",
  //     practicingAs: "bgb",
  //     educationQualifications: "MBBS",
  //     everWorkedAsClinicalDietitian: "Yes",
  //     bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     yearsOfExperience: "4",
  //     amount: 255,
  //     tax: 18
  // },

  // {
  //     userId: 1187,
  //     firstName: "Mayank",
  //     lastName: "xyz",
  //     practicingAs: "Consultant Physician ",
  //     educationQualifications: "MD",
  //     everWorkedAsClinicalDietitian: "No",
  //     bio: "Passed MBBS (Gold Medalist) in 2017, Passed MD medicine in 2022, Working as consultant physician since last 2 years in Manjalpur Hospital and Spandan multispeciality Hospital",
  //     yearsOfExperience: "6",
  //     amount: 325,
  //     tax: 18
  // },
  // ]


  return (
    <>
      {preventiveCare.length > 0 && (
        <div className="">
          {preventiveCare?.map((item, index) => (
            <>
              <table class="table table-bordered" style={{ border: "2px solid black" }}>
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> {item.medicalCondition}
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> {item.dateRecommended}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> {item.preventiveTest}
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> {item.lrid}
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}> {item.importance}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.whyYou}</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>{item.recommendation}</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                      {item.bookForTest && (
                        <tr class="table-active">
                          <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                            <p style={{ fontSize: "20px" }}>
                              <b>Book for Test - "{item.bookForTest}"</b>
                            </p>
                          </th>
                        </tr>
                      )}
                      {item.bookForTest && (
                        <tr>
                          <th scope="row" colSpan={2}>
                            {item.lrid === "CVD-04-02" || item.lrid === "Diabetes-03" ? (
                              <center>
                                <button type="button" className="btn btn-success" onClick={() => bookNutritionistDetail(item.lrid)}>
                                  Book Nutritionist
                                </button>{" "}
                                &nbsp;
                                <button type="button" className="btn btn-success" onClick={bookGym}>
                                  Book Gym
                                </button>
                                {isGymModalOpen && (
                                  <BookGymModel
                                    open={isGymModalOpen}
                                    onClose={closeModal}
                                  />
                                )}
                              </center>
                            ) : (
                                <center>
                                  <button type="button" className="btn btn-success" onClick={() => handlePreventiveTest(item.lrid)}>
                                    Book
                                  </button>
                                </center>
                            )}
                          </th>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <br></br>
            </>
          ))}
          {/* <table class="table table-bordered" style={{ border: "2px solid black" }}>
                <thead class="table-dark">
                  <tr>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Medical Condition :</b> Dengue
                      </p>
                    </th>
                    <th scope="col">
                      <p style={{ fontSize: "18px" }}>
                        <b>Date Recommended :</b> 8-10-2024
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <p style={{ fontSize: "18px" }}>
                        <b>Preventive Test :</b> Dengue test
                      </p>
                    </th>
                    <td>
                      <p style={{ fontSize: "18px" }}>
                        <b>LRID :</b> DR01
                      </p>
                    </td>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Importance</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>very important</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Why You?</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>cwgrt</p>
                    </th>
                  </tr>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Recommendation</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      <p style={{ fontSize: "18px" }}>xdewrgt</p>
                    </th>
                  </tr>
                  {familyInfo?.age >= 3 && (
                    <>
                  <tr class="table-active">
                    <th scope="row" colSpan={2} style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "20px" }}>
                        <b>Book for Test - "Nurtitionist"</b>
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                        <center>
                          <button type="button" className="btn btn-success" onClick={() => bookNutritionistDetail()}>
                            Book Nutritionist
                          </button>
                        </center>
                    </th>
                  </tr>
                    </>
                  )}
                </tbody>
              </table> */}

          <div>
            <Modal
              isOpen={modal}
              toggle={toggle}
              centered
              style={{
                maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
                minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
                width: "auto", // Automatically adjusts the width based on content
              }}
            >
              <ModalHeader
                style={{
                  backgroundColor: "black",
                  color: "white",
                  border: "1px solid lightgrey",
                }}
                toggle={toggle}
              >
                <center>Select Nurtitionist/Dietitian to Book</center>
              </ModalHeader>
              <ModalBody
                style={{
                  padding: "20px",
                  maxHeight: "80vh", // Adjust the height as needed
                  overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
                }}
              >
                <NutritionistCard nutritionistData={nutritionistData} onDoctorSelect={handleNutritionistSelect}></NutritionistCard>
                {/* <OrderDetailModel /> */}
              </ModalBody>
            </Modal>
          </div>
          <Modal
            isOpen={appointmentmodal}
            toggle={appointmentmodaltoggle}
            centered
            style={{
              maxWidth: "90%", // Allows the modal to expand up to 90% of the screen width
              minWidth: "60%", // Ensures a minimum width, so it doesn't get too narrow
              width: "auto", // Automatically adjusts the width based on content
            }}
          >
            <ModalHeader
              style={{
                backgroundColor: "black",
                color: "white",
                border: "1px solid lightgrey",
              }}
              toggle={appointmentmodaltoggle}
            >
              <center>Select Date/Time to Book Appointment</center>
            </ModalHeader>
            <ModalBody
              style={{
                padding: "20px",
                maxHeight: "80vh", // Adjust the height as needed
                overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
              }}
            >
              <AppointmentScheduler onAppointmentSelect={onAppointmentSelect} type={type} selectedDietician={selectedDoctor}></AppointmentScheduler>
            </ModalBody>
          </Modal>
          <Modal isOpen={open} toggle={handleClose} centered modalClassName="custom-modal">
            <ModalHeader
              style={{
                backgroundColor: "black",
                color: "white",
                border: "1px solid lightgrey",
              }}
              toggle={handleClose}
            >
              <Typography variant="h6" component="h2">
                Complete your Payment
              </Typography>
            </ModalHeader>
            <ModalBody className="modal-body-centered">
              <Typography variant="h5" sx={{ color: "purple", fontWeight: "600", fontSize: "1.75rem" }} component="h5">
                Uncurl: Health Services
              </Typography>
              <Typography
                variant="h6"
                // sx={{ color: "purple" }}
                component="h6"
              >
                Clinical Dietitian Consulting
              </Typography>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="h6">Service Type:</Typography>
                  <Typography variant="h6">Order Value:</Typography>
                  <Typography variant="body1">Surcharge:</Typography>
                  <Typography variant="body1">Tax (18%):</Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <Typography variant="h6">{type && type}</Typography>
                  <Typography variant="h6">₹{selectedDoctor != null && type === "Audio" ? selectedDoctor.audioCharge : type === "Video" ? selectedDoctor.videoCharge : ""}</Typography>
                  <Typography variant="body1">₹{surcharge}</Typography>
                  <Typography variant="body1">₹{tax && tax.toFixed(2)}</Typography>
                </Grid>
              </Grid>

              {/* Line above the Total */}
              <Box
                sx={{
                  borderTop: "2px solid lightgrey",
                  mt: 2,
                  mb: 2,
                  width: "100%",
                }}
              />

              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Total:
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    ₹{total && total.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>

              {/* Line below the Total */}
              <Box
                sx={{
                  borderTop: "2px solid lightgrey",
                  mt: 2,
                  mb: 2,
                  width: "100%",
                }}
              />
              <Typography variant="body1" sx={{ mb: 3 }}>
                This amount will be charged to your account.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#5353e9",
                  "&:hover": { backgroundColor: "#c60176" },
                }}
                onClick={handlePayment} // Add your payment handling function here
              >
                Confirm & Pay
              </Button>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PreventiveCareServices;
