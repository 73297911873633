import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import {
  getLabOrderDetailApi,
  getLabOrderDetailByIdApi,
} from "../../constants/apiList";
import OrderDetailModel from "./OrderDetailModel";
import AppLogo from "../../../static/img/AppLogoWithName.png";
import NoOrderImage from "./Images/no-order.jpeg";
import { toast } from "react-toastify";
import CorporateOrderDetailModel from "./CorporateOrderDetailModel";
import EditIcon from "@mui/icons-material/Edit";
import "./style.css";
import Spinner from "../../shared/Spinner";

const MyOrders = () => {
  const [rowData, setRowData] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [modal, setModal] = useState(false);
  const [corporatemodal, setcorporateModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const toggle = () => setModal(!modal);
  const Corporatetoggle = () => setcorporateModal(!corporatemodal);
  const [corporateOrder, setcorporateOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [fileMap, setFileMap] = useState({});
  const [fileCount, setFileCount] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState({});
  const [selectedIndividualFile, setselectedIndividualFile] = useState({});
  const [loadingState, setLoadingState] = React.useState({});
  const [openModal, setOpenModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEditClick = (order, field) => {
    setCurrentOrder(order);
    setCurrentField(field);
    setOpenModal(true);
  };

  const viewOrderDetail = (id) => {
    setOrderId(id);
    setModal(!modal);
  };
  const viewCorporateOrderDetail = (id) => {
    const order = corporateOrder.find((order) => order.purchaseOrderId === id);
    setSelectedOrder(order);
    setOrderId(id);
    setcorporateModal(true);
  };
  const handleSelectChange = (orderId, typeOfClient, event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Issues") {
      Swal.fire({
        html: `
        <div>
          <p>Mention what issue you are facing to execute this home collection service.</p>
          <input type="text" id="swal-input1" class="swal2-input">
          <p><b>Note: </b>Please call our Account Manager for resolving if needed.</p>
        </div>
      `,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const issueDescription = document.getElementById("swal-input1").value;
          if (!issueDescription) {
            Swal.showValidationMessage("Please enter a description");
          }
          return { issueDescription };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const issueDescription = result.value.issueDescription;
          const formData = {
            orderId: orderId,
            statusType: selectedValue,
            orderType: typeOfClient,
          };
          const url = `lab-partner/updateOrderStatus/${orderId}/${selectedValue}/${typeOfClient}`;
          AxiosInstance.post(url, formData)
            .then((response) => {
              if (response != null) {
                console.log("order status ", response);
                toast.success("Order Status Updated Successfully");
                toast.success(response.message);
                if (typeOfClient === "Corporate") {
                  setcorporateOrder(
                    corporateOrder.map((order) =>
                      order.purchaseOrderId === orderId
                        ? { ...order, status: selectedValue }
                        : order
                    )
                  );
                } else {
                  setRowData(
                    rowData.map((order) =>
                      order.orderId === orderId
                        ? { ...order, orderStatus: selectedValue }
                        : order
                    )
                  );
                }
              } else {
              }
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        }
      });
    } else {
      const formData = {
        orderId: orderId,
        statusType: selectedValue,
        orderType: typeOfClient,
      };
      const url = `lab-partner/updateOrderStatus/${orderId}/${selectedValue}/${typeOfClient}`;
      AxiosInstance.post(url, formData)
        .then((response) => {
          if (response != null) {
            console.log("order status ", response);
            toast.success("Order Status Updated Successfully");
            toast.success(response.message);
            if (typeOfClient === "Corporate") {
              setcorporateOrder(
                corporateOrder.map((order) =>
                  order.purchaseOrderId === orderId
                    ? { ...order, status: selectedValue }
                    : order
                )
              );
            } else {
              setRowData(
                rowData.map((order) =>
                  order.orderId === orderId
                    ? { ...order, orderStatus: selectedValue }
                    : order
                )
              );
            }
          } else {
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  const getLabAndCorporateOrders = async () => {
    setLoading(true);
    try {
      const [labOrderResponse, corporateOrderResponse] = await Promise.all([
        AxiosInstance.get(`${getLabOrderDetailApi}`),
        AxiosInstance.get("lab-partner/getCorporateOrderList"),
      ]);

      const labOrderData = labOrderResponse || [];
      const corporateOrderData = corporateOrderResponse || [];

      // Combine both datasets
      const combinedData = [...labOrderData, ...corporateOrderData];

      // Set state for individual data
      setRowData(labOrderData);
      setcorporateOrder(corporateOrderData);

      // Find the nearest date from the combined data
      const nearestDate = findNearestDate(combinedData, new Date());
      setSelectedDate(nearestDate);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data: ", err);
      setRowData([]);
      setcorporateOrder([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLabAndCorporateOrders();
  }, []);

  // Utility function to find the nearest date
  const findNearestDate = (data, targetDate) => {
    if (!data.length) return targetDate.toISOString().split("T")[0]; // Default to today's date if no data

    return data.reduce((nearest, item) => {
      const itemDate = new Date(item.date); // Ensure the date field matches your API's structure
      const nearestDate = new Date(nearest);
      return Math.abs(itemDate - targetDate) <
        Math.abs(nearestDate - targetDate)
        ? item.date
        : nearest;
    }, data[0].date);
  };

  const formatDateToYYYYMMDD = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Function to format date to dd-mm-yyyy
  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleFileChange = (purchaseOrderId, e) => {
    const newFiles = Array.from(e.target.files);
    setFileMap((prev) => {
      const updatedFiles = prev[purchaseOrderId]
        ? [...prev[purchaseOrderId], ...newFiles]
        : newFiles;
      return {
        ...prev,
        [purchaseOrderId]: updatedFiles,
      };
    });
    setFileCount((prev) => {
      const newCount = (prev[purchaseOrderId] || 0) + newFiles.length;
      return {
        ...prev,
        [purchaseOrderId]: newCount,
      };
    });
    if (newFiles.length + (fileCount[purchaseOrderId] || 0) >= 1) {
      setShowSubmitButton((prev) => ({
        ...prev,
        [purchaseOrderId]: true,
      }));
    }
  };

  const handleIndividualFileChange = (OrderId, e) => {
    // setselectedIndividualFile(e.target.files[0]);
    const file = e.target.files[0];
    setselectedIndividualFile((prev) => ({ ...prev, [OrderId]: file }));
  };

  const getOrderQuantity = (purchaseOrderId) => {
    const order = corporateOrder.find(
      (o) => o.purchaseOrderId === purchaseOrderId
    );
    return order ? order.quantity : 0;
  };

  const handleIndividualSubmit = (OrderId, typeOfClient) => {
    setLoadingState((prev) => ({ ...prev, [OrderId]: true }));
    console.log("orderid", OrderId, typeOfClient);
    const file = selectedIndividualFile[OrderId];
    console.log("individualfile", file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("orderId", OrderId);
    formData.append("clientType", typeOfClient);

    AxiosInstance.post(
      `lab-partner/test-report-upload/${OrderId}/${typeOfClient}`,
      formData
    )
      .then((response) => {
        console.log("Upload successful:", response);
        // Update order status to 'Completed'
        toast.success("Test Report Uploaded Successfully");
        getLabAndCorporateOrders();
        selectedIndividualFile({});
        setLoadingState((prev) => ({ ...prev, [OrderId]: false }));
      })
      .catch((err) => {
        console.log("Upload error:", err);
        setLoadingState((prev) => ({ ...prev, [OrderId]: false }));
      });
  };

  const handleSubmit = (purchaseOrderId, typeOfClient) => {
    console.log("orderid", purchaseOrderId, typeOfClient);
    const formData = new FormData();
    fileMap[purchaseOrderId].forEach((file) => {
      formData.append("file", file);
    });
    formData.append("orderId", purchaseOrderId);
    formData.append("clientType", typeOfClient);

    AxiosInstance.post(
      `lab-partner/corporate-test-upload/${purchaseOrderId}/${typeOfClient}`,
      formData
    )
      .then((response) => {
        console.log("Upload successful:", response);
        // Update order status to 'Completed'
        toast.success("All Test Report Uploaded Successfully");
        getLabAndCorporateOrders();
        setFileCount({});
        setFileMap({});
        // setCorporateOrder((prev) =>
        //   prev.map((order) =>
        //     order.purchaseOrderId === purchaseOrderId ? { ...order, status: 'Completed' } : order
        //   )
        // );
      })
      .catch((err) => {
        console.log("Upload error:", err);
      });
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  const filteredOrders = rowData.filter((order) => order.date === selectedDate);
  const filteredCorporateOrders = corporateOrder.filter(
    (order) => order.date === selectedDate
  );

  console.log(
    "filess",
    fileMap,
    fileCount,
    selectedDate,
    filteredOrders,
    filteredCorporateOrders,
    rowData,
    corporateOrder
  );
  const handleModalClose = () => {
    setOpenModal(false);
    setCurrentField(null);
    setCurrentOrder(null);
  };

  const handleInputChange = (value) => {
    setCurrentOrder((prev) => ({
      ...prev,
      [currentField]: value,
    }));
  };

  const handleSaveChanges = () => {
    console.log(`Updated ${currentField}:`, currentOrder);

    const formData = {
      collectionDate: currentOrder.testCollectionDate,
      collectionTime: currentOrder.testCollectionTime,
    };

    AxiosInstance.post(
      `lab-partner/update-test-collection-time/${currentOrder.purchaseOrderId}/${currentOrder.typeOfClient}`,
      formData
    )
      .then((response) => {
        toast.success(
          currentField === "testCollectionDate"
            ? "Test Collection Date Updated Successfully"
            : "Test Collection Time Updated Successfully"
        );
        setOpenModal(false);
        setCurrentField(null);
        setCurrentOrder(null);
        getLabAndCorporateOrders();
      })
      .catch((err) => {
        console.log("Upload error:", err);
      });

    // Add API call or Redux action here if needed
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        <div
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <b>Filter By Date :</b>
          <span style={{ marginLeft: "10px" }}>
            <input
              type="date"
              className="form-control"
              id="dateInput"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </span>
        </div>
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you daily home collection service orders.</b>
      </p>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={toggle}
          >
            <img src={AppLogo} alt="Lab" />
          </ModalHeader>
          <ModalBody>
            <OrderDetailModel orderId={orderId} />
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={corporatemodal}
          toggle={Corporatetoggle}
          style={{ maxHeight: "90vh", overflowY: "auto" }}
        >
          <ModalHeader
            style={{
              backgroundColor: "black",
              color: "white",
              border: "1px solid lightgrey",
            }}
            toggle={Corporatetoggle}
          >
            <img src={AppLogo} alt="Lab" />
          </ModalHeader>
          <ModalBody>
            {selectedOrder && (
              <CorporateOrderDetailModel order={selectedOrder} />
            )}
          </ModalBody>
        </Modal>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          {filteredOrders.length === 0 && corporateOrder?.length === 0 ? (
            <>
              <div style={{ marginTop: "7%", marginLeft: "35%" }}>
                <span>
                  <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                    No Orders Available!
                  </h4>
                  <img src={NoOrderImage} alt="Lab" style={{ width: "25%" }} />
                </span>
              </div>
            </>
          ) : (
            filteredOrders.map((order) => (
              <>
                <TableContainer
                  style={{ marginTop: "2%", border: "1px solid black" }}
                >
                  <Table key={order.orderId}>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Date :</b>{" "}
                          {order.date ? formatDateToDDMMYYYY(order.date) : ""}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Time :</b>{" "}
                          {order.time ? formatTime(order.time) : ""}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>LSH Order ID :</b> {order.orderId}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Patient UHID :</b>
                          {""} {order.patientDetail.patientUpId}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                          padding: "10px",
                        }}
                      >
                        <b>LRID :</b>{" "}
                        {order.testDetail.some((test) => test.lrid)
                          ? order.testDetail
                              .map((test) =>
                                test.lrid ? test.lrid : "Not Available"
                              )
                              .join(", ")
                          : "Not Available"}
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Cell
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Address
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Test(s)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {order.patientDetail.patientName}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {order.patientDetail.patientContactNumber}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {order.patientDetail.address?.addressLine1 +
                            ", " +
                            order.patientDetail.address?.city +
                            " " +
                            order.patientDetail.address?.zipcode +
                            "( " +
                            order.patientDetail.address?.state +
                            ", " +
                            order.patientDetail.address?.country +
                            ")"}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          {order.testDetail
                            .map((test) => test.testName)
                            .join(", ")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            fontSize: "20px",
                            border: "1px solid lightgrey",
                            padding: "10px",
                          }}
                        >
                          Order Copy
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Order Status
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          Upload Report
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px",
                          }}
                        >
                          <span
                            onClick={() => viewOrderDetail(order.orderId)}
                            style={{
                              color: "royalblue",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            View Order Copy
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px",
                          }}
                        >
                          {/* <select className="form-control form-control-sm" onChange={handleSelectChange}>
                      <option selected >
                        Order Generated
                      </option>
                  <option>Accepted</option>
                  <option>Sample Collected</option>
                  <option>Completed</option>
                  <option>Issues</option>
                </select> */}
                          <Input
                            id={`status-${order.orderId}`}
                            type="select"
                            className="custom-select" // Apply custom CSS class
                            value={order.orderStatus}
                            onChange={(e) =>
                              handleSelectChange(
                                order.orderId,
                                order.typeOfClient,
                                e
                              )
                            }
                            disabled={
                              order?.orderStatus == "Completed" ? true : false
                            }
                          >
                            <option value="Generated" disabled>
                              Order Generated
                            </option>
                            <option value="Accepted">Accepted</option>
                            <option value="Sample Collected">
                              Sample Collected
                            </option>
                            <option value="Completed">Completed</option>
                            <option value="Issues">Issues</option>
                          </Input>
                        </TableCell>
                        {order?.orderStatus == "Completed" ? (
                          <TableCell
                            colSpan={2}
                            style={{ border: "1px solid lightgrey" }}
                          >
                            <center>All Files are Uploaded Successfully</center>
                          </TableCell>
                        ) : (
                          <TableCell
                            colSpan={2}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px",
                            }}
                          >
                            <center>
                              <input
                                type="file"
                                onChange={(e) =>
                                  handleIndividualFileChange(order.orderId, e)
                                }
                              />
                            </center>
                            <center className="submitTestReportContainer">
                              {selectedIndividualFile[order.orderId] && (
                                <Button
                                  onClick={() =>
                                    handleIndividualSubmit(
                                      order.orderId,
                                      order.typeOfClient
                                    )
                                  }
                                  sx={{
                                    background: "#5353e9",
                                    color: "#fff",
                                    minWidth: "180px",
                                    "&:hover": { background: "#c60176" },
                                  }}
                                >
                                  {loadingState[order.orderId] ? (
                                    <CircularProgress
                                      size={24}
                                      sx={{ color: "#fff" }}
                                    />
                                  ) : (
                                    "Submit"
                                  )}
                                </Button>
                              )}
                            </center>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))
          )}
          {filteredCorporateOrders?.length > 0 &&
            filteredCorporateOrders?.map((order) => (
              <>
                <TableContainer
                  style={{ marginTop: "2%", border: "1px solid black" }}
                >
                  <Table key={order.orderId}>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Date :</b>{" "}
                          {order.date ? formatDateToDDMMYYYY(order.date) : ""}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Time :</b> {formatTime(order.time)}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>LSH Order ID :</b> {order.purchaseOrderId}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          <b>Client Type :</b> {order.typeOfClient}
                        </p>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: " rgb(83, 83, 233)",
                          color: "white",
                          border: "1px solid lightgrey",
                          fontSize: "18px",
                        }}
                      >
                        <b>LRID :</b>{" "}
                        {order.lrId ? order.lrId : "Not Available"}
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Cell
                        </TableCell>
                        <TableCell
                          colSpan={3}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          Address
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {order.clientName}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          {order.clientContact}
                        </TableCell>
                        <TableCell
                          colSpan={3}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          {order.clientAddress
                            ? order.clientAddress
                            : "Not Available"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                        >
                          Test(s)
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Medical Condition
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Test Collection Date
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Test Collection Time
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          {order.testNames}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "18px",
                          }}
                        >
                          {order.medicalCondition || "Not Available"}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "18px",
                          }}
                        >
                          {order.testCollectionDate
                            ? formatDateToDDMMYYYY(order.testCollectionDate)
                            : "Not Available"}
                          <IconButton
                            onClick={() =>
                              handleEditClick(order, "testCollectionDate")
                            }
                            sx={{ color: "black", marginLeft: "10px" }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid lightgrey",
                            fontSize: "18px",
                          }}
                        >
                          {order.testCollectionTime
                            ? formatTime(order.testCollectionTime)
                            : "Not Available"}
                          <IconButton
                            onClick={() =>
                              handleEditClick(order, "testCollectionTime")
                            }
                            sx={{ color: "black", marginLeft: "10px" }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            fontSize: "20px",
                            border: "1px solid lightgrey",
                          }}
                        >
                          Order Copy
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Order Status
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          style={{
                            backgroundColor: "whitesmoke",
                            fontWeight: "bolder",
                            textAlign: "center",
                            border: "1px solid lightgrey",
                            fontSize: "20px",
                          }}
                        >
                          Upload Report{" "}
                          {order?.status != "Completed" && order?.quantity && (
                            <span>
                              ({order.quantity} files to be uploaded only)
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            border: "1px solid lightgrey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          <span
                            onClick={() =>
                              viewCorporateOrderDetail(order.purchaseOrderId)
                            }
                            style={{
                              color: "royalblue",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            View Order Copy
                          </span>
                        </TableCell>
                        <TableCell style={{ border: "1px solid lightgrey" }}>
                          <Input
                            id={`status-${order.id}`}
                            type="select"
                            value={order.status}
                            className="custom-select" // Apply custom CSS class
                            onChange={(e) =>
                              handleSelectChange(
                                order.purchaseOrderId,
                                order.typeOfClient,
                                e
                              )
                            }
                            disabled={
                              order?.status == "Completed" ? true : false
                            }
                            style={{
                              border:
                                order.status === "Generated"
                                  ? "2px solid orange"
                                  : order.status === "Accepted"
                                  ? "2px solid lightgreen"
                                  : "1px solid lightgrey", // Default border
                              animation:
                                order.status === "Generated" ||
                                order.status === "Accepted"
                                  ? "popEffect 1.5s infinite"
                                  : "none", // Add animation for specific statuses
                            }}
                          >
                            <option value="Generated" disabled>
                              Order Generated
                            </option>
                            <option value="Accepted">Accepted</option>
                            <option value="Sample Collected">
                              Sample Collected
                            </option>
                            <option value="Completed">Completed</option>
                            <option value="Issues">Issues</option>
                          </Input>
                        </TableCell>
                        {order?.status == "Completed" ? (
                          <TableCell
                            colSpan={2}
                            style={{ border: "1px solid lightgrey" }}
                          >
                            <center>All Files are Uploaded Successfully</center>
                          </TableCell>
                        ) : (
                          <TableCell
                            colSpan={2}
                            style={{ border: "1px solid lightgrey" }}
                          >
                            <center>
                              <input
                                type="file"
                                multiple
                                onChange={(e) =>
                                  handleFileChange(order.purchaseOrderId, e)
                                }
                              />
                            </center>
                            <center className="submitTestReportContainer">
                              {showSubmitButton[order.purchaseOrderId] && (
                                <Button
                                  onClick={() =>
                                    handleSubmit(
                                      order.purchaseOrderId,
                                      order.typeOfClient
                                    )
                                  }
                                  sx={{
                                    background: "#5353e9",
                                    color: "#fff",
                                    minWidth: "180px",
                                    "&:hover": { background: "#c60176" },
                                  }}
                                >
                                  Submit
                                </Button>
                              )}
                              {fileCount[order.purchaseOrderId] && (
                                <p>
                                  <b>Total no. of files Selected: </b>{" "}
                                  {fileCount[order.purchaseOrderId]}
                                </p>
                              )}
                            </center>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
        </>
      )}

      <Modal
        isOpen={openModal}
        toggle={handleModalClose}
        centered
        style={{
          maxWidth: "500px", // Adjust the modal width
          margin: "auto", // Center horizontally
          borderRadius: "10px", // Rounded corners
        }}
      >
        <ModalHeader
          style={{
            backgroundColor: "black",
            color: "white",
            border: "1px solid lightgrey",
          }}
          toggle={handleModalClose}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {currentField === "testCollectionDate"
              ? "Edit Test Collection Date"
              : "Edit Test Collection Time"}
          </Typography>
        </ModalHeader>

        <ModalBody>
          {currentOrder && (
            <TextField
              label={
                currentField === "testCollectionDate"
                  ? "Test Collection Date"
                  : "Test Collection Time"
              }
              variant="outlined"
              type={currentField === "testCollectionDate" ? "date" : "time"}
              fullWidth
              sx={{ mt: 2 }}
              value={currentOrder[currentField] || ""}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          )}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={handleModalClose}
              sx={{
                backgroundColor: "gray",
                color: "white",
                "&:hover": { backgroundColor: "darkgray" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveChanges}
              sx={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                "&:hover": { backgroundColor: "rgb(63, 63, 223)" },
              }}
            >
              Update
            </Button>
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};
export default MyOrders;
