import React, { useState } from "react";
import {
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const TrainingApproval = ({
  selectedUser,
  approval,
  onCloseOpenModel,
  getAllUnApprovedPartners,
}) => {
  // State for storing selected diseases, gender, and age groups
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedAgeGroups, setSelectedAgeGroups] = useState([]);

  // List of diseases, gender options, and age groups
  const diseases = ["Jumba", "CrossFit", "Yoga"];

  const genderOptions = ["Male", "Female", "Both"];

  const ageGroups = ["0-3", "3-13", "13-21", "Greater than 21"];

  // Handler for checking/unchecking diseases
  const handleDiseaseChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDiseases((prev) =>
      checked ? [...prev, value] : prev.filter((disease) => disease !== value)
    );
  };

  // Handler for selecting gender
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  // Handler for checking/unchecking age groups
  const handleAgeGroupChange = (event) => {
    const { value, checked } = event.target;
    setSelectedAgeGroups((prev) =>
      checked ? [...prev, value] : prev.filter((ageGroup) => ageGroup !== value)
    );
  };

  const handleSubmit = async (status) => {
    const partnerType = approval;
    const userId = selectedUser.userId;
    const formData = {
      approvedDetail: null,
      userId: userId,
      approveFlag: status,
      userTypeId: partnerType,
    };
    try {
      await AxiosInstance.post(`admin/profile-approve`, formData)
        .then((response) => {
          if (status == true) {
            Swal.fire({
              icon: "success",
              title: "Approve!",
              text: "Profile Approved SuccessFully!",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Decline!",
              text: "Profile Decline SuccessFully!",
            });
          }
          onCloseOpenModel();
          getAllUnApprovedPartners();
        })
        .catch((err) => {
          console.log("Upload error:", err);
        });
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };

  return (
    <Form
      style={{
        border: "1px solid lightgrey",
        padding: "15px",
        marginTop: "25px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <h5>Internal Training Approval</h5>

      <Table striped bordered style={{ marginTop: "20px" }}>
        <thead>
          <tr>
            <th>Trainings</th>
            <th>Gender</th>
            <th>Age Group</th>
          </tr>
        </thead>
        <tbody>
          {diseases.map((disease, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  value={disease}
                  checked={selectedDiseases.includes(disease)}
                  onChange={handleDiseaseChange}
                />
                {disease}
              </td>
              <td>
                {genderOptions.map((gender) => (
                  <FormGroup check inline key={gender}>
                    <Input
                      type="radio"
                      name={`gender-${disease}`}
                      value={gender}
                      checked={selectedGender === gender}
                      onChange={handleGenderChange}
                    />
                    <Label>{gender}</Label>
                  </FormGroup>
                ))}
              </td>
              <td>
                {ageGroups.map((ageGroup) => (
                  <FormGroup check inline key={ageGroup}>
                    <Input
                      type="checkbox"
                      value={ageGroup}
                      checked={selectedAgeGroups.includes(ageGroup)}
                      onChange={handleAgeGroupChange}
                    />
                    <Label>{ageGroup}</Label>
                  </FormGroup>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Footer with the Approve/Reject Buttons */}
      <ModalFooter>
        <Button color="success" onClick={() => handleSubmit(true)}>
          Approve
        </Button>
        <Button color="danger" onClick={() => handleSubmit(false)}>
          Reject
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default TrainingApproval;
