import React from "react";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Dialog, DialogActions, DialogTitle, Button, Typography, Box, FormControl, TextField, TextField, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@mui/material";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Col, Input, Row, Toast } from "reactstrap";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gym1 from "../GymScreen/images/sliderGym.jpg";
import gym2 from "../GymScreen/images/slider2.jpg";
import gym3 from "../GymScreen/images/slider3.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const SeeMoreAboutGym = ({ open, onClose }) => {
    const [isTrainerModalOpen, setTrainerIsModalOpen] = useState(false);
    const [classDescription, setClassDescription] = useState(false)
    const toggle = () => setModal(!classDescription);

  const trainerInformation = () => {
    setTrainerIsModalOpen(true);
  };
  const onCloseTrainerModel = () => {
    setTrainerIsModalOpen(false);
  };
    
    const handleClassDescription = (className) => {
        setClassDescription(className);
    }
    const onCloseClassDescription = () => {
        setClassDescription(false);
    }
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
    };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        width: "10rem%", // Set the maximum width of the modal to 60%
        minWidth: "400px", // Set a minimum width to prevent the modal from becoming too small
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "black",
          color: "white",
          border: "1px solid lightgrey",
          textAlign: "center", // Center the title
        }}
      >
        Gym Information <FitnessCenterIcon />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <ModalBody
        sx={{
          padding: "20px",
          maxHeight: "80vh", // Adjust the height as needed
          overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
        }}
      >
        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "25px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>Gym Photos</h3>
          </Typography>
          <div style={{ marginTop: "20px" }}>
            <Slider {...sliderSettings}>
              <div>
                <img src={gym1} alt="Gym 1" style={{ width: "100%", height: "400px", objectFit: "cover", borderRadius: "10px" }} />
              </div>
              <div>
                <img src={gym3} alt="Gym 2" style={{ width: "100%", height: "400px", objectFit: "cover", borderRadius: "10px" }} />
              </div>
              <div>
                <img src={gym2} alt="Gym 3" style={{ width: "100%", height: "400px", objectFit: "cover", borderRadius: "10px" }} />
              </div>
            </Slider>
          </div>
        </div>

        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px", marginTop: "20px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>About Trainers</h3>
          </Typography>

          <Row className="flex flex-wrap justify-center">
            <Col md={2}>
              <b> 1. Are the Trainers Qualified?</b>
              <p> - Yes</p>
            </Col>
            <Col md={2}>
              <b> 2. Are there Male trainers?</b>
              <p> - Yes</p>
            </Col>
            <Col md={3}>
              <b> 3. What are the qualification and experience level of all Male trainers in the gym?</b>
              <p> - Male trainer Qualification </p>
            </Col>
            <Col md={5}>
              <b> 4. What days and time Male trainers are available?</b>
              <p> Weekdays (Monday to Friday): 10 A.M. to 1 A.M.</p>
              <p> Saturday: 10 A.M. to 1 A.M.</p>
              <p> Sunday: 10 A.M. to 1 A.M.</p>
            </Col>
          </Row>
          <Row className="flex flex-wrap justify-center mb-4 mt-4">
            <Col md={3}>
              <b> 5. Are Female trainers available?</b>
              <p> - Yes</p>
            </Col>
            <Col md={4}>
              <b> 6. What are the qualification and experience level of all Female trainers in the gym?</b>
              <p> - Female trainer Qualification </p>
            </Col>
            <Col md={5}>
              <b> 7. What days and time Female trainers are available?</b>
              <p> Weekdays (Monday to Friday): 10 A.M. to 1 A.M.</p>
              <p> Saturday: 10 A.M. to 1 A.M.</p>
              <p> Sunday: 10 A.M. to 1 A.M.</p>
            </Col>
          </Row>
          <Row className="flex flex-wrap justify-center mb-4 mt-4">
            <Col md={3}>
              <b> 8. Is there any Manager/Lead Trainer who manages the entire operations of the gym?</b>
              <p> - Yes</p>
            </Col>
            <Col md={3}>
              <b> 9. Name of the Manager/Lead/Trainer of the gym</b>
              <p> - Manager Name </p>
            </Col>
            <Col md={2}>
              <b> 10. Enter Qualification</b>
              <p> - Qualification</p>
            </Col>
            <Col md={2}>
              <b> 11. Enter Experience</b>
              <p> - Experience</p>
            </Col>
            <Col md={2}>
              <b> 12. Won any award or other recognitions</b>
              <p> - recognitions</p>
            </Col>
          </Row>
        </div>
        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px", marginTop: "20px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>Cleanliness Standards</h3>
          </Typography>

          <Row className="flex flex-wrap justify-center mb-4 mt-4">
            <Col md={3}>
              <b>5.1 Does the gym maintain good sanitation and cleanliness standard?</b>
              <p> - Yes</p>
              <p>Note : </p>
            </Col>
            <Col md={3}>
              <b>5.2 Are sanitation and cleaning stations placed around the GYM and well-stocked.</b>
              <p> - No</p>
            </Col>
            <Col md={2}>
              <b> 5.3 How do staff members enforce standard of hygiene?</b>
              <p> - staff members</p>
            </Col>
            <Col md={2}>
              <b> 5.4 Are locker rooms, showers, toilets, sinks, kept clean and properly maintained?</b>
              <p> - Yes</p>
              <p>Note : </p>
            </Col>
            <Col md={2}>
              <b> 12. Won any award or other recognitions</b>
              <p> - recognitions</p>
            </Col>
          </Row>
        </div>
        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px", marginTop: "20px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>About Amenities</h3>
          </Typography>

          <Row className="flex flex-wrap justify-center">
            <Col md={12}>
              <Row>
                <Col md={3}>
                  <p> 1. Kids Area</p>
                </Col>
                <Col md={3}>
                  <p> 2. Ladies workout space</p>
                </Col>
                <Col md={3}>
                  <p> 3. Sauna</p>
                </Col>
                <Col md={3}>
                  <p> 4. Functional Training area</p>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <p> 5. Personal Wifi</p>
                </Col>
                <Col md={3}>
                  <p> 6. Wifi</p>
                </Col>
                <Col md={3}>
                  <p> 7. Prop shop</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px", marginTop: "20px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>About Classes and Programs</h3>
          </Typography>

          <Row className="flex flex-wrap justify-center">
            <Col md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Classes Name</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Charges</TableCell>
                      <TableCell>Trainer Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <p>
                          <Link onClick={() => handleClassDescription("Zumba")}>Zumba</Link>
                        </p>
                      </TableCell>
                      <TableCell>
                        <p>2 hours</p>
                      </TableCell>
                      <TableCell>
                        <p>11 A.M.</p>
                      </TableCell>
                      <TableCell>
                        <p>1000</p>
                      </TableCell>
                      <TableCell>
                        <p>Alex</p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <p>
                          <Link onClick={() => handleClassDescription("Yoga")}>Yoga</Link>
                        </p>
                      </TableCell>
                      <TableCell>
                        <p>1 hours</p>
                      </TableCell>
                      <TableCell>
                        <p>7 A.M.</p>
                      </TableCell>
                      <TableCell>
                        <p>1200</p>
                      </TableCell>
                      <TableCell>
                        <p>John</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </div>
        {(classDescription == "Zumba" || classDescription == "Yoga") && (
          <>
            <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px", marginTop: "20px" }}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: "8px",
                  color: "#1976d2",
                  textAlign: "center", // Center the title
                  backgroundColor: "#e3f2fd", // Light background for the title
                  padding: "8px",
                  borderRadius: "8px 8px 0 0", // Rounded corners on top
                }}
              >
                <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>
                  About {classDescription ? classDescription : "Class"} {/* Dynamically display class name */}
                </h3>
              </Typography>

              <Row className="flex flex-wrap justify-center">
                <Col md={12}>
                  {classDescription === "Zumba" && (
                    <>
                      <p>
                        Zumba is a fitness program that involves cardio and Latin-inspired dance. It targets many muscle groups at once for total body toning. It also boosts heart health and offers
                        both aerobic and anaerobic benefits.
                      </p>
                      <p>Zumba helps de-stress and maintain a good cardiovascular and respiratory system. It's a fun way to burn calories and improve your fitness!</p>
                    </>
                  )}

                  {classDescription === "Yoga" && (
                    <>
                      <p>Yoga is a mind-body practice that combines physical postures, breathing exercises, and meditation. It helps in improving flexibility, strength, and mental clarity.</p>
                      <p>Practicing Yoga regularly can reduce stress, improve cardiovascular health, and promote a sense of well-being. It's a great way to connect with your body and mind.</p>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </>
        )}
      </ModalBody>

      {/* Modal Footer */}
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default SeeMoreAboutGym;
