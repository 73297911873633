import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { getRiskDiseaseMappingApi } from "../../../constants/apiList";
import { getColorByLabel } from "../../../util/Helper";
import { columns } from "./RisksDiseaseMapColumns";
import { Col, Row } from "reactstrap";
import PCODModel from "./PCODModel";
import ThyroidModel from "./ThyroidModel";

const RisksDiseaseMap = ({RiskDiseaseMapNext}) => {
  const [riskModifiableData, setModifiableData] = useState();
  const [riskNonModifiableData, setNonModifiableData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openThyroidModel, setOpenThroidModel] = useState(false);
  const [keys, setKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [symptomsFlag, setSymptomsFlag] = useState("");
  const [slowHeartRate, setSlowHeartRate] = useState(null);
  const [feelingTired, setFeelingTired] = useState(null);
  const [unexplainedWeightGain, setUnExplainedWeightGain] = useState(null);
  const [feelingSensitiveToCold, setFeelingSensitiveToCold] = useState(null);
  const [drySkinAndDryHair, setDrySkinAndDryHair] = useState(null);
  const [feelingDepressed, setFeelingDepressed] = useState(null);
  const [heavyMenstrualPeriods, setHeavyMenstrualPeriods] = useState(null);
  const [fasterHeartRate, setFasterHeartRate] = useState(null);
  const [difficultySleeping, setDifficultySleeping] = useState(null);
  const [unexplainedWeightLoss, setUnexplainedWeightLoss] = useState(null);
  const [feelingSensitiveToHeat, setFeelingSensitiveToHeat] = useState(null);
  const [sweatySkin, setSweatySkin] = useState(null);
  const [feelingAnxious, setFeelingAnxious] = useState(null);
  const [irregularMenstrualCycles, setIrregularMenstrualCycles] = useState(null);
  const [lackOfPeriods, setLackOfPeriods] = useState(null);
  const [missedPeriods, setMissedPeriods] = useState(null);
  const [notHavingPeriodAtAll, setNotHavingPeriodAtAll] = useState(null);
  const [heavyBleeding, setHeavyBleeding] = useState(null);
  const [unpredictableOvulation, setUnpredictableOvulation] = useState(null);
  const [troubleGettingPregnant, setTroubleGettingPregnant] = useState(null);
  const [excessiveAcne, setExcessiveAcne] = useState(null);
  const [excessiveHairGrowth, setExcessiveHairGrowth] = useState(null);
  const [patchesOfDarkSkin, setPatchesOfDarkSkin] = useState(null);
  const [skinTags, setSkinTags] = useState(null);
  const [unexplainedWeightGainPCOD, setUnExplainedWeightGainPCOD] = useState(null);
  const [isPCOD, setIsPCOD] = useState(false);
  const [isThyroid, setIsThyroid] = useState(false);

  const getRiskDiseaseMapping = async () => {
    AxiosInstance.get(`${getRiskDiseaseMappingApi}`)
      .then((res) => {
        setModifiableData(res?.modifiableRiskDiseaseMapping);
        setNonModifiableData(res?.unModifiableRiskDiseaseMapping);
        let symptomsMessage = "";
        res?.modifiableRiskDiseaseMapping.forEach((item) => {
          if (item.pcodPcos === true) {
             setIsPCOD (true);
            setOpenModal(true);
          }
          if (item.thyroid === true) {
            setIsThyroid(true);
            setOpenThroidModel(true); 
          }
        });
        setSymptomsFlag(symptomsMessage);
      })
      .catch(() => {
        console.log("Error");
      });
  };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await AxiosInstance.get(`form/getThyroidSymptoms`);
          setSlowHeartRate(response?.slowHeartRate === true ? " Slower than usual heart rate," : "");
          setFeelingTired(response?.feelingTired === true ? " Feeling tired often (Fatigue)," : "");
          setUnExplainedWeightGain(response?.unexplainedWeightGain === true ? " Unexplained weight gain," : "");
          setFeelingSensitiveToCold(response?.feelingSensitiveToCold === true ? " Feeling sensitive to cold," : "");
          setDrySkinAndDryHair(response?.drySkinAndDryHair === true ? " Dry skin and Dry hair," : "");
          setFeelingDepressed(response?.feelingDepressed === true ? " Feeling depressed quite often," : "");
          setHeavyMenstrualPeriods(response?.heavyMenstrualPeriods === true ? " Heavy menstrual periods," : "");
          setFasterHeartRate(response?.fasterHeartRate === true ? " Faster than usual heart rate," : "");
          setDifficultySleeping(response?.difficultySleeping === true ? " Difficulty sleeping," : "");
          setUnexplainedWeightLoss(response?.unexplainedWeightLoss === true ? " unexplained weight loss," : "");
          setFeelingSensitiveToHeat(response?.feelingSensitiveToHeat === true ? " Feeling sensitive to heat," : "");
          setSweatySkin(response?.sweatySkin === true ? " Sweaty skin" : "");
          setFeelingAnxious(response?.feelingAnxious === true ? " Feeling anxious, irritable or nervous," : "");
          setIrregularMenstrualCycles(response?.irregularMenstrualCycles === true ? " Irregular Menstrual cycles," : "");
          setLackOfPeriods(response?.lackOfPeriods === true ? " Lack of periods," : "");
        } catch (err) {}
      };

      fetchData();
    }, []);

   useEffect(() => {
     const fetchData = async () => {
       try {
         const response = await AxiosInstance.get(`form/getPcodPcosSymtoms`);
         setMissedPeriods(response?.missedPeriods === true ? " Irregular or missed periods (longer than 40 days between periods)," : "");
         setNotHavingPeriodAtAll(response?.notHavingPeriodAtAll === true ? " Not having period at all," : "");
         setHeavyBleeding(response?.heavyBleeding === true ? " Heavy bleeding during periods," : "");
         setUnpredictableOvulation(response?.unpredictableOvulation === true ? " Unpredictable ovulation," : "");
         setTroubleGettingPregnant(response?.troubleGettingPregnant === true ? " Trouble getting pregnant (trying unsuccessfully for 12 months or more)," : "");
         setExcessiveAcne(response?.excessiveAcne === true ? " Excessive acne (check back, chest, face, other)," : "");
         setExcessiveHairGrowth(response?.excessiveHairGrowth === true ? ",Excessive hair growth (arms, chest, face, abdomen)," : "");
         setPatchesOfDarkSkin(response?.patchesOfDarkSkin === true ? " Patches of dark skin (neck, armpits, groin, under your breasts)," : "");
         setSkinTags(response?.skinTags === true ? " Skin tags (little flaps of extra skin on your neck or armpits)," : "");
         setUnExplainedWeightGain(response?.unexplainedWeightGain === true ? " Sudden unexplained weight gain," : "");
       } catch (err) {}
     };
     fetchData();
   }, []);

  const filteredColumns = columns?.filter((column) => {
    if (column.id === "label") return true;
    return riskModifiableData?.some((item) => item[column.id] !== null);
  });

  const filteredData = riskModifiableData?.filter((item) => Object.values(item).some((value) => value !== null && typeof value !== "object"));

  const filteredNonColumns = columns?.filter((column) => {
    if (column.id === "label") return true; 
    return riskNonModifiableData?.some((item) => item[column.id] !== null);
  });

  const filteredNonData = riskNonModifiableData?.filter((item) => Object.values(item).some((value) => value !== null && typeof value !== "object"));

  useEffect(() => {
    getRiskDiseaseMapping();
  }, []);

  useEffect(() => {
    const riskLevels = ['Medium', 'Moderate', 'High'];
    const result = [];

    if (riskNonModifiableData && riskNonModifiableData.length > 0) {
        for (const [key, value] of Object.entries(riskNonModifiableData[0])) {
            if (value && value.riskLevel && riskLevels.includes(value.riskLevel)) {
                result.push(key);
            }
        }
        setKeys(result);
    }
}, [riskNonModifiableData])

  // Handle closing the modal
  const handleCloseCommentModal = () => {
    setOpenModal(false); // Close the modal
  };
  const handleCloseThyroidModel = () => {
    setOpenThroidModel(false);
  }
  return (
    <>
    <Row>
      <Col lg={12}>
        <TableContainer
          component={Paper}
          className="risk-disease-map-table mb-2"
        >
          <Table size="small">
            <TableHead sx={{ whiteSpace: "nowrap", background: "#EEEDEB",  textTransform: "capitalize" }}>
              <TableRow>
                {filteredColumns.map((column, columnIndex) => {
                  return (
                   <>
                        {column.id === "cld" || column.id === "overWeightObesity" || column.id === "highBloodPressure" ? (
                    <TableCell
                      key={column.id}
                      sx={{ minWidth: "240px", borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: `${columnIndex == 0 ? "left" : "center"}` }}
                    >
                      {column.label}
                    </TableCell>
                      ) : (
                          <TableCell key={column.id} sx={{ minWidth: column.id === "label" ? "185px" : "110px", borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}>
                    {column.label}
                  </TableCell>
                        )}
                      </>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {/* {filteredColumns.map((column) => {
                    if (column.id === "label") {
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          {rowData.riskFactorDisease}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={column.id}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                      >
                        {rowData[column.id]?.value ? "Y" : ""}&nbsp;&nbsp;
                        {rowData[column.id]?.value && (
                          <span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel(rowData[column.id]?.riskLevel) }}
                          ></span>
                        )}
                      </TableCell>
                    );
                  })} */}

                  {filteredColumns.map((column) => (
                    <TableCell key={column.id} sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}>
                        {column.id === "label" ? (
                          rowData.riskFactorDisease
                        ) : rowData[column.id]?.value ? (
                          <>
                            Y&nbsp;&nbsp;
                            <span className="risk-level-circle" style={{ background: getColorByLabel(rowData[column.id]?.riskLevel), border: "2px black solid" }}></span>
                          </>
                        ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableHead sx={{ whiteSpace: "nowrap", 
                textTransform: "capitalize", borderTop:"2px solid black" }}>
             {filteredNonData?.map((rowData, rowIndex) => (
                <TableRow key={rowData.id}>
                  {filteredColumns?.map((column) => (
                    <>
                        {column.id === "cld" || column.id === "overWeightObesity" || column.id === "highBloodPressure" ? (
                          <TableCell key={column.id} 
                          sx={{ 
                            minWidth: "240px",
                            borderRight: "1px solid rgba(224, 224, 224, 1)", 
                            textAlign: "center", 
                            fontWeight:"bolder", 
                            padding:"12px", 
                            fontSize:"unset" 
                          }}>
                            {column.id === "label" ? rowData.riskFactorDisease : filteredNonData[0][column.id]?.value ? filteredNonData[0][column.id]?.riskLevel : null}
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} 
                          sx={{ 
                            minWidth: column.id === "label" ? "185px" : "110px", 
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            textAlign: "center", 
                            fontWeight:"bolder", 
                            padding:"12px", 
                            fontSize:"unset" 
                          }}>
                            {column.id === "label" ? rowData.riskFactorDisease : filteredNonData[0][column.id]?.value ? filteredNonData[0][column.id]?.riskLevel : null}
                          </TableCell>
                        )}
                      </>
                  ))}
                </TableRow>
             ))}
            </TableHead>
          </Table>
        </TableContainer>
        {/* <TableContainer
          component={Paper}
          className="risk-disease-map-table"
        >
          <Table size="small">
            <TableHead sx={{ whiteSpace: "nowrap", background: "#EEEDEB", textTransform: "capitalize" }}>
              <TableRow>
                {filteredNonColumns.map((column, columnIndex) => (
                  <TableCell
                    key={column.id}
                    sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: `${columnIndex == 0 ? "left" : "center"}` }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNonData?.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {filteredNonColumns.map((column) => {
                    if (column.id === "label") {
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          {rowData.riskFactorDisease}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={column.id}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                      >
                        {rowData[column.id]?.value ? "Y" : ""}&nbsp;&nbsp;
                        {rowData[column.id]?.value && (
                          <span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel(rowData[column.id]?.riskLevel) }}
                          ></span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Col>

        <Col lg={12}> 
        <TableContainer
          component={Paper}
          className="risk-disease-map-table mb-2"
        >
          <Table size="small">
            {/* <TableBody>  
              {filteredNonData?.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {filteredNonColumns.map((column) => {
                      if (column.id === "label") {
                        return (
                          <TableCell
                            key={column.id}
                            sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                          >
                            {rowData.riskFactorDisease}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                        >
                          {rowData[column.id]?.value && (
                            <span
                            >{rowData[column.id]?.riskLevel}</span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody> */}
            
          </Table>
        </TableContainer>
        </Col>
    </Row>

    {/* <Row>
      <Col lg={12}>
        <TableContainer
          component={Paper}
          className="risk-disease-map-table mb-2"
        >
          <Table size="small">
            <TableBody>  
              {filteredNonData?.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {filteredNonColumns.map((column) => {
                      if (column.id === "label") {
                        return (
                          <TableCell
                            key={column.id}
                            sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                          >
                            {rowData.riskFactorDisease}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                        >
                          {rowData[column.id]?.value && (
                            <span
                            >{rowData[column.id]?.riskLevel}</span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row> */}

    <Row>
      <Col>
          <div className="meter-content-wrapper">
            <div className="meter-content-inner summery">
              <div className="meter-head">
                <h6>Summary </h6>
              </div>
              <div className="meter-content">
                <p>Your Lifestyle Analysis mapped with Risk Factors and the same then mapped to Lifestyle Diseases shows that you are at risk for following Diseases.</p>
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul style={{ listStyle: "disc" }}>
                  {keys.map((key, index) => (
                    <li key={index}>
                      <h6>{key}</h6>
                    </li>
                    ))}
                  </ul> 
              </div>
              <div className="clickHereText">
                Please checkout the Preventive care section for recommendations for next steps.
              </div>
            </div>
          </div>
      </Col>
    </Row>
      {isPCOD && (
      <>
        <Row>
          <Col>
            <div className="meter-content-wrapper">
              <p>
                <b>PCOD symptoms :</b> {missedPeriods} {notHavingPeriodAtAll} {heavyBleeding} {unpredictableOvulation} {troubleGettingPregnant}
                {excessiveAcne} {excessiveHairGrowth} {patchesOfDarkSkin} {skinTags} {unexplainedWeightGain}
              </p>
            </div>
          </Col>
        </Row>
      </>
      )}  
       {isThyroid && ( 
        <>
          <Row>
            <Col>
              <div className="meter-content-wrapper">
                <p>
                  <b>Thyroid symptoms :</b> {slowHeartRate} {feelingTired}
                  {unexplainedWeightGain} {feelingSensitiveToCold} {drySkinAndDryHair}
                  {feelingDepressed} {heavyMenstrualPeriods} {fasterHeartRate} {difficultySleeping} {unexplainedWeightLoss}
                  {feelingSensitiveToHeat} {sweatySkin} {feelingAnxious} {irregularMenstrualCycles} {lackOfPeriods}
                </p>
              </div>
            </Col>
          </Row>
        </>
       )} 

      <PCODModel
        open={openModal}
        onClose={handleCloseCommentModal}
        // selectedInvoice={selectedInvoice} // Pass the selected invoice data
      />
      <ThyroidModel open={openThyroidModel} onClose={handleCloseThyroidModel} />
    </>
  );
};

export default RisksDiseaseMap;
