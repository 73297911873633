import React from "react";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Box,
  FormControl,
  TextField,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {  Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Col, Input, Row, Toast } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import SeeMoreAboutGym from "./SeeMoreAboutGym";
import StarIcon from "@mui/icons-material/Star";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from "sweetalert2";

const BookGymModel = ({ open, onClose }) => {
  const [isTrainerModalOpen, setTrainerIsModalOpen] = useState(false);
  const [seeMoreAboutGym, setSeeMoreAboutGym] = useState(false)
  const [activeGym, setActiveGym] = useState("Energyia fitness centre");
  const [gymGeneralInformation, setGymGeneralInformation] = useState("")
  const [trailBooking, setTrailBooking] = useState(false);
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const [note, setNote] = useState(""); 
  const [kindOfEquipment, setKindOfEquipment] = useState([]);

  const trainerInformation = () => {
      setTrainerIsModalOpen(true);
  }

  const handleSeeMoreInfo = () => {
    setSeeMoreAboutGym(true)
  }
  const onCloseSeeMoreInfo = () => {
    setSeeMoreAboutGym(false)
  }
  const onCloseTrainerModel = () => {
      setTrainerIsModalOpen(false);
  }
  
  const handleGymClick = (gym) => {
    setActiveGym(gym);
  };

  const handleTrailBooking = () => {
    setTrailBooking(true);
  }
  
const handleBookAppointment = () => {
  const formattedDate = selectedDate.format("DD/MM/YYYY");

  Swal.fire({
    title: "Appointment Booked!",
    text: `Your appointment at ${activeGym} is booked for ${formattedDate}.`,
    icon: "success",
    confirmButtonText: "OK",
    customClass: {
      popup: "swal-popup", 
    },
    didOpen: () => {
      const swalPopup = document.querySelector(".swal-popup");
      if (swalPopup) {
        swalPopup.style.zIndex = "999999"; 
      }
    },
  });
};
    useEffect(() => {
      AxiosInstance.get("gym/get-profile")
        .then((response) => {
          setGymGeneralInformation(response)
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        width: "10rem%",
        minWidth: "400px",
        zIndex: 999,
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "black",
          color: "white",
          border: "1px solid lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ flexShrink: 0 }}>{activeGym}</p>
        <p style={{ marginRight: "10%" }}>Gym phone number</p>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <ModalBody
        sx={{
          padding: "20px",
          maxHeight: "80vh", // Adjust the height as needed
          overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
        }}
      >
        <div style={{ padding: "10px 0" }}>
          {/* <Typography variant="body1" style={{ marginBottom: "8px" }}>
            Available gyms:
          </Typography> */}
          <ul style={{ display: "flex", paddingLeft: 0, gap: "15px" }}>
            <li style={{ marginBottom: "10px" }}>
              <Link
                href="/gym1"
                onClick={(e) => {
                  e.preventDefault();
                  handleGymClick("Energyia fitness centre");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#1976d2",
                  borderBottom: activeGym === "Energyia fitness centre" ? "2px solid #1976d2" : "none",
                  paddingBottom: "5px",
                }}
              >
                Energyia fitness centre
              </Link>
            </li>
            {/* <li style={{ marginBottom: "10px" }}>
              <Link
                href="/gym2"
                onClick={(e) => {
                  e.preventDefault();
                  handleGymClick("Health Club");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#1976d2",
                  borderBottom: activeGym === "Health Club" ? "2px solid #1976d2" : "none",
                  paddingBottom: "5px",
                }}
              >
                Health Club
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Link
                href="/gym3"
                onClick={(e) => {
                  e.preventDefault();
                  handleGymClick("Athletic Facility");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#1976d2",
                  borderBottom: activeGym === "Athletic Facility" ? "2px solid #1976d2" : "none",
                  paddingBottom: "5px",
                }}
              >
                Athletic Facility
              </Link>
            </li> */}
          </ul>
        </div>
        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>{activeGym} </h3>
            4.5
            <StarIcon /> (533)
          </Typography>

          <Row className="flex flex-wrap justify-center">
            <Col md={2}>
              <b> Year of Establish:</b> Since 2016
            </Col>
            <Col md={4}>
              <b> Size(area):</b> Current 10000 + Sq.Ft. Carpet & One Shot Straight.
            </Col>
            <Col md={6}>
              <b>Location: </b>Eden Super Market, Beside, Opp TMC Garden, Vihang Vally Phase 1, Sai Nagar, Kasarvadavali, Thane West, Maharashtra – 400615.
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={7}>
              <b>General time of the gym : </b>Morning 5.30 AM To 11.00 PM (Monday To Saturday ) & Morning 7.00 AM To 1.00 PM ( Sunday )
            </Col>
            <Col md={5}>
              <b>Total staff members: </b>24 Staff ( Shift Wise )
            </Col>
          </Row>

          {activeGym === "Energyia fitness centre" && (
            <>
              <Row className="flex flex-wrap justify-center mb-4 mt-5">
                <Col className="rounded-lg  p-4" md={4}>
                  <h6>Gym Description :</h6>
                  <p className="mt-3">Description of Gym </p>
                  <br></br>
                  <h6>Available Facilities in gym :</h6>
                  <p className="mt-2"></p>
                </Col>
                <Col md={8}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Equipment Name</TableCell>
                          <TableCell>Equipment Type</TableCell>
                          <TableCell>How Old Equipment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <p>equipment name</p>
                          </TableCell>
                          <TableCell>
                            <p>equipment type</p>
                          </TableCell>
                          <TableCell>
                            <p>equipment year old</p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <p>equipment name2</p>
                          </TableCell>
                          <TableCell>
                            <p>equipment type2</p>
                          </TableCell>
                          <TableCell>
                            <p>equipment year ol1</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
            </>
          )}
        </div>
        {activeGym === "Energyia fitness centre" && (
          <>
            <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px" }}>
              <Typography
                variant="h6"
                style={{
                  marginBottom: "8px",
                  color: "#1976d2",
                  textAlign: "center", // Center the title
                  backgroundColor: "#e3f2fd", // Light background for the title
                  padding: "8px",
                  borderRadius: "8px 8px 0 0", // Rounded corners on top
                }}
              >
                <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>Membership, Training and Testing</h3>
              </Typography>{" "}
              <Row className="flex flex-wrap justify-center mb-4 mt-3">
                <Col className="rounded-lg" md={4}>
                  <b>1. Is there any introductory clinic to help members get started?</b>
                  <p> - Yes</p>
                </Col>
                <Col className="rounded-lg" md={4}>
                  <b>2. Is separate training provided on complex equipment for proper usage?</b>
                  <p> - Yes</p>
                </Col>
                <Col className="rounded-lg" md={4}>
                  <b>3. Is an initial fitness orientation, screening or body assessment provided to each member right after joining?</b>
                  <p> - Yes</p>
                </Col>
              </Row>
              <Row className="">
                <Col className="rounded-lg" md={4}>
                  <b>4. Are there areas dedicated to stretching or turf areas for training?</b>
                  <p> - Yes</p>
                </Col>
                <Col className="rounded-lg" md={4}>
                  <b>5. Does the gym offer body fat testing, cardiovascular testing, and strength and flexibility testing</b>
                  <p> - No</p>
                </Col>
                <Col className="rounded-lg" md={4}>
                  <b>6. Is there any free trial membership available to check out the gym ?</b>
                  <p> - No</p>
                </Col>
              </Row>
              <Row className="flex justify-center justify-center mb-4 mt-3">
                <Col className="rounded-lg" md={4}>
                  <b>7. Can I get a Tour of the gym before signing up for the membership?</b>
                  <p> - Yes</p>
                  <p>Timing : 10 A.M. to 1 P.M.</p>
                </Col>
                <Col className="rounded-lg" md={4}>
                  <b>8. Can I cancel and get refund of membership fees, if for some reason, after few days of training, I do not want to continue with my membership anymore? </b>
                  <p> - Yes</p>
                  <p>Note : note related to membership</p>
                </Col>
                <Col className="rounded-lg" md={4}>
                  <b>9. What kind of membership plans the Gym has? (Select all that applies)</b>
                  <p> - 2 month, 3 month</p>
                </Col>
              </Row>
            </div>
          </>
        )}

        <br></br>
        <br></br>
        <div style={{ border: "1px solid rgb(25, 118, 210)", borderRadius: "10px", padding: "15px" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "8px",
              color: "#1976d2",
              textAlign: "center", // Center the title
              backgroundColor: "#e3f2fd", // Light background for the title
              padding: "8px",
              borderRadius: "8px 8px 0 0", // Rounded corners on top
            }}
          >
            <h3 style={{ textAlign: "center", textDecoration: "underline", fontFamily: "cursive" }}>Google Reviews</h3>
          </Typography>
          <p>
            <b>1. </b> The gym vibe is sufficient to keep you motivated for the workouts. Trainers are very friendly and supportive too. Being working out here for almost 2 years now.
          </p>
          <p>
            <b>2. </b> This gym is amazing. Its huge n Amazing facility, top equipment and great environment. Professional n super friendly staff and comfortable gym for everyone from beginners to
            professional athletes to train at. Huge cardio section with crossfit. Dedicated zone for powerlifting.
          </p>
          <p>
            <b>3.</b> This place is equipped with every tool to make your exercise regime a fulfilling ride.
          </p>
          <p>
            <b>4.</b> Gym is equipped with modern equipments and the vibe is just amazing. Additionally there are multiple extra activities which happen every week or 10 days. The trainers are super
            knowledgeable and helpful. I personally took personal training from Rohit sir and the results he has delivered are amazing.
          </p>
          <p>
            <b>5. </b>Good vibe, gym environment keeps you motivated to work towards your goal.
          </p>
          <p>
            <b>6. </b>One of the best gyms with state of the art facilities and equipment. The staff is friendly and knowledgeable. The quality of the gym is right up there with international
            standards
          </p>
        </div>
        <Button color="primary" variant="contained" className="mt-4" onClick={handleTrailBooking}>
          Book for a Free Tour of the Gym
        </Button>
        <Button color="primary" variant=" " onClick={handleSeeMoreInfo} className="mt-4" style={{ float: "right" }}>
          See More Information about Gym
        </Button>
        {seeMoreAboutGym && <SeeMoreAboutGym open={seeMoreAboutGym} onClose={onCloseSeeMoreInfo} />}

        {trailBooking == true && (
          <>
            <div>
              <h4 style={{ textAlign: "center" }}>Book Appointment</h4>
              <br />
              <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={selectedDate}
                    minDate={today}
                    onChange={(newDate) => setSelectedDate(newDate)}
                    format="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                  <br></br>
                </LocalizationProvider>
                <br></br>
              </Box>
              <FormControl style={{ marginLeft: "25%", width: "50%" }}>
                <TextField
                  label="Write your note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)} // Handling the input change
                  multiline
                />
              </FormControl>
              <br></br>
              <br></br>
              <Button color="primary" variant="contained" style={{ marginLeft: "45%" }} onClick={handleBookAppointment}>
                Book
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Dialog>
  );
};

export default BookGymModel;
